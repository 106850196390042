export const validate = (inputs) => {
  const errors = {};
    
  if (!inputs.maxValue) {
    errors.maxValue = 'Enter your price sale';
  }
  if (!inputs.rentMaxValue) {
    errors.rentMaxValue = 'Enter your price rent';
  }
  if (!inputs.table) {
    errors.table = 'Enter your database table';
  }
  return errors;
};


export const requiredInput = (input) =>
  input ? undefined : `Input is required`;

// export const correctInput = input =>
//   input !== 'rajat' ? 'Incorrect Username' : undefined;
