import momment from 'moment';
import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'
import { FORMAT_DATE, VERIFIED_STATUS } from '../../../../../shared/helpers/constants'

const fetchData = (id) => {
    return apiCaller(`${API_URL.GET_SELLER_DETAIl}/${id}`, HTTP_METHOD.GET)
}

const formatData = (data) => {
    let formatData = [];
    data.forEach(element => {
        formatData.push({ id: element._id, name: element.first_name + " " + element.last_name, phone: element.phone_number })
    });
    return formatData;
}

const fetchDataProperties = (status, fromDate, toDate, itemPerPage, pageNumber, ownerId) => {

    let params = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage,
        ownerId
    };

    if (fromDate !== "") {
        params["fromDate"] = fromDate;
    }

    if (toDate !== "") {
        params["toDate"] = toDate;
    }

    switch (status.value) {
        case "verified":
        case "none-verified":
        case "rejected":
            params["verifyStatus"] = status.value;
            break;
        case "verifying":
            params["verifyStatus"] = VERIFIED_STATUS.PENDING
            params["status"] = "posting"
            break;
        case "closing":
        case "closed":
        case "available":
        case "confirmed":
        case "ongoing":
            params["status"] = status.value;
            break;
        default:
            break;
    }

    return apiCaller(API_URL.GET_LIST_PROPERTY, HTTP_METHOD.GET, null, params)
}

const formatDataProperty = (data) => {
    const verifyType = {
        verification: "Certified",
        none: "Un-certified"
    }
    const formatData = data.map(element => ({
        ...element,
        id: element._id,
        name: `${element.first_name} ${element.last_name}`,
        add_time: momment(element.add_time).format(FORMAT_DATE),
        upd_time: momment(element.upd_time).format(FORMAT_DATE),
        status: element.status === "posting" ?
        (element.verified_status === "rejected" ? "rejected" : "verifying") : element.status,
        verify_type: element.verify_type = verifyType[element.verify_type]
    }));

    return formatData;
}

export { fetchData, formatData, fetchDataProperties, formatDataProperty };