import PropTypes from 'prop-types';

const {
  string, shape, number, bool, arrayOf
} = PropTypes;

export const CurrentUserProps = shape({
  id: number,
  name: string,
  isOnline: bool,
  totalMessages: number
});


export const ListChatRoomProps = arrayOf(shape({
  id: number,
  name: string,
  users: arrayOf(CurrentUserProps),
}));