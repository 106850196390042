
import * as CONSTANTS from '../actions/constants';

const initialState = {
  listCurencyParams: [],
  item: {},
  loading: false,
  error: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.LOADING:
    case CONSTANTS.EDIT_CURRENCY_REQUEST:
    case CONSTANTS.FETCH_CURRENCY_PARAMS_REQUEST:
      return { ...state, loading: true };
    case CONSTANTS.EDIT_CURRENCY_SUCCESS:
      return { item: action.payload, loading: false, error: false };
    case CONSTANTS.FETCH_CURRENCY_PARAMS_SUCCESS:
      return { item: action.payload, loading: false, error: false, listCurencyParams: action.listCurencyParams };
    case CONSTANTS.FETCH_CURRENCY_DETAIL_SUCCESS:
      return { item: action.currency, loading: false, error: false };
    case CONSTANTS.FETCH_CURRENCY_DETAIL_FAILURE:
    case CONSTANTS.FETCH_CURRENCY_PARAMS_FAILURE:
    case CONSTANTS.EDIT_CURRENCY_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}