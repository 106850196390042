import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import MatTable from "../../../shared/components/table/components/MatTable";
import Loading from "../../../shared/components/Loading";
import {
  changeFilterValue,
  onShortAccount,
  fetchAccounts,
  changePage,
  changeItemPerPage,
  activeUser,
  deactiveUser,
  changeDefaultPage,
  resetState
} from "./redux/actions/index";
import Filter from "./Filter";

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.activeUser = this.activeUser.bind(this);
    this.deaciveUser = this.deaciveUser.bind(this);
  }

  componentDidMount() {
    this.props.resetState();
    this.props.fetchAccounts();
  }

  onPageChange(page, orders) {
    this.props.changePage(page);
  }

  onChangeRowsPerPage(event) {
    this.props.changeItemPerPage(event.target.value);
  }

  activeUser(id) {
    this.props.activeUser(id);
  }

  deaciveUser(id) {
    this.props.deactiveUser(id);
  }

  handleFilter = () => {
    this.props.changeDefaultPage();
    this.props.fetchAccounts();
  };

  render() {
    const { result, pageNumber, itemPerPage, showLoading } = this.props;
    const { data, count } = result;
    return (
      <Container className="properties">
        <Loading isOpen={showLoading} />
        <Row>
          <Col md={12} lg={12}>
            <Filter
              changeFilterValue={this.props.changeFilterValue}
              // onFilter={this.props.fetchAccounts}
              onFilter={this.handleFilter}
              data={this.props}
            />
            {data.length > 0 ? (
              <MatTable
                data={data}
                count={count}
                currentPage={pageNumber}
                onPageChange={this.onPageChange}
                rowsPerPage={parseInt(itemPerPage)}
                // percents={["10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"]}
                column={[
                  "first_name",
                  "last_name",
                  "email",
                  "line_account",
                  "status",
                  "phone_number",
                  "role",
                  "owner_type",
                  "posting_properties_count",
                  "add_time"
                ]}
                header={[
                  "First Name",
                  "Last Name",
                  "Email",
                  "Line ID",
                  "Status",
                  "Phone No.",
                  "Role",
                  "Seller Type",
                  "No. of Properties",
                  "Joined Date"
                ]}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                // onRowsPerPageChange={this.onChangeRowsPerPage}
                onClickRow={this.onClickRow}
                showId={true}
                actionColumn={[
                  {
                    title: "Active",
                    color: "success",
                    action: this.activeUser
                  },
                  {
                    title: "Deactive",
                    color: "danger",
                    action: this.deaciveUser
                  }
                ]}
                onShort={this.props.onShortAccount}
              />
            ) : (
              <span>No Data</span>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.accountsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAccounts: () => {
      dispatch(fetchAccounts());
    },
    changePage: pageNumber => {
      dispatch(changePage(pageNumber));
    },
    changeItemPerPage: itemPerPage => {
      dispatch(changeItemPerPage(itemPerPage));
    },
    activeUser: id => {
      dispatch(activeUser(id));
    },
    deactiveUser: id => {
      dispatch(deactiveUser(id));
    },
    onShortAccount: value => {
      dispatch(onShortAccount(value));
    },
    changeFilterValue: data => {
      dispatch(changeFilterValue(data));
    },
    changeDefaultPage: () => {
      dispatch(changeDefaultPage());
    },
    resetState: () => {
      dispatch(resetState());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Accounts));
