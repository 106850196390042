import 'whatwg-fetch';

import { authHeader, API_URL, apiCaller, HTTP_METHOD } from '../../../../shared/helpers/index';

export const login = async (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ username, password })
    };
    const response = await fetch(API_URL.LOGIN, requestOptions);
    const res = response.json();
    return res;
}

export const loginUA = async (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            email: username,
            password: password,
            role: ""
        })
    };
    const response = await fetch(API_URL.LOGIN_UA, requestOptions);
    const res = response.json();
    return res;
}

export const logoutUA = async (token) => {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`);
    const params = {};
    const paramsString = JSON.stringify(params);
    const queryString = `?$$JSON=${paramsString}`
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(API_URL.LOGOUT_UA + queryString, requestOptions);
    const res = response.json();
    return res;
}


export const authome = () => {
    return apiCaller(API_URL.AUTHO_ME, HTTP_METHOD.GET);
}
export const logout = async () => {
    localStorage.removeItem('token');
}
