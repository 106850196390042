import React from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons'
import m from "moment";

const ScheduleItem = ({ t, item }) => (
    <Row className="key__infor__row">
            <FontAwesomeIcon className="key__infor_name" icon={faClock} style={{ marginRight: "10px" }} />
            <span className="schedule_item">{convertTime(item)}</span>
    </Row>
);

const renderKeyinfor = (data) => {
    let result = data.map((item, index) => {
        return <ScheduleItem key={index} item={item} />
    });
    return result;
}

const convertTime = (item) => {
    const{from, to} = item;
    let result = `${new Date(from.date).toLocaleDateString("en-GB", {day: '2-digit', month: 'short', year: 'numeric', timeZone: 'Asia/Bangkok'}).replaceAll(' ', '-')} to
        ${new Date(to.date).toLocaleDateString("en-GB", {day: '2-digit', month: 'short', year: 'numeric', timeZone: 'Asia/Bangkok'}).replaceAll(' ', '-')} 
        from ${m(from.hour, 'HH:mm').format('hh:mm a')} to ${m(to.hour, 'HH:mm').format('hh:mm a')}`
    return result
}

const ViewSchedule = (props) => {

    const { t, viewing_schedules } = props;
    return (
        <Card>
            <CardBody>
                <div>
                    <h3 className="page-title">Viewing Schedules</h3>
                    <hr />
                    <div>
                        {!!viewing_schedules ? renderKeyinfor(viewing_schedules) : ''}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}


export default ViewSchedule;
