export const FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_ERROR = 'FETCH_AGENTS_ERROR';
export const TOGGLE_MODAL_AGENT = 'TOGGLE_MODAL_AGENT';
export const ASIGN_AGENT = 'ASIGN_AGENT';
export const ASIGN_AGENT_ERROR = 'ASIGN_AGENT_ERROR';
export const ASIGN_AGENT_SUCCESS = 'ASIGN_AGENT_SUCCESS';
const getAgentsRequest = function getAgentsRequest(id) {
    return {
        type: FETCH_AGENTS_REQUEST
    }
}

const getAgentsSuccess = function getAgentsSuccess(agents) {
    return {
        type: FETCH_AGENTS_SUCCESS,
        agents
    }
}

const getAgentsError = function getAgentsError() {
    return {
        type: FETCH_AGENTS_ERROR,
    }
}

const toggleModalAgent = function toggleModalAgent() {
    return {
        type: TOGGLE_MODAL_AGENT
    }
}

const asignAgent = function asignAgent(agentId) {
    return {
        type: ASIGN_AGENT,
        agentId: agentId
    }
}

const asignAgentError = function asignAgentError(error) {
    return {
        type: ASIGN_AGENT_ERROR,
        error: error
    }
}

const asignAgentSuccess = function asignAgentSuccess(message) {
    return {
        type: ASIGN_AGENT_SUCCESS,
        message
    }
}
export {
    getAgentsRequest,
    getAgentsSuccess,
    getAgentsError,
    toggleModalAgent,
    asignAgent,
    asignAgentError,
    asignAgentSuccess
}