export const CREATE = 'CREATE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILED = 'CREATE_FAILED';
export const CLOSE_MODAL = 'CLOSE_MODAL';
const closeModal = () => {
  return {
    type: CLOSE_MODAL
  }
}
const handleCreate = (data) => {
  return {
    type: CREATE,
    data
  }
}

const createSuccees = () => {
  return {
    type: CREATE_SUCCESS
  }
}

const createFail = (error) => {
  return {
    type: CREATE_FAILED,
    error
  }
}


export {
  handleCreate,
  createSuccees,
  createFail,
  closeModal
} 
