import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { URBANASSET_IMAGE_BASE_API } from "../../helpers/index";

class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    editImage: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    ]).isRequired
  };

  static defaultProps = {
    customHeight: false
  };

  constructor(props) {
    super(props);
    this.state = {
      changeNewImage: false,
      editImage: this.props.editImage
    };

    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    const { onChange } = this.props;

    onChange(
      file.map(fl => {
        return Object.assign(fl, {
          preview: URL.createObjectURL(fl)
        });
      })
    );

    this.setState({ changeNewImage: true });
  }

  componentDidUpdate = (prevState, prevProps) => {
    if (prevProps.value !== this.props.value) {
      const { onChange } = this.props;
      onChange(
        this.props.value.map(fl =>
          Object.assign(fl, {
            preview: URL.createObjectURL(fl)
          })
        )
      );
    }
  };

  removeFile(index, e) {
    const { onChange, value } = this.props;
    this.setState({
      changeNewImage: false
    });
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  }

  render() {
    const { value, customHeight, name } = this.props;

    const files = value;

    let imgName = files[0] && files[0].name;
    const imageUrl = URBANASSET_IMAGE_BASE_API + "/large/" + imgName;

    return (
      <div
        className={`dropzone dropzone--single${
          customHeight ? " dropzone--custom-height" : ""
        }`}
      >
        <Dropzone
          accept="image/jpeg, image/png"
          name={name}
          multiple={false}
          onDrop={fileToUpload => {
            this.onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input">
              {(!files || files.length === 0) && (
                <div className="dropzone__drop-here">
                  <span className="lnr lnr-upload" /> Upload an image
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0 && (
          <aside className="dropzone__img">
            <img
              className="dropzone__image"
              src={this.state.changeNewImage ? files[0].preview : imageUrl}
              alt="drop-img"
            />
            <button
              className="dropzone__img-delete"
              type="button"
              onClick={e => this.removeFile(0, e)}
            >
              Remove
            </button>
          </aside>
        )}
      </div>
    );
  }
}

const renderDropZoneField = props => {
  const { input, customHeight } = props;

  return <DropZoneField {...input} customHeight={customHeight} />;
};

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    editImage: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  customHeight: PropTypes.bool,
  editImage: PropTypes.string
};

renderDropZoneField.defaultProps = {
  customHeight: false
};

export default renderDropZoneField;
