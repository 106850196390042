import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Transaction from './components/Transaction';
import { fetchTransactionFee, changeTransactionFee } from './redux/actions/fetchTransactionFee';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loading from '../../../shared/components/Loading';

class SettingTransactionFee extends PureComponent {
  constructor(props) {
    super(props);
    this.handleUpdateRentalFee = this.handleUpdateRentalFee.bind(this);
    this.handleUpdateSaleFee = this.handleUpdateSaleFee.bind(this);
  }

  componentDidMount() {
    this.props.fetchTransactionFee();
  }

  handleUpdateRentalFee(id, value) {
    this.props.changeTransactionFee(id, 'Rental_Fee', value);
  }

  handleUpdateSaleFee(id, value) {
    this.props.changeTransactionFee(id, 'Sale_Fee', value);
  }

  render() {
    const { result, loading } = this.props;
    const { data } = result.data;
    return (
      <Container className="properties">
        <Loading isOpen={loading} />
        <Row>
          <Col md={12}>
            <h3 className="page-title">Transaction fee</h3>
          </Col>
        </Row>
        <Transaction
          data={data}
          onSaveSaleFee={this.handleUpdateSaleFee}
          onSaveRentalFee={this.handleUpdateRentalFee}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.transactionFeeReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchTransactionFee: () => { dispatch(fetchTransactionFee()) },
    changeTransactionFee: (id, typeTransaction, value) => { dispatch(changeTransactionFee(id, typeTransaction, value)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SettingTransactionFee));
