import { call, put, takeLatest, cancelled, select } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions/ambassadorAction';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import { fetchListAmbassador } from '../services/index';

export const getData = (state) => state.ambassadorReducer;

function* getListAmbassador() {
  try {
    yield put({ type: ACTIONS.LOADING });
    const stateData = yield select(getData);
    const { pageNumber, itemPerPage, sort, desc, name, status } = stateData;
    const result = yield call(fetchListAmbassador, itemPerPage, pageNumber, name, status.value, sort, desc);

    // const formatedData = yield call(formatListAmbassadorData, result.data);
    // const userCount = yield call(countUsers);

    yield put({ type: ACTIONS.AMBASSADOR_FETCH_SUCCEEDED, payload: { items: result.data.data ? result.data.data : [], count: result.data.total ? result.data.total.count : 0 } })
  } catch (error) {
    yield put({ type: ACTIONS.AMBASSADOR_FETCH_FAILED, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}



function* ambassadorWatcher() {
  yield takeLatest(ACTIONS.AMBASSADOR_FETCH_REQUESTED, getListAmbassador);
  yield takeLatest(ACTIONS.CHANGE_ITEM_PAGE, getListAmbassador);
  yield takeLatest(ACTIONS.CHANGE_PAGE, getListAmbassador);
  yield takeLatest(ACTIONS.ON_SHORT_AMBASSADOR, getListAmbassador);
}

export default ambassadorWatcher 
