import { takeLatest, call, put, cancelled, select } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers';
import { fetchData, formatData, removeCurrency } from '../services';

import * as CONSTANTS from '../actions/constants';
export const getData = (state) => state.currencyReducer;


function* getListCurrency() {
  try {
    const stateData = yield select(getData);
    const { itemPerPage, pageNumber } = stateData;
    const result = yield call(fetchData, itemPerPage, pageNumber);
    const formatedData = yield call(formatData, result.data.data);
    const data = { data: formatedData, count: "total" in result.data ? result.data.total.count : 0 };
    yield put({ type: CONSTANTS.LOADING });
    yield put({ type: CONSTANTS.FETCH_CURRENCY_SUCCESS, data });
  } catch (error) {
    yield put({ type: CONSTANTS.FETCH_CURRENCY_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* handleRemoveCurrency(id) {
  try {
    yield call(removeCurrency, id);
    yield put({ type: CONSTANTS.FETCH_CURRENCY_REQUEST });
    yield call(history.push, PATH_URL.CURRENCIES);
  } catch (error) {
    // yield put({ type: CONSTANTS.FETCH_CURRENCY_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* changePage() {
  yield put({ type: CONSTANTS.FETCH_CURRENCY_REQUEST });
}

function* changeItemPerPage() {
  yield put({ type: CONSTANTS.FETCH_CURRENCY_REQUEST });
}

function* currencyWatcher() {
  yield takeLatest(CONSTANTS.FETCH_CURRENCY_REQUEST, getListCurrency);
  yield takeLatest(CONSTANTS.REMOVE_CURRENCY, handleRemoveCurrency);
  yield takeLatest(CONSTANTS.CHANGE_PAGE, changePage);
  yield takeLatest(CONSTANTS.CHANGE_ITEM_PER_PAGE, changeItemPerPage);
}

export default currencyWatcher 
