import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import RelatedPropertiesCarousel from "./RelatedPropertiesCarousel";
import Modal from '../../../../shared/components/Modal';
import { IMAGE_BASE_URL } from '../../../../shared/helpers/api';
import { VERIFY_TYPES } from '../../../../shared/helpers/constants';
import CertifiedThumbLogo from '../../../../shared/img/logo/logo_certified_thumb.png';


const PropertyCarouselItem = ({ t, key, verify_type, img, name, price, address, id, onTakeOver }) => (
  <div className="slide-container" key={key}>
    <div className="property_carousel_item_header">
      <img src={`${IMAGE_BASE_URL}/large/${img}`} alt="Property"/>
      {verify_type === VERIFY_TYPES.VERIFICATION && 
        <img src={CertifiedThumbLogo} className="slick-slider__caption__img-certified-logo"/>}
    </div>
      <div className="slick-slider__caption" style={{height: "35%"}}>
          <p className="slick-slider__caption-title">{name}</p>
          <p className="slick-slider__caption-description">{price}</p>
          <p className="slick-slider__caption-address" style={{height: "40px", lineHeight: "19px"}}>{address}</p>
      </div>
      <div style={{height: "15%"}}>
         <Modal
            color="danger"
            btn="Take over"
            title="Are you sure take over this property?"
            optionClass="ambassador__takeover-btn"
            confirmAction={() => {
              onTakeOver(id)
            }}
          />
      </div>
  </div>
);


const renderProperties = (properties, onTakeOver) => {
  let results = properties.map((item, index, handleClick) => {
    return (
      <PropertyCarouselItem 
      key={index} 
      verify_type={item.verify_type}
      img={item.image} 
      name={item.title} 
      price={item.price} 
      address={item.address} 
      id={item.id}
      onTakeOver={onTakeOver}
      />
    );
  });
  return results;
}

class RelatedProperties extends PureComponent {
  render() {
    const {t, properties, onTakeOver} = this.props;
    return (
      <Card>
        <CardBody>
          <RelatedPropertiesCarousel items={renderProperties(properties, onTakeOver)} title={`Following ${properties.length} deals`}/>
        </CardBody>
      </Card>
    );
  }
}

RelatedProperties.propTypes = {
  t: PropTypes.func.isRequired,
  relPropertiesList: PropTypes.arrayOf(PropTypes.object),
  handleClick: PropTypes.func
}

export default withTranslation('common')(RelatedProperties);
