
import _ from 'lodash';

import * as CONSTANTS from '../actions/constants';

const initialState = {
  item: null,
  loading: false,
  loadingDetail: false,
  error: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.DETAIL_LOADING:
    case CONSTANTS.PARAMETER_DETAIL_FETCH_REQUESTED:
      return { ...state, loadingDetail: true };
    case CONSTANTS.UPDATE_LOADING:
    case CONSTANTS.PARAMETER_UPDATE_FETCH_REQUESTED:
      return { ...state, loading: true };
    case CONSTANTS.PARAMETER_DETAIL_FETCH_SUCCEEDED:
      const item = formatData(action.payload);
      return { item, loadingDetail: false, error: false };
    case CONSTANTS.PARAMETER_UPDATE_FETCH_SUCCEEDED:
      handleDataUpdate(action.payload);
      return { item: null, loading: false, error: false };
    case CONSTANTS.PARAMETER_UPDATE_FETCH_FAILED:
    case CONSTANTS.PARAMETER_DETAIL_FETCH_FAILED:
      return { ...state, item: null, loading: false, loadingDetail: false, error: true };
    default:
      return state;
  }
}

const handleDataUpdate = (item) => {
  let items = JSON.parse(localStorage.getItem('items')) || [];
  if(items && items.length > 0) {
    items = items.map(data => data.id === item.id ? item : data);
  }
  localStorage.setItem('items', JSON.stringify(items));
  return items;
}

const formatData = item => {
  const data = item;
  if(item.table) {
    data.table = {
      value: item.table,
      label: _.upperFirst(item.table)
    };
  }
  if(item.field) {
    data.field = {
      value: item.field,
      label: _.upperFirst(item.field)
    };
  }

  return data;
}