import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { change, untouch, Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import renderSelectField from "../../../../shared/components/form/Select";
import renderDropZoneField from "../../../../shared/components/form/DropZone";
import { createMuiTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: "red"
    }
  });

class FormCreateExploreSections extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      cateStatus: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
      ],
      sectionType: [
        { value: "development", label: "Development" },
        { value: "category", label: "Category" },
        { value: "area", label: "Area" }
      ],
      tabIndex: 0,
      value: 0,
      selectedStatus: { value: "active", label: "Active" },
      selectedType: { value: "development", label: "Development" }
    };
  }

  onChangeStatus = e => {
    this.setState({ selectedStatus: e });
  };

  onChangeType = e => {
    this.resetValue();
    this.setState({ selectedType: e });
  };

  submit = values => {
    const { selectedStatus, selectedType } = this.state;
    let params = {
      title: {
        en: values.enTitle,
        th: values.thTitle
      },
      description: {
        en: values.enDesc,
        th: values.thDesc
      },
      image: values.files,
      order: values.order,
      status: selectedStatus.value,
      type: selectedType.value
    };

    if (selectedType.value === "area") {
      params = {
        ...params,
        value: {
          en: values.enValue,
          th: values.thValue
        }
      };
    }

    this.props.handleCreate(params);
  };

  resetValue() {
    const fields = ["enValue", "thValue"];

    for (let field of fields) {
      this.props.change(field, null);
      this.props.untouch(field);
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  setTabIndex = index => {
    this.setState({ tabIndex: index });
  };

  render() {
    const { handleSubmit, classes } = this.props;
    const {
      cateStatus,
      sectionType,
      selectedStatus,
      selectedType,
      value,
      tabIndex
    } = this.state;

    return (
      <Col md={12} lg={12}>
        <form className="form" onSubmit={handleSubmit(this.submit)}>
          <Card>
            <CardBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => this.setTabIndex(index)}
              >
                <TabList>
                  <Tab>English</Tab>
                  <Tab>Thai</Tab>
                </TabList>
                <TabPanel>
                  <div
                    className="form__form-group"
                    style={{ marginTop: "40px" }}
                  >
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <Field
                        name="enTitle"
                        component="input"
                        type="text"
                        placeholder="Title *"
                        required
                      />
                    </div>
                  </div>
                  {selectedType.value === "area" && (
                    <div
                      className="form__form-group"
                      style={{ marginTop: "40px" }}
                    >
                      <span className="form__form-group-label">Find Place</span>
                      <div className="form__form-group-field">
                        <Field
                          name="enValue"
                          component="input"
                          type="text"
                          placeholder="Value * Example Chatuchak, Bangkok, Thailand"
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <Field
                        name="enDesc"
                        component="input"
                        type="text"
                        placeholder="Description *"
                        required
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="form__form-group"
                    style={{ marginTop: "40px" }}
                  >
                    <span className="form__form-group-label">ชื่อเรื่อง</span>
                    <div className="form__form-group-field">
                      <Field
                        name="thTitle"
                        component="input"
                        type="text"
                        placeholder="ชื่อ *"
                        required
                      />
                    </div>
                  </div>
                  {selectedType.value === "area" && (
                    <div
                      className="form__form-group"
                      style={{ marginTop: "40px" }}
                    >
                      <span className="form__form-group-label">
                        พื้นที่ ที่ค้นหา
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="thValue"
                          component="input"
                          type="text"
                          placeholder="Value *"
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">รายละเอียด</span>
                    <div className="form__form-group-field">
                      <Field
                        name="thDesc"
                        component="input"
                        type="text"
                        placeholder="รายละเอียด *"
                        required
                      />
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              <hr
                style={({ "margin-top": "50px" }, { backgroundColor: "red" })}
              />
              <div className="form__form-group">
                <span className="form__form-group-label">Order(รายการ)</span>
                <div className="form__form-group-field">
                  <Field
                    name="order"
                    component="input"
                    type="number"
                    placeholder="Order *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Status(สถานะ)</span>

                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    options={cateStatus}
                    className="properties__status-select"
                    onChange={this.onChangeStatus}
                    value={selectedStatus}
                    defaultValue={selectedStatus}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Type(ประเภท)</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    options={sectionType}
                    className="properties__status-select"
                    onChange={this.onChangeType}
                    value={selectedType}
                    defaultValue={selectedType}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Image(รูป)</span>
                <div
                  className="form__form-group-field"
                  style={{ margin: "20px 0px 50px", padding: "20px 0px 50px" }}
                >
                  <div className="form__form-group-img">
                    <div className="form__form-group-field-img">
                      <Field name="files" component={renderDropZoneField} />
                    </div>
                  </div>
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="danger" type="submit">
                  Submit
                </Button>
                <Link
                  className="btn btn-primary"
                  to="/company/explore-sections"
                >
                  Cancel
                </Link>
              </ButtonToolbar>
            </CardBody>
          </Card>
        </form>
      </Col>
    );
  }
}

export default reduxForm({
  form: "ExploreSectionForm" // a unique identifier for this form
})(withTranslation("common")(FormCreateExploreSections));
