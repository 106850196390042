import * as ACTIONS from "../actions/exploreSectionDetailAction";
const initialState = {
  loading: false,
  message: "",
  showNotification: false,
  error: false,
  exploreSectionData: []
};

export default function detailExploreSectionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACTIONS.EXPLORE_SECTION_DETAIL_FETCH_REQUESTED:
      return { ...state, loading: true };
    case ACTIONS.EXPLORE_SECTION_DETAIL_FETCH_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        exploreSectionData: data,
        loading: false,
        error: false
      };
    case ACTIONS.CREATE_EXPLORE_SECTION:
      return { ...state, loading: true };
    case ACTIONS.UPDATE_EXPLORE_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        showNotification: true,
        message: "Update Successfully"
      };
    case ACTIONS.UPDATE_EXPLORE_SECTION_FAILED:
      const errors = action.error ? JSON.parse(action.error) : "";
      let message = "";

      return {
        ...state,
        loading: false,
        error: true,
        showNotification: true,
        message: message || errors
      };
    case ACTIONS.CLOSE_EXPLORE_SECTION_MODAL:
      return { ...state, showNotification: false };
    default:
      return state;
  }
}
