import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const { logoutRequest } = this.props;
    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">Admin</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">

            <TopbarMenuLink title="Dashboard" icon="text-align-justify" path="/company/dashboard" />
            <TopbarMenuLink title="Properties" icon="apartment" path="/company/property" />
            <TopbarMenuLink title="Lessors or Sellers" icon="license" path="/company/sellers" />
            <TopbarMenuLink title="Ambassadors" icon="users" path="/company/ambassadors" />
            <TopbarMenuLink title="Chats" icon="bubble" path="/company/chats" />
            <TopbarMenuLink title="Email Templates" icon="envelope" path="/company/email-template" />
            <TopbarMenuLink title="Payment" icon="diamond" path="/company/payment" />
            <TopbarMenuLink title="Currencies" icon="database" path="/company/currencies" />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Log Out" icon="exit" onClick={logoutRequest} />
          </div>
        </Collapse>
      </div>
    );
  }
}
