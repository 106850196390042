import React, { PureComponent } from 'react';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';
import TopbarSearch from './TopbarSearch';
import classNames from "classnames";

class Topbar extends PureComponent {

  render() {
    const { sidebar, logoutRequest } = this.props;
    const topbarClass = classNames({
      topbar: true,
      'top--collapse': sidebar.collapse,
    });
   
    return (
      <div className={topbarClass}>
        <div className="topbar__wrapper">
          <div className="topbar__right">
            <div className="topbar__icon">
              <div className="topbar__avatar">
              <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/app-logo.png`}/>
              </div>
              <span className="topbar__link-title">URBAN ASSETS</span>
            </div>
            
            <div className="topbar__right_container" >
              <TopbarNotification />
              <TopbarProfile
                logoutRequest={logoutRequest}
              />
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
