import { call, put, takeLatest, select } from 'redux-saga/effects'
import { FETCH_SELLER_REQUEST, FETCH_SELLER_SUCCESS, FETCH_SELLER_ERROR, CHANGE_PAGE, CHANGE_ITEM_PER_PAGE } from '../actions'
import { fetchData, formatData } from '../services';
import { ON_SHORT_SELLER } from '../actions/index';

export const getData = (state) => state.sellerReducer;

function* getSellers() {
  try {
    
    const stateData = yield select(getData);
    const { status, itemPerPage, pageNumber, name, sort, desc } = stateData;
    const result = yield call(fetchData, status, itemPerPage, pageNumber, name, sort, desc);
    const formatedData = yield call(formatData, result.data.data);
    const data = {data: formatedData, count: "total" in result.data ? result.data.total.count : 0};
    yield put({ type: FETCH_SELLER_SUCCESS, data});

  } catch (error) {
    yield put({ type: FETCH_SELLER_ERROR, error })
  }
}

function* changePage() {
  yield put({ type: FETCH_SELLER_REQUEST });
}

function* changeItemPerPage() {
  yield put({ type: FETCH_SELLER_REQUEST });
}


function* sellerWatcher() {
  yield takeLatest(FETCH_SELLER_REQUEST, getSellers);
  yield takeLatest(CHANGE_PAGE, changePage);
  yield takeLatest(CHANGE_ITEM_PER_PAGE, changeItemPerPage);
  yield takeLatest(ON_SHORT_SELLER, getSellers);

}

export default sellerWatcher 
