import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormSyncErrors } from "redux-form";
import { withRouter } from "react-router-dom";

import { PATH_URL } from "../../../shared/helpers/path";

import ParameterEditForm from "./components";
import { handleDetailParameter, handleUpdateParameter } from "./redux/actions";

class ParametersDetail extends Component {
  componentDidMount() {
    const {
      match: { params },
      history,
      handleDetailParameter
    } = this.props;
    if (!params.id) {
      history.push(PATH_URL.HOME);
    }
    handleDetailParameter(params.id);
  }

  handleSubmit = event => {
    event.preventDefault();

    const { handleUpdateParameter, errors, form } = this.props;

    if (errors && Object.keys(errors).length === 0) {
      if (
        form &&
        form.parameterForm &&
        form.parameterForm.values &&
        Object.keys(form.parameterForm.values).length > 0
      ) {
        const item = this.handleData(form.parameterForm.values);
        handleUpdateParameter(item);
      }
    }
  };

  handleBack = event => {
    event.preventDefault();

    const { history } = this.props;
    history.push(PATH_URL.PARAMETERS);
  };

  handleData(items) {
    const obj = {};
    if (items) {
      Object.keys(items).map(key => {
        if (items[key] && items[key].value) {
          obj[key] = items[key].value;
        } else {
          obj[key] = items[key];
        }
      });
    }
    return obj;
  }

  render() {
    const {
      form: { parameter },
      parametersDetail,
      initialValues
    } = this.props;

    if (parametersDetail.loadingDetail) {
      return (
        <div
          className={`load${parametersDetail.loadingDetail ? "" : " loaded"}`}
        >
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      );
    }

    return (
      <ParameterEditForm
        parameter={parameter}
        parametersDetail={parametersDetail}
        handleSubmit={this.handleSubmit}
        initialValues={initialValues}
        handleBack={this.handleBack}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameters,
    parametersDetail: state.parametersDetail,
    initialValues: state.parametersDetail.item,
    form: state.form,
    errors: getFormSyncErrors("parameterForm")(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleDetailParameter: id => {
      dispatch(handleDetailParameter(id));
    },
    handleUpdateParameter: item => {
      dispatch(handleUpdateParameter(item));
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParametersDetail)
);
