import React, { Component } from "react";
import { Card, CardBody, Button } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MatTableHead from "./MatTableHead";
import ModalComponent from "../../Modal";
import { PROPERTY_STATUS, USER_STATUS, PAYMENT_STATUS, OWNER_TYPE, VERIFIED_STATUS } from "../../../helpers/constants";

export default class MatTable extends Component {
  state = {
    order: { col: "", value: "" }
  };

  handleRequestSort = col => {
    if (this.props.onShort === undefined) return;
    let { order } = this.state;
    if (order["col"] === col) {
      let value = "desc";
      order["value"] = order["value"] === value ? "asc" : value;
    } else order = { col: col, value: "desc" };
    this.setState({
      order: order
    });
    if (this.props.onShort) this.props.onShort(order);
  };

  handleClick = (event, row) => {
    if (this.props.onClickRow) {
      this.props.onClickRow(row);
    }
  };

  handleCellClick = (event, columId, row) => {
    if (this.props.onClickCell) {
      this.props.onClickCell(columId, row);
    }
  };

  handleChangePage = (event, page) => {
    this.props.onPageChange(page, this.state.orders);
  };

  render() {
    const { order } = this.state;
    const {
      data,
      dataType,
      rowsPerPage,
      count,
      currentPage,
      column,
      header,
      onChangeRowsPerPage,
      showId,
      specificCell,
      actionColumn,
      percents,
      actionColumnCustom,
      disablePaging
    } = this.props;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (currentPage * rowsPerPage));

    return (
      <Card>
        <CardBody>
          <div className="material-table__wrap">
            <Table className="material-table">
              <MatTableHead
                order={order}
                onRequestSort={this.handleRequestSort}
                header={header}
                column={column}
                showId={showId}
                percents={percents}
                actionColumn={actionColumn || actionColumnCustom}
              />
              <TableBody>
                {data.map((d, index) => {
                  const hasProduct = d.hasOwnProperty('products');
                  const hasOwner = d.hasOwnProperty('owner');
                  const hasProductWithCancel = hasProduct && d.products[0].status === PAYMENT_STATUS.CANCEL;
                  const hasProductWithPaid = hasProduct && d.products[0].status === PAYMENT_STATUS.PAID;
                  const hasProductWithUnPaid = hasProduct && d.products[0].status === PAYMENT_STATUS.UNPAID;
                  const isAgent = d.hasOwnProperty('owner') && d.owner.owner_type === OWNER_TYPE.AGENT;
                  const rejectedStatus = d.status === VERIFIED_STATUS.REJECTED;
                  const isAgentAndHasProduct = hasProduct && isAgent;
                  const isAgentAndPaid = hasProductWithPaid && isAgent;
                  const isAgentAndUnPaid = hasProductWithUnPaid && isAgent;
                  const isAgentAndCancel = hasProductWithCancel && isAgent;

                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => this.handleClick(event, d)}
                      tabIndex={-1}
                      key={`row-${index}`}
                    >
                      {showId && (
                        <TableCell
                          onClick={event =>
                            this.handleCellClick(event, "id", d)
                          }
                          align={"center"}
                          padding={"checkbox"}
                          key={`id-${index}`}
                          style={{
                            background: dataType && hasProductWithPaid && !rejectedStatus
                              ? "linear-gradient(to right, #4ce1b6 20%, transparent 50%)"
                              : dataType && hasProductWithUnPaid && !rejectedStatus
                                ? "linear-gradient(to right, #70bbfd 20%, transparent 50%)"
                                : dataType && hasOwner && ((!hasProduct)||(hasProduct && rejectedStatus)||(hasProductWithCancel))
                                  ? "linear-gradient(to right, #ff4861 20%, transparent 50%)"
                                  : null,
                            // "backgroundRepeat": "no-repeat",
                            // "backgroundSize": "100% 100%"
                            }}
                          className={`material-table__cell material-table__cell-center`}
                        >
                          {currentPage * rowsPerPage + index + 1}
                        </TableCell>
                      )}
                      {column.map((col, i) => {
                        if (column[i] === specificCell) {
                          return (
                            <TableCell
                              onClick={event =>
                                this.handleCellClick(event, column[i], d)
                              }
                              key={`Cell-${d[col]}-${index}-${i}`}
                              className="material-table__cell-specific"
                            >
                              {d[col]}
                            </TableCell>
                          );
                        } else if (col === "is_publish") {
                          const oldProperty = [
                            "available",
                            "confirmed",
                            "closed",
                            "closing"
                          ].some(status => status === d.status);

                          let paymentStatusClass = "property-indicator-paying";
                          if (d && d.is_publish) {
                            paymentStatusClass = "property-indicator-paid";
                          } else if (
                            typeof d.is_publish === "undefined" &&
                            oldProperty
                          ) {
                            paymentStatusClass = "property-indicator-undefined";
                          }

                          return (
                            <TableCell
                              onClick={event =>
                                this.handleCellClick(event, column[i], d)
                              }
                              key={`Cell-${d[col]}-${index}-${i}`}
                              className="material-table__cell material-table__cell-right"
                            >
                              <span className={paymentStatusClass}></span>
                            </TableCell>
                          );
                        } else if (col === 'owner_type') {
                          if (hasOwner) {
                            return (
                              <TableCell
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right">
                                {(d.owner.owner_type || d.owner.role)}
                              </TableCell>)
                          } else {
                            return (
                              <TableCell
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right">
                                {d.owner_type || ""}
                              </TableCell>)
                          }

                        } else if (col === 'prices') {
                            return (
                              <TableCell
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right">
                                {d[col] && d[col].amount}
                              </TableCell>)
                        } else if (col === 'products') {
                          const billingName = d.products && d.products[0].hasOwnProperty('billing_name')
                            ? d.products[0].billing_name
                            : ""
                            return (
                              <TableCell
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right">
                                { billingName }
                              </TableCell>)
                        } else if (col === "availability") {
                          if (d.availability === true) {
                            return (
                              <TableCell
                                onClick={event =>
                                  this.handleCellClick(event, column[i], d)
                                }
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right"
                              >
                                YES
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right"
                              >
                                NO
                              </TableCell>
                            );
                          }
                        } else if (col === "phone_number") {
                          if (d.phone_number === "") {
                            return (
                              <TableCell
                                onClick={event =>
                                  this.handleCellClick(event, column[i], d)
                                }
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right"
                              >
                                {"-"}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                onClick={event =>
                                  this.handleCellClick(event, column[i], d)
                                }
                                align={"center"}
                                key={`Cell-${d[col]}-${index}-${i}`}
                                className="material-table__cell material-table__cell-right"
                              >
                                {d[col]}
                              </TableCell>
                            );
                          }
                        } else {
                          return (
                            <TableCell
                              onClick={event =>
                                this.handleCellClick(event, column[i], d)
                              }
                              key={`Cell-${d[col]}-${index}-${i}`}
                              className="material-table__cell material-table__cell-right"
                            >
                              {d[col]}
                            </TableCell>
                          );
                        }
                      })}

                      {actionColumn && (
                        <TableCell
                          key={Math.random()}
                          className="material-table__cell material-table__cell-right "
                        >
                          {actionColumn.map((action, index) => {
                            let item;
                            if (d.type && d.type === "currency") {
                              return (
                                <ModalComponent
                                  key={Math.random()}
                                  color="danger"
                                  btn={action.title}
                                  title="Are you sure remove this currency?"
                                  optionClass=""
                                  confirmAction={() => {
                                    action.action(d._id);
                                  }}
                                />
                              );
                            }

                            if (d.status === USER_STATUS.ACTIVE) {
                              item =
                                action.title === "Deactive" ? (
                                  <Button
                                    key={Math.random()}
                                    onClick={() => {
                                      action.action(d._id);
                                    }}
                                    size="sm"
                                    color={action.color}
                                  >
                                    {action.title}
                                  </Button>
                                ) : (
                                  ""
                                );
                            }
                            if (d.status === USER_STATUS.INACTIVE) {
                              item =
                                action.title === "Active" ? (
                                  <Button
                                    key={Math.random()}
                                    onClick={() => {
                                      action.action(d._id);
                                    }}
                                    size="sm"
                                    color={action.color}
                                  >
                                    {action.title}
                                  </Button>
                                ) : (
                                  ""
                                );
                            }
                            if (
                              d.status === PROPERTY_STATUS.DRAFT ||
                              d.status === PROPERTY_STATUS.VERIFYING ||
                              d.status === PROPERTY_STATUS.AVAILABLE
                            ) {
                              item = (
                                <Button
                                  key={Math.random()}
                                  onClick={() => {
                                    action.action(d._id, d.status, d.stage);
                                  }}
                                  size="sm"
                                  color={action.color}
                                >
                                  Edit
                                </Button>
                              );
                            }

                            return item;
                          })}
                        </TableCell>
                      )}

                      {actionColumnCustom && (
                        <TableCell
                          key={Math.random()}
                          className="material-table__cell material-table__cell-right"
                        >
                          {actionColumnCustom.map((action, index) => {
                            return (
                              <Button
                                key={Math.random()}
                                onClick={e => {
                                  e.stopPropagation();
                                  action.action(d._id);
                                }}
                                size="sm"
                                color={action.color}
                              >
                                {action.title}
                              </Button>
                            );
                          })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </div>

          {(disablePaging === undefined || disablePaging === false) && (
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={count}
              rowsPerPage={parseInt(rowsPerPage)}
              page={currentPage}
              backIconButtonProps={{ "aria-label": "Previous Page" }}
              nextIconButtonProps={{ "aria-label": "Next Page" }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              // onPageChange={this.handleChangePage}
              // onRowsPerPageChange={onChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
              dir="ltr"
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true
              }}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}
