import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Card, CardBody } from 'reactstrap';
import AmbassadorProfile from './components/AmbassadorProfile';
import RelatedProperties from './components/RelatedProperties';
import OnMap from './components/OnMap';
import Appointments from './components/Appointments';
import { PATH_URL } from '../../../shared/helpers/path';
import {
  handleDetailAmbassador,
  handleUpdateAmbassador,
  takeoverPropertyRequest,
  changeAmbassadorActiveReqest,
  changeAmbassadorDeactiveRequest,
  toggleNotification
} from './redux/actions/ambassadorDetailAction';
import Loading from '../../../shared/components/Loading';
import NotificationModal from '../../../shared/components/NotificationModal';
import AgentModal from '../PropertyDetail/components/AgentModal';

class AmbassadorDetail extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      showAmbassadors: false,
      showAgentsModal: false,
      currentProperty: null
    }
  }
  

  componentDidMount() {
    const { match: { params }, history, items, handleDetailAmbassador } = this.props;
    if (!params.id) {
      history.push(PATH_URL.HOME);
    }
    const id = window.location.pathname.split("/").pop();
    // const id = params.id;
    handleDetailAmbassador(id);
  }


  onTakeover = (proId) => {
    this.setState({
      currentProperty: proId
    })
    this.toggleModalAgent();
   
  }

  toggleModalAgent = () => {
    let {showAgentsModal} = this.state;
    this.setState({
      showAgentsModal: !showAgentsModal
    })
  }

  asignAgent = (agentId) => {
    this.toggleModalAgent();
    const {currentProperty} = this.state;
    this.setState({
      currentProperty: null
    });
   
    this.props.takeoverPropertyRequest(currentProperty, agentId);
   
  }

  render() {
    const { match,
      ambassador,
      showLoading,
      notificationType,
      notificationMessage,
      changeAmbassadorActiveReqest,
      changeAmbassadorDeactiveRequest,
      toggleNotification,
      
    } = this.props;

    let {showAgentsModal} = this.state;
    return (
      <Container className="ambassadordetail">
          <AgentModal
          modalprop={showAgentsModal}
          closeModal={this.toggleModalAgent}
          asignAgent={this.asignAgent}
       
        />
        <Loading isOpen={showLoading} />
        <NotificationModal
          isOpen={notificationType !== ""}
          color={notificationType}
          message={notificationMessage}
          toggle={toggleNotification}
        />
        <Card>
          <CardBody>
            <AmbassadorProfile
              profile={ambassador.profile}
              onActive={changeAmbassadorActiveReqest}
              onDeActive={changeAmbassadorDeactiveRequest} />

            {ambassador.followingDeal.length > 0 &&
              <RelatedProperties properties={ambassador.followingDeal} onTakeOver={this.onTakeover}/>
            }

            {ambassador.propertyLocation.length > 0 &&
              <OnMap propertyLocation={ambassador.propertyLocation} />
            }
            <Appointments appointmennts={ambassador.appointments} />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.ambassadorDetailReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDetailAmbassador: (id) => { dispatch(handleDetailAmbassador(id)) },
    handleUpdateAmbassador: (item) => { dispatch(handleUpdateAmbassador(item)) },
    toggleNotification: () => { dispatch(toggleNotification()) },
    changeAmbassadorActiveReqest: (data) => { dispatch(changeAmbassadorActiveReqest(data)) },
    changeAmbassadorDeactiveRequest: (data) => { dispatch(changeAmbassadorDeactiveRequest(data)) },
    takeoverPropertyRequest: (pro, am) => { dispatch(takeoverPropertyRequest(pro, am)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AmbassadorDetail)));
