import React, { Component, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import htmlToDraft from 'html-to-draftjs';

const ToolbarOptions = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const TextEditor = ({ onChange, initValue }) => {
  const [editorState, setEditorState] = useState();
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    if (onChange) { onChange(draftToHtml(convertToRaw(editorState.getCurrentContent()))); }
  }
  useEffect(() => {
    if (initValue && initValue.trim() !== '') {
      const blocksFromHtml = htmlToDraft(initValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initValue])
  return (
    <div className="text-editor">
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
      />
    </div>
  );
}

export default TextEditor;