import React from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faSquare, faTools, faUser } from '@fortawesome/free-solid-svg-icons'

const KeyInforRow = ({ t, name, value, icon, showTanent }) => (
    <Row className="key__infor__row">
        <Col xs={6} md={6} lg={6}>
            <FontAwesomeIcon className="key__infor_name" icon={icon} style={{ marginRight: "10px" }} />
            <span className="key__infor_name">{name}</span>
        </Col>
        <Col xs={6} md={6} lg={6}>
            <span className="key__infor_value">
                {value === "tenants_profile" && <Button onClick={() => { showTanent() }} size="sm" color="primary">{t('propertiesDetail.view_tenants_detail')}</Button>}
                {value !== "tenants_profile" && value}
            </span>
        </Col>
    </Row>
);

const renderKeyinfor = (data) => {
  let result = data.map((item, index) => {
        return <KeyInforRow key={index} name={item.name} value={item.value} icon={item.icon} />
    });
    return result;
}

const KeyInfor = (props) => {

    const { t, listing_type, housing_type, construction_status, area, showTanent, displayTenant } = props;
    const dummy = [
        { icon: faBuilding, name: "Posting type", value: listing_type },
        { icon: faBuilding, name: "Property type", value: housing_type },
        { icon: faTools, name: "Finished construction", value: construction_status },
        { icon: faSquare, name: "Size", value: area }
    ];

    return (
        <Card>
            <CardBody>
                <div>
                    <h3 className="page-title">Keys information</h3>
                    <hr />
                    <div>
                        {renderKeyinfor(dummy)}
                        {displayTenant &&
                            <KeyInforRow t={t} key={Math.random()} name={t('propertiesDetail.tenants_profile')}
                                value={"tenants_profile"} icon={faUser} showTanent={showTanent} />
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}


export default withTranslation('common')(KeyInfor);
