import * as CONSTANTS from './constants';


const handleEditCurrencies = (data) => {
  return {
    type: CONSTANTS.EDIT_CURRENCY_REQUEST,
    data,
  }
}

const fetchListCurrencyParams = () => {
  return {
    type: CONSTANTS.FETCH_CURRENCY_PARAMS_REQUEST,
  }
}

const fetchCurrencyDetail = (id) => {
  return {
    type: CONSTANTS.FETCH_CURRENCY_DETAIL_REQUEST,
    id
  }
}

export {
  handleEditCurrencies,
  fetchListCurrencyParams,
  fetchCurrencyDetail
}

