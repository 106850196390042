import * as CONSTANTS from './constants';

const fetchTransactionFee = () => {
    return {
        type: CONSTANTS.FETCH_TRANSACTION_FEE_REQUEST,
    }
}

const changeTransactionFee = (id, typeTransaction, value) => {
    return {
        type: CONSTANTS.CHANGE_TRANSACTION_FEE,
        id,
        typeTransaction,
        value
    }
}


const getGeneralValue = function getGeneralValue () {
    return {
        type: CONSTANTS.GET_GENERAL_VALUE
    }
}

const getPackages = function getPackages() {
    return {
        type: CONSTANTS.GET_PACKAGES
    }
}

const configPackages = function configPackages(payload) {
    return {
        type: CONSTANTS.CONFIG_PACKAGE,
        payload,
    }
}

export {
    fetchTransactionFee,
    changeTransactionFee,
    getGeneralValue,
    getPackages,
    configPackages,
}

