export const FETCH_PROPERTY_DETAIL_REQUEST = 'FETCH_PROPERTY_DETAIL_REQUEST';
export const FETCH_PROPERTY_DETAIL_SUCCESS = 'FETCH_PROPERTY_DETAIL_SUCCESS';
export const FETCH_PROPERTY_DETAIL_ERROR = 'FETCH_PROPERTY_DETAIL_ERROR';


const fetchPropertyDetail = function fetchPropertyDetail(id) {
    return {
        type: FETCH_PROPERTY_DETAIL_REQUEST,
        id
    }
}

const fetchPropertyDetailSuccess = function fetchPropertyDetailSuccess(property) {
    return {
        type: FETCH_PROPERTY_DETAIL_SUCCESS,
        property
    }
}

const fetchPropertyDetailError = function fetchPropertyDetailError() {
    return {
        type: FETCH_PROPERTY_DETAIL_ERROR,
    }
}

export {
    fetchPropertyDetail,
    fetchPropertyDetailSuccess,
    fetchPropertyDetailError
}