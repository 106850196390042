import { call, put, takeEvery, cancelled } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import { fetchEmailTemplateDetail, updateEmailTemplateDetailAPI } from '../services';

export const getData = (state) => state.chatReducer;

function* getEmailTemplateDetail(action) {
    try {
        const { id } = action.payload;
        yield put({ type: ACTIONS.LOADING });
        const result = yield call(fetchEmailTemplateDetail, id);
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_SUCCEEDED, payload: { data: result.data } });

    } catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}

function* updateEmailTemplateDetail(action) {
    try {
        const { id, data } = action.payload;
        yield put({ type: ACTIONS.LOADING });
        const result = yield call(updateEmailTemplateDetailAPI, id, data);
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_DETAIL_UPDATE_SUCCEEDED, payload: { data: result.data } });

    } catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_DETAIL_UPDATE_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}

function* emailTemplateWatcher() {
    yield takeEvery(ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_REQUESTED, getEmailTemplateDetail);
    yield takeEvery(ACTIONS.EMAIL_TEMPLATE_DETAIL_UPDATE_REQUESTED, updateEmailTemplateDetail);
}

export default emailTemplateWatcher;
