export const LOADING = 'LOADING';
export const AMBASSADOR_FETCH_REQUESTED = 'AMBASSADOR_FETCH_REQUESTED';
export const AMBASSADOR_FETCH_SUCCEEDED = 'AMBASSADOR_FETCH_SUCCEEDED';
export const AMBASSADOR_FETCH_FAILED = 'AMBASSADOR_FETCH_FAILED';
export const HANDLE_CHANGE_TABLE = 'HANDLE_CHANGE_TABLE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_ITEM_PAGE = 'CHANGE_AM_ITEM_PAGE';
export const ON_SHORT_AMBASSADOR = 'ON_SHORT_AMBASSADOR';
export const CHANGE_FILTER_VALUE = 'CHANGE_AMBASSADOR_FILTER';
export const CHANGE_DEFAULT_PAGE = 'CHANGE_DEFAULT_PAGE';
export const RESET_STATE = 'RESET_STATE';

const handleListAmbassador = () => {
  return {
    type: AMBASSADOR_FETCH_REQUESTED

  }
}

const handleChangeTable = (data) => {
  return {
    type: HANDLE_CHANGE_TABLE,
    payload: data
  }
}

const changeItemPage = (number) => {
  return {
    type: CHANGE_ITEM_PAGE,
    number
  }
}

const changePage = (pageNumber) => {
  return {
    type: CHANGE_PAGE,
    pageNumber
  }
}

const onShortAmbassador = (value) => {
  return {
    type: ON_SHORT_AMBASSADOR,
    value
  }
}

const changeFilterValue = function changeFilterValue(filtersData) {
  return {
    type: CHANGE_FILTER_VALUE,
    filtersData
  }
}

const changeDeafultPage = function changeDeafultPage() {
  return {
      type: CHANGE_DEFAULT_PAGE,
      pageNumber: 0,
  }
}

const resetState = () => {
  return {
    type: RESET_STATE
  }
}

export {
  handleListAmbassador,
  handleChangeTable,
  changeItemPage,
  changePage,
  onShortAmbassador,
  changeFilterValue,
  changeDeafultPage,
  resetState
} 
