export const USER_COUNT_REQUEST_START = 'USER_COUNT_REQUEST_START';
export const USER_COUNT_REQUEST_SUCCESS = 'USER_COUNT_REQUEST_SUCCESS';
export const GET_VERIFYING_PROPERTIES = 'GET_VERIFYING_PROPERTIES';
export const GET_CLOSED_PROPERTOES = 'GET_CLOSED_PROPERTOES';
export const GET_ACTIVE_AGENTS = 'GET_ACTIVE_AGENTS';

const userCountRequestStart = function userCountRequestStart () {
    return {
        type: USER_COUNT_REQUEST_START
    }
}

const userCountRequestSuccess = function userCountRequestSuccess (data) {
    return {
        type: USER_COUNT_REQUEST_SUCCESS,
        data
    }
}

const getVerifyingProperties = function getVerifyingProperties (data) {
    return {
        type: GET_VERIFYING_PROPERTIES,
        data
    }
}

const getClosedProperties = function getClosedProperties (data) {
    return {
        type: GET_CLOSED_PROPERTOES,
        data
    }
}

const getActiveAgent = function getActiveUsers (data) {
    return {
        type: GET_ACTIVE_AGENTS,
        data
    }
}

export {
    userCountRequestStart,
    userCountRequestSuccess,
    getVerifyingProperties,
    getClosedProperties,
    getActiveAgent
}

