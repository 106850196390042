import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'

const fetchData = (itemPerPage, pageNumber) => {

    let params = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage,
    };

    return apiCaller(API_URL.CURRENCY, HTTP_METHOD.GET, null, params)
}

const removeCurrency = ({ data }) => {
    return apiCaller(API_URL.CURRENCY + `/delete/${data}`, HTTP_METHOD.PUT)
}

const formatData = (data) => {
    const formatData = data.map(element => ({
        ...element,
        type: 'currency'
    }));

    return formatData;
}
export { fetchData, formatData, removeCurrency }; 