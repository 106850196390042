/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import PropTypes, { element } from "prop-types";
import { Card, CardBody } from "reactstrap";
import { VERIFY_TYPES } from "../helpers/constants";
import CertifiedLogo from "../img/logo/logo_certified.png";
import video_thumb from "../img/video_thumb.png";
import { IMAGE_BASE_URL } from "../helpers";
import exitFullScreenIcon from "../img/icon/exitFullScreen.svg";
import fullScreenIcon from "../img/icon/fullScreen.svg";
import {
  Player,
  ControlBar,
  BigPlayButton,
  Shortcut,
  PlayToggle,
  ProgressControl,
  CurrentTimeDisplay,
  DurationDisplay,
  TimeDivider,
  VolumeMenuButton
} from "video-react";

const SUB_MEDIA_MAX_LENGTH = 5;

export default class ProductGallery extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string
      })
    ).isRequired,
    indexStart: PropTypes.number
  };

  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      currentImageIndex: 0,
      subArrBegin: 0,
      subArrEnd: SUB_MEDIA_MAX_LENGTH,
      currentMedia: null,
      newShortcuts: [
        {
          keyCode: 70,
          handle: () => {}
        }
      ]
    };
  }
  componentDidMount() {
    const { indexStart } = this.props;
    this.setState({ currentMedia: this.props.images[indexStart || 0] });
    window.addEventListener("resize", this.onFSChange.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", null);
  }

  onFSChange = () => {
    const fullScreenElement = window.document.fullscreenElement;
    if (!fullScreenElement) {
      this.setState({ lightboxIsOpen: false });
    }
  };

  changeImg = (img, e) => {
    e.preventDefault();
    const currentIndex = this.findCurrentImageIndex(img);
    if (this.state.currentImageIndex != currentIndex) {
      this.setState({
        currentMedia: img,
        currentImageIndex: currentIndex
      });

      this.changeSubArr(currentIndex);
    }
  };

  changeSubArr = currentImageIndex => {
    const { images } = this.props;
    const { subArrBegin, subArrEnd } = this.state;
    if (currentImageIndex < subArrBegin || currentImageIndex > subArrEnd - 1) {
      if (currentImageIndex < SUB_MEDIA_MAX_LENGTH) {
        this.setState({ subArrBegin: 0, subArrEnd: SUB_MEDIA_MAX_LENGTH });
      } else {
        if (currentImageIndex > images.length - SUB_MEDIA_MAX_LENGTH) {
          this.setState({
            subArrBegin: images.length - SUB_MEDIA_MAX_LENGTH,
            subArrEnd: images.length
          });
        } else {
          this.setState({
            subArrBegin: currentImageIndex - SUB_MEDIA_MAX_LENGTH / 2,
            subArrEnd: currentImageIndex + SUB_MEDIA_MAX_LENGTH / 2
          });
        }
      }
    }
  };

  findCurrentImageIndex = img => {
    const { images } = this.props;
    return images && images.findIndex(item => item === img);
  };

  openLightbox = async event => {
    event.preventDefault();
    const elem = document.getElementById("product-gallery");
    const fullScreenElement = window.document.fullscreenElement;
    if (fullScreenElement) {
      await this.setState({ lightboxIsOpen: false });
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    } else {
      await this.setState({ lightboxIsOpen: true });
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    }
  };

  onPreFullScreenMedia = async (currentImageIndex, e) => {
    const { images } = this.props;
    this.changeImg(images[currentImageIndex - 1], e);
  };
  onNextFullScreenMedia = async (currentImageIndex, e) => {
    const { images } = this.props;
    this.changeImg(images[currentImageIndex + 1], e);
  };

  playVideo = e => {
    if (e.target === this.mainVideo.video.video) {
      e.preventDefault();
      const { player } = this.mainVideo.getState();
      if (player.ended) {
        this.mainVideo.video.play();
      }
    }
  };

  renderMainMedia = () => {
    const { images } = this.props;
    const { currentMedia, lightboxIsOpen, currentImageIndex } = this.state;
    if (currentMedia) {
      const videoWrapper = {
        className: "video_wrapper",
        onDoubleClick: e => this.openLightbox(e),
        onClick: e => this.playVideo(e)
      };
      const playerWrapper = {
        ref: player => (this.mainVideo = player),
        fluid: false,
        src: currentMedia.src
      };
      const currentImageProps = {
        id: "product-img",
        src: currentMedia.src,
        alt: "product-img",
        onDoubleClick: e => this.openLightbox(e)
      };
      const preButtonProps = {
        type: "button",
        onClick: e => this.onPreFullScreenMedia(currentImageIndex, e),
        className: "pre_fullscreen_button"
      };
      const nextButtonProps = {
        type: "button",
        onClick: e => this.onNextFullScreenMedia(currentImageIndex, e),
        className: "next_fullscreen_button"
      };
      const fullScreenProps = {
        className: "full-screen-toggle",
        onClick: e => this.openLightbox(e)
      };
      const fullScreenIconProps = {
        className: "full-screen-icon",
        src: lightboxIsOpen ? exitFullScreenIcon : fullScreenIcon
      };
      return (
        <div className="product_gallery__main_wrapper">
          {lightboxIsOpen && currentImageIndex > 0 && (
            <button {...preButtonProps} />
          )}
          {!currentMedia.src.includes(IMAGE_BASE_URL) ? (
            <div {...videoWrapper}>
              <Player {...playerWrapper}>
                <ControlBar autoHide={true} disableDefaultControls={true}>
                  <PlayToggle />
                  <CurrentTimeDisplay />
                  <TimeDivider />
                  <DurationDisplay />
                  <ProgressControl />
                  <VolumeMenuButton />
                  <div {...fullScreenProps}>
                    <img {...fullScreenIconProps} />
                  </div>
                </ControlBar>
                <BigPlayButton position="center" />
                <Shortcut
                  dblclickable={false}
                  shortcuts={this.state.newShortcuts}
                />
              </Player>
            </div>
          ) : (
            <img {...currentImageProps} />
          )}
          {lightboxIsOpen && currentImageIndex < images.length - 1 && (
            <button {...nextButtonProps} />
          )}
        </div>
      );
    }
  };

  onPreSubMedia = () => {
    this.setState({
      subArrBegin: this.state.subArrBegin - 1,
      subArrEnd: this.state.subArrEnd - 1
    });
  };
  onNextSubMedia = () => {
    this.setState({
      subArrBegin: this.state.subArrBegin + 1,
      subArrEnd: this.state.subArrEnd + 1
    });
  };

  renderSubMedia = () => {
    const { images } = this.props;
    const { subArrBegin, subArrEnd, currentMedia, lightboxIsOpen } = this.state;

    const renderArr = lightboxIsOpen
      ? images
      : images.slice(subArrBegin, subArrEnd);
    let arr = [];
    renderArr &&
      renderArr.map((img, i) => {
        const buttonProps = {
          type: "button",
          key: i,
          onClick: e => this.changeImg(img, e),
          style: { border: currentMedia === img && "3px solid #70bbfd" },
          className: "product-gallery__img-preview"
        };
        const subVideoProps = {
          src: img.src,
          type: img.ext && img.ext.replace(".", "video/"),
          alt: "sub-video"
        };
        const subImgProps = {
          src: img.src,
          alt: "sub-img"
        };

        arr.push(
          <button {...buttonProps}>
            {!img.src.includes(IMAGE_BASE_URL) ? (
              <div style={{ position: "relative" }}>
                <img src={video_thumb} style={{ position: "absolute" }} />
                <video>
                  <source {...subVideoProps} />
                </video>
              </div>
            ) : (
              <img {...subImgProps} />
            )}
          </button>
        );
      });

    if (!lightboxIsOpen && images.length > SUB_MEDIA_MAX_LENGTH) {
      const preButtonProps = {
        key: "pre-sub-button",
        type: "button",
        onClick: () => this.onPreSubMedia(),
        className: "product-gallery__pre-button"
      };
      const nextButtonProps = {
        key: "next-sub-button",
        type: "button",
        onClick: () => this.onNextSubMedia(),
        className: "product-gallery__next-button"
      };
      if (subArrBegin > 0) {
        arr.unshift(<button {...preButtonProps} />);
      }
      if (subArrEnd < images.length) {
        arr.push(<button {...nextButtonProps} />);
      }
    }

    return arr;
  };

  render() {
    const { verify_type } = this.props;
    return (
      <Card>
        <CardBody>
          <div id="product-gallery" className="product-gallery">
            {verify_type === VERIFY_TYPES.VERIFICATION && (
              <img
                src={CertifiedLogo}
                className="product-gallery__img-certified-logo"
              />
            )}
            <div id="main-media" className="product-gallery__current-img">
              {this.renderMainMedia()}
            </div>
            <div className="product_gallery__gallery">
              {this.renderSubMedia()}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
