import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { history } from "../../../shared/helpers/index";
import SellerProfile from "./components/SellerProfile";
import Filter from "./components/PropertiesFilter";
import {
  fetchSellerDeatail,
  changeFilterValue,
  changePage,
  changeItemPerPage,
  fetchPropertyBySeller
} from "./redux/actions/fetchDataAction";
import { connect } from "react-redux";
import MatTable from "../../../shared/components/table/components/MatTable";
import Loading from "../../../shared/components/Loading";

class SellersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAgentList: false,
      properties: []
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onClickRow = this.onClickRow.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const id = window.location.pathname.split("/").pop();
    this.props.fetchSellerDeatail(id);
  }

  onPageChange(page, orders) {
    this.props.changePage(page);
  }

  onClickRow(data) {
    const detailURL = `/company/property/${data.id}`;
    history.push(detailURL);
  }

  onChangeRowsPerPage(event) {
    this.props.changeItemPerPage(event.target.value);
  }

  changeFilterValue = filtersData => {
    this.props.changeFilterValue(filtersData);
  };

  onFilter = () => {
    this.props.fetchPropertyBySeller();
  };

  render() {
    const {
      result,
      pageNumber,
      itemPerPage,
      showLoading,
      status,
      fromDate,
      toDate,
      seller
    } = this.props;

    const { data, count } = result;
    return (
      <Container className="dashboard">
        <Loading isOpen={showLoading} />
        <Card>
          <CardBody>
            <SellerProfile seller={seller} />
            <hr />
            <Filter
              status={status}
              fromDate={fromDate}
              toDate={toDate}
              changeFilterValue={this.changeFilterValue}
              onFilter={this.onFilter}
            />
            {data.length > 0 ? (
              <MatTable
                data={data}
                count={count}
                currentPage={pageNumber}
                rowsPerPage={itemPerPage}
                onPageChange={this.onPageChange}
                column={[
                  "title",
                  "status",
                  "verify_type",
                  "add_time",
                  "upd_time"
                ]}
                header={[
                  "Title",
                  "Status",
                  "Posting Type",
                  "Create Time",
                  "Last Update"
                ]}
                percents={["40%", "10%", "10%", "10%", "10%"]}
                // onRowsPerPageChange={this.onChangeRowsPerPage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                onClickRow={this.onClickRow}
                showId={true}
              />
            ) : (
              <span>No Data</span>
            )}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.sellerDetailReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSellerDeatail: id => {
      dispatch(fetchSellerDeatail(id));
    },
    changeFilterValue: filtersData => {
      dispatch(changeFilterValue(filtersData));
    },
    changePage: pageNumber => {
      dispatch(changePage(pageNumber));
    },
    changeItemPerPage: itemPerPage => {
      dispatch(changeItemPerPage(itemPerPage));
    },
    fetchPropertyBySeller: () => {
      dispatch(fetchPropertyBySeller());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(SellersDetail));
