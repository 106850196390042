import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index'

const createAmbassador = (data) => {

  const formData = new FormData();
  
  if (data.files) {
    
    formData.append("file", data.files[0]);
    delete data["files"];
  }

  let dataRequest = {
    ...data,
    language_code: "language_code" in data ? data.language_code.value : "",
    country_code: "country_code" in data ? data.country_code.value : ""
  }
 
  for (var key in dataRequest) {
    formData.append(key, dataRequest[key]);
  }


  return apiCaller(API_URL.CREATE_AMBASSADOR, HTTP_METHOD.POST, formData)
}

export { createAmbassador };
