import {
    FETCH_PAYMENT_REQUEST,
    FETCH_PAYMENT_SUCCESS,
    FETCH_PAYMENT_ERROR,
    CHANGE_FILTER_VALUE,
    CHANGE_ITEM_PER_PAGE,
    CHANGE_PAGE,
    ON_SHORT_PAYMENT,
    CHANGE_DEFAULT_PAGE
} from '../actions/index';

const initialState = {
    result: { data: [
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"},
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"},
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"},
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"},
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"},
        {_id: "1", first_name: "test", last_name: "A", status: "Deposited", date: "21-Jan-2020"}
    
    ], count: 6 },
    itemPerPage: 10,
    pageNumber: 0,
    showLoading: false,
    rowsPerPage: 10,
    fromDate: "",
    toDate: "",
    name: "",
    sort: "",
    desc: 0
}

const paymentReducer = function paymentReducer(state = initialState, action) {

    switch (action.type) {
        case FETCH_PAYMENT_REQUEST:
            const result = { ...state, showLoading: true };
            return result;

        case FETCH_PAYMENT_SUCCESS:

            return { ...state, showLoading: false, result: action.data }

        case FETCH_PAYMENT_ERROR:
            return { ...state, showLoading: false }

        case CHANGE_FILTER_VALUE:
            const { fromDate, toDate, name } = action.filtersData;
            return { ...state, fromDate, toDate, name }

        case CHANGE_DEFAULT_PAGE:
        case CHANGE_PAGE:
            return { ...state, pageNumber: action.pageNumber }

        case CHANGE_ITEM_PER_PAGE:
            return { ...state, itemPerPage: action.itemPerPage }

        case ON_SHORT_PAYMENT:
            return { ...state, sort: action.value.col, desc: action.value.value === 'desc' ? -1:0 };
        default:
            return state
    }
}

export default paymentReducer
