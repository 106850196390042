import React, { PureComponent } from 'react';
import {  Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { compose, withProps, withStateHandlers } from 'recompose';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs,
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import CloseIcon from 'mdi-react/CloseIcon';
import {GOOGLE_MAP_KEY} from '../../../../shared/helpers/constants';

const MapWithAMarker = compose(
    withProps({
      // generate your API key
      googleMapURL: 'https://maps.googleapis.com/maps/api/js?key='+GOOGLE_MAP_KEY,
      
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div className="map" style={{ height: '360px' }} />,
      mapElement: <div style={{ height: '100%' }} />,
    }),
    withStateHandlers(() => ({
      isOpen: true,
    }), {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }),
    withScriptjs,
    withGoogleMap,
  )(props => (
    
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: props.location.lat, lng:  props.location.lon}}
    >
      
      {
      
      props.isMarkerShown
        && (
        <Marker position={{ lat: props.location.lat, lng: props.location.lon}} onClick={props.onToggleOpen}>
          {/* {props.isOpen
          && (
          <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
            <div className="map__marker-label">
              <div className="map__marker-label-content">
                <div className="map__maker-label-close" onClick={props.onToggleOpen}><CloseIcon /></div>
                DRAKARYS!!!
              </div>
            </div>
          </InfoBox>
          )} */}
        </Marker>
        )}
    </GoogleMap>
  ));

class OnMap extends PureComponent {

    render() {
   
        return (
            <Card>
                <CardBody>
                    <div>
                        <h3 className="page-title">On Map</h3>
                        <hr />
                        <div>
                        <MapWithAMarker isMarkerShown location={this.props.location}/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}


export default withTranslation('common')(OnMap);
