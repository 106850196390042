export const LOADING = "LOADING";
export const EXPLORE_SECTION_FETCH_REQUESTED =
  "EXPLORE_SECTION_FETCH_REQUESTED";
export const EXPLORE_SECTION_FETCH_SUCCEEDED =
  "EXPLORE_SECTION_FETCH_SUCCEEDED";
export const EXPLORE_SECTION_FETCH_FAILED = "EXPLORE_SECTION_FETCH_FAILED";
export const HANDLE_CHANGE_TABLE = "HANDLE_CHANGE_TABLE";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_ITEM_PAGE = "CHANGE_ITEM_PAGE";
export const ON_SORT_EXPLORE_SECTION = "ON_SORT_EXPLORE_SECTION";
export const EXPLORE_SECTION_DELETE_REQUESTED =
  "EXPLORE_SECTION_DELETE_REQUESTED";
export const CHANGE_FILTER_VALUE = "CHANGE_FILTER_VALUE";
export const CHANGE_DEFAULT_PAGE = "CHANGE_DEFAULT_PAGE";
export const RESET_STATE = "RESET_STATE";
export const EXPLORE_SECTION_UPLOAD_IMAGE_REQUESTED =
  "EXPLORE_SECTION_UPLOAD_IMAGE_REQUESTED";
export const EXPLORE_SECTION_UPLOAD_IMAGE_SUCCEEDED =
  "EXPLORE_SECTION_UPLOAD_IMAGE_SUCCEEDED";

const handleListExploreSection = () => {
  return {
    type: EXPLORE_SECTION_FETCH_REQUESTED
  };
};

const handleDeleteExploreSection = id => {
  return {
    type: EXPLORE_SECTION_DELETE_REQUESTED,
    payload: {
      id
    }
  };
};

const setUploadImage = state => {
  return {
    type: EXPLORE_SECTION_UPLOAD_IMAGE_REQUESTED,
    payload: state
  };
};

const handleChangeTable = data => {
  return {
    type: HANDLE_CHANGE_TABLE,
    payload: data
  };
};

const changeItemPage = number => {
  return {
    type: CHANGE_ITEM_PAGE,
    number
  };
};

const changePage = pageNumber => {
  return {
    type: CHANGE_PAGE,
    pageNumber
  };
};

const onSortExploreSection = value => {
  return {
    type: ON_SORT_EXPLORE_SECTION,
    value
  };
};

const changeFilterValue = function changeFilterValue(filtersData) {
  return {
    type: CHANGE_FILTER_VALUE,
    filtersData
  };
};

const changeDefaultPage = function changeDefaultPage() {
  return {
    type: CHANGE_DEFAULT_PAGE,
    pageNumber: 0
  };
};

const resetState = () => {
  return {
    type: RESET_STATE
  };
};

export {
  handleListExploreSection,
  handleDeleteExploreSection,
  handleChangeTable,
  changeItemPage,
  changePage,
  onSortExploreSection,
  changeFilterValue,
  changeDefaultPage,
  resetState,
  setUploadImage
};
