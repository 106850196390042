import { CLIENT_SET, CLIENT_UNSET } from '../actions/clientAction'

const initialSate = {  
  id: null,
  token: null,
  tokenUA: null,
}

const reducer = function clientReducer (state = initialSate, action) {  
  switch (action.type) {
    case CLIENT_SET:
      return {
        id: action.token.userId,
        token: action.token,
        tokenUA: action.tokenUA
      }

    case CLIENT_UNSET:
      return {
        id: null,
        token: null,
        tokenUA: null
      }

    default:
      return state
  }
}

export default reducer
