import * as ACTIONS from '../actions/ambassadorDetailAction';
import { takeOverSuccessFormatData, takeOverSuccessAppoinments } from '../../services/index';
const initialState = {
  ambassador: {
    profile: {
      activeDate: "...",
      address1: "...",
      address2: "...",
      avatar: "...",
      city: "...",
      email: "...",
      fullName: "...",
      phone: "...",
      state: "...",
      status: "",
      zip_code: "...",
    },
    followingDeal: [],
    appointments: [],
    propertyLocation: []
  },
  showLoading: false,
  error: false,
  notificationMessage: "",
  notificationType: ""
}

export default function ambassadorReducer(state = initialState, action) {

  switch (action.type) {
   
    case ACTIONS.LOADING:
      return { ...state, showLoading: true };
    case ACTIONS.AMBASSADOR_DETAIL_FETCH_REQUESTED:
      
      return {
        ambassador: {
          profile: {
            id: "",
            activeDate: "...",
            address1: "...",
            address2: "...",
            avatar: "...",
            city: "...",
            email: "...",
            fullName: "...",
            phone: "...",
            state: "...",
            status: "",
            zip_code: "...",
          },
          followingDeal: [],
          appointments: [],
          propertyLocation: []
        },
        showLoading: true,
        error: false,
        notificationMessage: "",
        notificationType: ""
      };

    case ACTIONS.AMBASSADOR_DETAIL_FETCH_SUCCEEDED:
      return { ...state, ambassador: action.payload, showLoading: false, error: false };

    case ACTIONS.AMBASSADOR_DETAIL_FETCH_FAILED:
      return { ...state, showLoading: false, notificationType: "danger", notificationMessage: action.error };

    case ACTIONS.HANDLE_CHANGE_TABLE:
      return { ...state, ...action.payload, showLoading: false };

    case ACTIONS.TOGGLE_NOTIFICATION:
      return { ...state, notificationType: "" };

    case ACTIONS.CHANGE_AMBASSADOR_DEACTIVE_REQUEST:
    case ACTIONS.CHANGE_AMBASSADOR_ACTIVE_REQUEST:
      
      return { ...state, showLoading: true };

    case ACTIONS.TAKE_OVER_PROPERTY_SUCCESS: {
      const formatedData = takeOverSuccessFormatData(state.ambassador, action.propertyId);
      const formatedAppointments = takeOverSuccessAppoinments(formatedData, action.propertyId);
      return { ...state, ambassador: formatedAppointments,  showLoading: false, notificationType: "success", notificationMessage: action.message }
    }
    case ACTIONS.CHANGE_AMBASSADOR_STATUS_SUCCESS:
    
    let {ambassador} = state;
    let {status} = ambassador.profile;
    status = status === "active" ? "deactive" : "active";
    ambassador.profile.status = status;
    return { ...state, ambassador: ambassador, showLoading: false, notificationType: "success", notificationMessage: action.message }
     
    
    default:
      return state;
  }
}
