import React, { PureComponent, Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import RelatedPropertiesCarousel from "./RelatedPropertiesCarousel";
import PropertyCarouselItem from "../../Dashboard/components/PropertyCarouselItem";
import {currencyFormat, currencySymbol} from '../../../../shared/helpers';


function getDefaultPrice(prices, currencies) {
    let priceLable = "";

    prices.forEach(pri => {

        if (pri.is_default) {
            // priceLable = pri.price + " " + pri.currency_code.toUpperCase();
            priceLable = `${currencyFormat(pri.price)} ${currencySymbol(currencies, pri.currency_code)}`;
        }
    });

    return priceLable;
}

  const renderProperties = (related_properties, currencies, fetchPropertyDetail) => {   
    let properties = related_properties.map((item, index) => {
      const pricelabel = getDefaultPrice(item.prices, currencies)
      const propertyCarouselItemProps = {
        key: index,
        verify_type: item.verify_type,
        media: !!item.galleries ? item.galleries[0] ? item.galleries[0].id : '' : '',
        name: item.title, 
        price: pricelabel,
        address: item.address,
        id: item._id, 
        fetchPropertyDetail: fetchPropertyDetail
      }
      return !item.is_deleted && <PropertyCarouselItem {...propertyCarouselItemProps}/>
    });
    return properties;
  }
  
class RelatedProperties extends Component {

    render() {
      const {related_properties, currencies,fetchPropertyDetail} = this.props;     
        return (
            <Card>
                <CardBody>
                <RelatedPropertiesCarousel items={renderProperties(related_properties, currencies, fetchPropertyDetail)} title={"Related Properties"} />
                </CardBody>
            </Card>
        );
    }

}


export default withTranslation('common')(RelatedProperties);
