export const LOADING = 'LOADING';
export const EMAIL_TEMPLATE_FETCH_REQUESTED = 'EMAIL_TEMPLATE_FETCH_REQUESTED';
export const EMAIL_TEMPLATE_FETCH_SUCCEEDED = 'EMAIL_TEMPLATE_FETCH_SUCCEEDED';
export const EMAIL_TEMPLATE_FETCH_FAILED = 'EMAIL_TEMPLATE_FETCH_FAILED';
export const EMAIL_TEMPLATE_CHANGE_PAGE = 'EMAIL_TEMPLATE_CHANGE_PAGE';
export const EMAIL_TEMPLATE_CHANGE_ROW_PER_PAGE = 'EMAIL_TEMPLATE_CHANGE_ROW_PER_PAGE';
export const EMAIL_TEMPLATE_DELETE_REQUESTED = 'EMAIL_TEMPLATE_DELETE_REQUESTED';
export const EMAIL_TEMPLATE_DELETE_SUCCEEDED = 'EMAIL_TEMPLATE_DELETE_SUCCEEDED';
export const EMAIL_TEMPLATE_DELETE_FAILED = 'EMAIL_TEMPLATE_DELETE_FAILED';

const getEmailTemplate = () => {
    return {
        type: EMAIL_TEMPLATE_FETCH_REQUESTED,

    }
}

const deleteEmailTemplate = (id) => {
    return {
        type: EMAIL_TEMPLATE_DELETE_REQUESTED,
        payload: {
            id
        }
    }
}
const changePage = (page) => {
    return {
        type: EMAIL_TEMPLATE_CHANGE_PAGE,
        payload: {
            page
        }
    }
}

const changeRowPerPage = (rowPerPage) => {
    return {
        type: EMAIL_TEMPLATE_CHANGE_ROW_PER_PAGE,
        payload: {
            rowPerPage
        }
    }
}
export {
    getEmailTemplate,
    changePage,
    changeRowPerPage,
    deleteEmailTemplate,
}