
import { apiCaller, API_URL, HTTP_METHOD, authHeader } from '../../../../../shared/helpers/index'
import axios from "axios";

const fetchData = (id) => {
    return apiCaller(`${API_URL.PROPERTY_DETAIL}/${id}`, HTTP_METHOD.GET)
}

const rejectProperty = (id, message, isPaid) => {
  let params = {
    id: id,
    message: message,
  }

  if (isPaid !== undefined) {
    params['isPaid'] = isPaid
  }

  return apiCaller(API_URL.REJECT_PROPERTY, HTTP_METHOD.POST, params);
}

const changeStatusProperty = async (property_id) => {
    let token = localStorage.getItem('token_UA');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return axios({
        method: HTTP_METHOD.PUT,
        url: API_URL.CHANGE_STATUS_PROPERTY,
        // crossDomain: true,
        data: { property_id: property_id },
        timeout: 100000,
        headers,
        // params: params
    }).catch(function (error) {
        // Server Error
        throw (error);
    });
}

const postOnFaceBookService = async ({ id, propertyLink, message }) => {
    const content = message !== null ? message + "\n" : "";
    return apiCaller(`${API_URL.POST_PROPERTY_ON_SOCIAL_NETWORK}`, HTTP_METHOD.POST, {
        id,
        content,
        link: propertyLink,
        socialNetworkType: "facebook"
    });
}

const postOnInstagramService = async ({ id, message }) => {
  const content = message !== null ? message + "\n" : "";
    return apiCaller(`${API_URL.POST_PROPERTY_ON_SOCIAL_NETWORK}`, HTTP_METHOD.POST, {
        id,
        content,
        socialNetworkType: "instagram"
  });

}

const approveRenewalProperty = async (propertyId) => {
  return apiCaller(API_URL.RENEWAL_PAYMENT, HTTP_METHOD.POST, {
    property_id: propertyId,
    isApproved: true
  });
}

const rejectRenewalProperty = async (propertyId, message) => {
  return apiCaller(API_URL.RENEWAL_PAYMENT, HTTP_METHOD.POST, {
    property_id: propertyId,
    isApproved: false,
    rejectionReason: message
  });
}

const verifyAssignProperty = (id, agentId) => {
    return apiCaller(API_URL.VERIFY_ASSIGN_PROPERTY, HTTP_METHOD.POST, {
        property_id: id,
        user_id: agentId
    })
}

const verifyProperty = (id) => {
    return apiCaller(API_URL.VERIFY_PROPERTY, HTTP_METHOD.POST, {
        id: id,
        message: ""
    });
}

const confirmProperty = (id) => {
    return apiCaller(`${API_URL.CONFIRM_PROPERTY}/${id}`, HTTP_METHOD.PUT);
}

const publishProperty = async (id) => {
    let token = localStorage.getItem('token_UA');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return await axios({
        method: HTTP_METHOD.PUT,
        url: API_URL.PUBLISH_PROPERTY,
        // crossDomain: true,
        data: { property_id: id },
        timeout: 100000,
        headers,
        // params: params
    }).catch(function (error) {
        // Server Error
        throw (error);
    });
}

const publishPropertyWithoutPay = async (id) => {
    let token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return await axios({
        method: HTTP_METHOD.POST,
        url: API_URL.PUBLISH_PROPERTY_WITHOUT_PAY,
        // crossDomain: true,
        data: { property_id: id },
        timeout: 100000,
        headers,
        // params: params
    }).catch(function (error) {
        // Server Error
        throw (error);
    });
}

const getAgents = () => {
    return apiCaller(API_URL.GET_LIST_AGENT, HTTP_METHOD.GET, null, {
        // pageNumber: pageNumber,
        // itemPerPage: itemPerPage
    });
}

const formatData = (data) => {
    let formatData = [];
    data.forEach(element => {
        formatData.push({ id: element._id, name: element.first_name + " " + element.last_name, phone: element.phone_number })
    });
    return formatData;
}

const paymentFormatData = (data) => {
  return data.sort((a, b) => b.add_time - a.add_time);
}

const asignAgent = (propertyId, agentId) => {
    return apiCaller(API_URL.ASIGN_AGENT, HTTP_METHOD.POST, {
        property_id: propertyId,
        user_id: agentId
    })

}


const fetListCurrencies = async () => {
    const requestOptions = {
        method: 'GET'
    };
    const response = await fetch(API_URL.GENERAL_PARAM, requestOptions);
    const res = response.json();
    return res;
}

const getListCurrencies = async () => {
    const value = await fetListCurrencies();
    return value.data.system.currencyCodes;
}

export {
  confirmProperty,
  fetchData,
  rejectProperty,
  changeStatusProperty,
  postOnFaceBookService,
  postOnInstagramService,
  approveRenewalProperty,
  rejectRenewalProperty,
  verifyAssignProperty,
  verifyProperty,
  getAgents,
  formatData,
  paymentFormatData,
  asignAgent,
  getListCurrencies,
  publishProperty,
  publishPropertyWithoutPay
};