export const LOADING = 'LOADING';
export const EDIT_CURRENCY_REQUEST = 'EDIT_CURRENCY_REQUEST';
export const EDIT_CURRENCY_SUCCESS = 'EDIT_CURRENCY_SUCCESS';
export const EDIT_CURRENCY_FAILURE = 'EDIT_CURRENCY_FAILURE';

export const FETCH_CURRENCY_PARAMS_REQUEST = 'FETCH_CURRENCY_PARAMS_REQUEST';
export const FETCH_CURRENCY_PARAMS_SUCCESS = 'FETCH_CURRENCY_PARAMS_SUCCESS';
export const FETCH_CURRENCY_PARAMS_FAILURE = 'FETCH_CURRENCY_PARAMS_FAILURE';

export const FETCH_CURRENCY_DETAIL_REQUEST = 'FETCH_CURRENCY_DETAIL_REQUEST';
export const FETCH_CURRENCY_DETAIL_SUCCESS = 'FETCH_CURRENCY_DETAIL_SUCCESS';
export const FETCH_CURRENCY_DETAIL_FAILURE = 'FETCH_CURRENCY_DETAIL_FAILURE';