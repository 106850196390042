import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkItemsAuthorization } from "./check-auth/index";
import { PATH_URL } from "../../shared/helpers/index";
import { store } from "./redux/store";

export function PrivateRoute({ children, ...rest }) {
  const isAuthor = checkItemsAuthorization(store);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthor ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATH_URL.HOME,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
