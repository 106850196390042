import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { withRouter } from 'react-router-dom';

import ParameterCreateForm from './components';
import { handleCreateParameter } from './redux/actions';
import { PATH_URL } from '../../../shared/helpers/path';

class ParameterCreate extends Component {

  handleSubmit = event => {
    event.preventDefault();

    const { handleCreateParameter, errors, form } = this.props;

    if(errors && Object.keys(errors).length === 0) {
      if(form && form.parameterForm && form.parameterForm.values && Object.keys(form.parameterForm.values).length > 0) {
        const item = this.handleData(form.parameterForm.values);
        handleCreateParameter(item);
      }
    } 
  }

  handleData(items) {
    const obj = {};
    if(items) {
      Object.keys(items).map(key => {
        if(items[key] && items[key].value) {
          obj[key] = items[key].value;
        } else {
          obj[key] = items[key];
        }
      })
    }
    return obj;
  }

  handleBack = event => {
    event.preventDefault();

    const { history } = this.props;
    history.push(PATH_URL.PARAMETERS);
  }

  componentDidUpdate(prevProps) {
    const { parametersCreate, history } = this.props;

    if(parametersCreate.item && parametersCreate.item !== prevProps.parametersCreate.item && !parametersCreate.loading) {
      history.push('/company/parameters');
    }
  }

  render() {
    const { form: { parameter }, parametersCreate } = this.props;

    return (
      <ParameterCreateForm parameter={parameter} handleBack={this.handleBack} 
      parametersCreate={parametersCreate} handleSubmit={this.handleSubmit} />
    )
  }
}

const mapStateToProps = state => {
  return { 
    parameters: state.parameters,
    parametersCreate: state.parametersCreate,
    form: state.form,
    errors: getFormSyncErrors("parameterForm")(state),
   }
}
const mapDispatchToProps = dispatch => {
  return {
    handleCreateParameter: (item) => { dispatch(handleCreateParameter(item))}
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParameterCreate));