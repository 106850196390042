export const CREATE_EXPLORE_SECTION = "CREATE_EXPLORE_SECTION";
export const UPDATE_EXPLORE_SECTION_REQUESTED =
  "UPDATE_EXPLORE_SECTION_REQUESTED";
export const CREATE_EXPLORE_SECTION_SUCCESS = "CREATE_EXPLORE_SECTION_SUCCESS";
export const UPDATE_EXPLORE_SECTION_SUCCESS = "UPDATE_EXPLORE_SECTION_SUCCESS";
export const EXPLORE_SECTION_DETAIL_FETCH_SUCCESS =
  "EXPLORE_SECTION_DETAIL_FETCH_SUCCESS";
export const UPDATE_EXPLORE_SECTION_FAILED = "UPDATE_EXPLORE_SECTION_FAILED";
export const EXPLORE_SECTION_DETAIL_FETCH_REQUESTED =
  "EXPLORE_SECTION_DETAIL_FETCH_REQUESTED";
export const CLOSE_EXPLORE_SECTION_MODAL = "CLOSE_EXPLORE_SECTION_MODAL";
export const LOADING = "LOADING";
const closeModal = () => {
  return {
    type: CLOSE_EXPLORE_SECTION_MODAL
  };
};

const handleDetailExploreSection = id => {
  return {
    type: EXPLORE_SECTION_DETAIL_FETCH_REQUESTED,
    id
  };
};

const handleCreate = data => {
  return {
    type: CREATE_EXPLORE_SECTION,
    data
  };
};

const handleUpdateExploreSection = (id, data) => {
  return {
    type: UPDATE_EXPLORE_SECTION_REQUESTED,
    payload: {
      id,
      data
    }
  };
};

const createSuccess = () => {
  return {
    type: CREATE_EXPLORE_SECTION_SUCCESS
  };
};

const createFail = error => {
  return {
    type: UPDATE_EXPLORE_SECTION_FAILED,
    error
  };
};

export {
  handleDetailExploreSection,
  handleCreate,
  handleUpdateExploreSection,
  createSuccess,
  createFail,
  closeModal
};
