import React, {useState} from 'react';
import { chatRoomSearch } from './../redux/actions/chatAction';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const ChatSearch = (props) => {
  const [searchTitle, setSearchTitle] = useState('');

  const handleSearchRoom = e => {
    try {
      let searchQuery = {};
      setSearchTitle(e.target.value);
      if (e.target.value) {
        searchQuery = { property_title: e.target.value };
      }
      props.chatRoomSearch(searchQuery)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="chat__search">
      <input className="chat__search-input" placeholder="Property name" value={searchTitle} onChange={handleSearchRoom}/>
      <MagnifyIcon />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.chatReducer,
  }
}
export default connect(mapStateToProps, {
  chatRoomSearch
})(withTranslation('common')(ChatSearch));
