import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../../shared/components/form/Select";
import { Col, Button, Row } from "reactstrap";
import {
  EXPLORE_SECTION_STATUS_FILTER,
  EXPLORE_SECTION_TYPE_FILTER
} from "../../../../shared/helpers/constants";

class Filter extends PureComponent {
  onChangeStatus = e => {
    this.props.changeFilterValue({ status: e });
  };
  onChangeType = e => {
    this.props.changeFilterValue({ type: e });
  };

  onChangeTitle = e => {
    let title = e.target.value;
    title = title ? title.trim() : title;
    this.props.changeFilterValue({ title });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onFilter();
  };

  render() {
    const { status, title, isAsign, type } = this.props.data;

    return (
      <Row>
        <form
          style={{
            width: "100%",
            background: "white",
            padding: "28px 0 0 26px"
          }}
          className="form form--horizontal"
          onSubmit={this.handleSubmit}
        >
          <Col md={3} lg={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="property_name"
                  component="input"
                  type="text"
                  onChange={this.onChangeTitle}
                  className="properties__filer-border properties__status-select"
                  value={title}
                />
              </div>
            </div>
          </Col>

          <Col md={3} lg={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">Status</span>
              <div className="form__form-group-field">
                <Field
                  name="status"
                  component={renderSelectField}
                  options={EXPLORE_SECTION_STATUS_FILTER}
                  className="properties__status-select"
                  onChange={this.onChangeStatus}
                  value={status}
                  defaultValue={status}
                />
              </div>
            </div>
          </Col>

          <Col md={3} lg={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">Type</span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  options={EXPLORE_SECTION_TYPE_FILTER}
                  className="properties__status-select"
                  onChange={this.onChangeType}
                  value={type}
                  defaultValue={type}
                />
              </div>
            </div>
          </Col>

          <Col md={3} lg={2}>
            <Button color="primary" action="submit" size="sm">
              Filter
            </Button>
          </Col>
        </form>
      </Row>
    );
  }
}

export default reduxForm({
  form: "horizontal_form" // a unique identifier for this form
})(withTranslation("common")(Filter));
