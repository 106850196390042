import React, { Component } from 'react';
import Ambassadors from '../../../Ambassadors';
export default class AgentTable extends Component {
  render() {
    return (
      <div>
        <Ambassadors isAsign={true} selectAgent={this.props.selectAgent} />
      </div>
    );
  }
}
