export const FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_ERROR = 'FETCH_PAYMENT_ERROR';
export const CHANGE_PAGE = 'CHANGE_PAGE_PAYMENT';
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
export const CHANGE_ITEM_PER_PAGE = 'CHANGE_ITEM_PER_PAGE';
export const ON_SHORT_PAYMENT = 'ON_SHORT_PAYMENT';
export const CHANGE_DEFAULT_PAGE = 'CHANGE_DEFAULT_PAGE';

const fetchPayment = function fetchPAYMENT() {
    return {
        type: FETCH_PAYMENT_REQUEST
    }
}

const fetchPaymentSuccess = function fetchPaymentSuccess(data) {
    return {
        type: FETCH_PAYMENT_SUCCESS,
        data
    }
}

const fetchPaymentError = function fetchPaymentError() {
    return {
        type: FETCH_PAYMENT_ERROR,
    }
}

const changeFilterValue = function changeFilterValue(filtersData) {
    return {
        type: CHANGE_FILTER_VALUE,
        filtersData
    }
}

const changePage = function changePage(pageNumber) {
    return {
        type: CHANGE_PAGE,
        pageNumber: pageNumber
    }
}

const changeItemPerPage = function changeItemPerPage(itemPerPage) {
    return {
        type: CHANGE_ITEM_PER_PAGE,
        itemPerPage: itemPerPage
    }
}

const onShortPayment = (value) => {
    return {
        type: ON_SHORT_PAYMENT,
        value
    }
}

const changeDefaultPage = () => {
    return {
        type: CHANGE_DEFAULT_PAGE,
        pageNumber: 0
    }
}

export {
    fetchPayment,
    fetchPaymentSuccess,
    fetchPaymentError,
    changeFilterValue,
    changeItemPerPage,
    changePage,
    onShortPayment,
    changeDefaultPage
}

