import {
  call,
  put,
  takeLatest,
  takeEvery,
  cancelled,
  select
} from "redux-saga/effects";
import * as ACTIONS from "../redux/actions/exploreSectionAction";
import { history, PATH_URL } from "../../../../shared/helpers/index";
import {
  fetchListExploreSection,
  formatData,
  deleteExploreSectionService
} from "../services/index";

export const getData = state => state.exploreSectionReducer;

function* getListExploreSection() {
  try {
    yield put({ type: ACTIONS.LOADING });
    const stateData = yield select(getData);
    const {
      pageNumber,
      itemPerPage,
      title,
      status,
      type,
      sort,
      desc
    } = stateData;

    const paramsObj = {
      pageNumber,
      itemPerPage,
      title,
      status: status.value,
      type: type.value,
      sort,
      desc
    };

    const result = yield call(fetchListExploreSection, paramsObj);
    const formatedData = yield call(formatData, result.data.data);

    yield put({
      type: ACTIONS.EXPLORE_SECTION_FETCH_SUCCEEDED,
      payload: {
        items: formatedData ? formatedData : [],
        count: result.data.total ? result.data.total.count : 0
      }
    });
  } catch (error) {
    yield put({ type: ACTIONS.EXPLORE_SECTION_FETCH_FAILED, error });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* deleteExploreSection(action) {
  try {
    const { id } = action.payload;
    yield call(deleteExploreSectionService, id);
    yield put({ type: ACTIONS.EXPLORE_SECTION_FETCH_REQUESTED });
  } catch (error) {
    yield put({ type: ACTIONS.EXPLORE_SECTION_FETCH_FAILED, error });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* exploreSectionWatcher() {
  yield takeEvery(
    [
      ACTIONS.EXPLORE_SECTION_FETCH_REQUESTED,
      ACTIONS.CHANGE_ITEM_PAGE,
      ACTIONS.CHANGE_PAGE,
      ACTIONS.ON_SORT_EXPLORE_SECTION
    ],
    getListExploreSection
  );

  yield takeLatest(
    ACTIONS.EXPLORE_SECTION_DELETE_REQUESTED,
    deleteExploreSection
  );
}

export default exploreSectionWatcher;
