export const LOADING = 'LOADING';
export const EMAIL_TEMPLATE_CREATE_REQUESTED = 'EMAIL_TEMPLATE_CREATE_REQUESTED';
export const EMAIL_TEMPLATE_CREATE_SUCCEEDED = 'EMAIL_TEMPLATE_CREATE_SUCCEEDED';
export const EMAIL_TEMPLATE_CREATE_FAILED = 'EMAIL_TEMPLATE_CREATE_FAILED';
export const LOAD_EMAIL_TYPES = 'LOAD_EMAIL_TYPES';
export const LOAD_EMAIL_SUCCESS = 'LOAD_EMAIL_SUCCESS';
const createEmailTemplate = (data) => {
    return {
        type: EMAIL_TEMPLATE_CREATE_REQUESTED,
        payload: {
            data,
        }
    }
}

const loadEmailTypes = () => {
    return {
        type: LOAD_EMAIL_TYPES
    }
}


export {
    createEmailTemplate,
    loadEmailTypes
}