import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'

const fetchData = () => {
    return apiCaller(API_URL.GET_TRANSACTION_FEE, HTTP_METHOD.GET, null, null)
}

const updateTransactionFee = (id, type, value) => {
    return apiCaller(API_URL.UPDATE_TRANSACTION_FEE + `${id}`, HTTP_METHOD.PUT, {
        name: type,
        type: type,
        value: value,
        currency: "USD",
        status: "active"
    })
}

const getGeneralValue = async () => {
    const requestOptions = {
        method: 'GET'
    };
    const response = await fetch(API_URL.GENERAL_PARAM, requestOptions);
    const res = response.json();
    return res;
}

const getPackages = async () => {
    return apiCaller(API_URL.GET_PACKAGE, HTTP_METHOD.GET, null, null);
}

const configPackage = async (body) => {
    return apiCaller(API_URL.CONFIG_PACKAGE, HTTP_METHOD.PUT, body, null)
}
 
export { 
    fetchData, 
    updateTransactionFee, 
    getGeneralValue,
    getPackages,
    configPackage
}; 