import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../shared/components/Modal';
import { PAYMENT_STATUS, OWNER_TYPE } from '../../../../shared/helpers/constants';
import instagramIcon from '../../../../shared/img/instagram.png';
import facebookIcon from '../../../../shared/img/facebook.png';

function renderVerify(verify_type, verifyProperty) {
    if (verify_type === "verification") {
        return <Modal
            color="primary"
            btn="Post as Certified"
            title="Are you sure this property has been posted as Certified?"
            optionClass="property__verification-btn"
            confirmAction={verifyProperty}
        />
    } else {
        return <Modal
            color="primary"
            btn="Post as Un-Certify"
            title="Are you sure this property has been posted as Un-Certify?"
            optionClass="property__verification-btn"
            confirmAction={verifyProperty}
        />
    }
}

const renewalButton = (approveRenewalPayment, rejectRenewalPayment) => {
  return(
    <>
      <Modal
        color="primary"
        btn="Approve Renewal"
        title="Are you sure to approve this renewal payment?"
        optionClass="property__verification-btn property__reject"
        withTextMessage={false}
        renewal={true}
        confirmAction={approveRenewalPayment}
      />
      <Modal
        color="danger"
        btn="Reject Renewal"
        title="Are you sure to reject this renewal payment?"
        optionClass="property__verification-btn property__reject"
        withTextMessage={true}
        renewal={false}
        confirmAction={rejectRenewalPayment}
      />
    </>
  )
}

const renderRejectButton = (rejectProperty, verifiedStatus, ownerType) => {

  if (verifiedStatus === "pending") {
    return (
      <>
        <Modal
          color="warning"
          btn="Reject With Paid"
          title="Are you sure to reject this property with message inputted bellow"
          optionClass="property__verification-btn property__reject"
          withTextMessage={true}
          confirmAction={rejectProperty}
          isPaid={true}
          />
        <Modal
          color="danger"
          btn="Reject Without Paid"
          title="Are you sure to reject this property with message inputted bellow"
          optionClass="property__verification-btn property__reject"
          withTextMessage={true}
          confirmAction={rejectProperty}
          isPaid={false}
          />
      </>
    )
  } else if (verifiedStatus === "pending") {
    return (
       <Modal
        color="danger"
        btn="Reject"
        title="Are you sure to reject this property with message inputted bellow"
        optionClass="property__verification-btn property__reject"
        withTextMessage={true}
        confirmAction={rejectProperty}
      />
    )
  }

}

const ActionGroup = (props) => {

  const { verify_type, rejectProperty, verifyProperty, approveRenewalPayment, rejectRenewalPayment, changeStatusProperty, postOnInstagram, postOnFB, verified_status, owner, status, availability, product, publishProperty, isPublish, publishWithoutPay } = props;

  const { status: productStatus } = product.length !== 0 && product[0];
  const { owner_type, role } = owner;

  // const { status: productStatus } = product.length !== 0 && [...product].pop();
  // const { status: productStatus } = product.slice(-1)[0];

  return (
        <div>
          {status === 'available' && availability &&
            <>
              <Modal
                color="primary"
                logoIcon={facebookIcon}
                btn="Post on Facebook"
                title="Are you sure to post this property on facebook page?"
                optionClass="property__verification-btn property__reject"
                withTextMessage={true}
                confirmAction={postOnFB}
              />
              <Modal
                color="primary"
                logoIcon={instagramIcon}
                btn="Post on Instagram"
                title="Are you sure to post this property on Instagram?"
                optionClass="property__verification-btn property__reject"
                withTextMessage={true}
                confirmAction={postOnInstagram}
              />
            </>
          }
          {verified_status === "pending" && renderVerify(verify_type, verifyProperty)}
          {product.length !== 0 &&
            status === 'available' &&
            // owner_type === OWNER_TYPE.AGENT &&
            productStatus === PAYMENT_STATUS.UNPAID &&
            renewalButton(approveRenewalPayment, rejectRenewalPayment)
          }
          {status === 'available' &&
              <Modal
                color="primary"
                btn="Change to draft"
                title="Are you sure to change status of this property to Draft?"
                optionClass="property__verification-btn property__reject"
                withTextMessage={false}
                confirmAction={changeStatusProperty}
              />
          }
          {owner_type !== OWNER_TYPE.AGENT && status === 'available' && isPublish !== true &&
              <Modal
                color="success"
                btn="Publish"
                title="Are you sure to change status of this property to Publish? (The property is paid)"
                optionClass="property__verification-btn property__reject"
                withTextMessage={false}
                confirmAction={publishWithoutPay}
              />
          }
          {owner_type !== OWNER_TYPE.AGENT && status === 'paying' &&
              <Modal
                color="success"
                btn="Publish"
                title="Are you sure to change status of this property to Publish? (The property is paid)"
                optionClass="property__verification-btn property__reject"
                withTextMessage={false}
                confirmAction={publishProperty}
              />
          }
          { renderRejectButton(rejectProperty, verified_status, owner_type)}
        </div>
    );
}


export default withTranslation('common')(ActionGroup);
