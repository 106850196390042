import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'

const fetchDataDetail = (id) => {
  return apiCaller(API_URL.CURRENCY + `/${id}`, HTTP_METHOD.GET, null, null);
}

const fetchData = () => {
  return apiCaller(API_URL.CURRENCY + '/currency_params', HTTP_METHOD.GET, null);
}

const editCurrency = (id, data) => {

  return apiCaller(API_URL.CURRENCY + `/update/${id}`, HTTP_METHOD.PUT, data);
}

const formatData = (data) => {
  const formatData = data.map(element => ({
    ...element,
    value: element.code,
    label: element.name
  }));

  return formatData;
}

export { editCurrency, fetchData, formatData, fetchDataDetail }; 