import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import {
  sidebarReducer,
  themeReducer
} from "../../Layout/redux/reducers/index";
import {
  loginReducer,
  clientReducer
} from "../../Company/LogIn/redux/reducers/index";
import { parameterReducer } from "../../Company/Parameters/redux/reducers";
import { parameterCreateReducer } from "../../Company/ParametersCreate/redux/reducers";
import { parameterDetailReducer } from "../../Company/ParametersDetail/redux/reducers";
import { propertyReducer } from "../../Company/Properties/redux/reducers";
import { sellerReducer } from "../../Company/Sellers/redux/reducers";
import { ambassadorReducer } from "../../Company/Ambassadors/redux/reducers";
import { exploreSectionReducer } from "../../Company/ExploreSections/redux/reducers";
import { ambassadorDetailReducer } from "../../Company/AmbassadorDetail/redux/reducers";
import { exploreSectionDetailReducer } from "../../Company/ExploreSectionsDetail/redux/reducers";
import { propertyDetailReducer } from "../../Company/PropertyDetail/redux/reducers";
import { sellerDetailReducer } from "../../Company/SellersDetail/redux/reducers";
import { dashboardReducer } from "../../Company/Dashboard/redux/reducers";
import { currencyReducer } from "../../Company/Currencies/redux/reducers";
import { transactionFeeReducer } from "../../Company/SettingTransactionFee/redux/reducers";
import { currentiesCreateReducer } from "../../Company/CurrenciesCreate/redux/reducers";
import { currentiesEditReducer } from "../../Company/CurrenciesEdit/redux/reducers";
import { createAmbassadorReducer } from "../../Company/Agents/redux/reducers";
import { createExploreSectionReducer } from "../../Company/ExploreSectionsCreate/redux/reducers";
import { accountsReducer } from "../../Company/Accounts/redux/reducers";
import { chatReducer } from "../../Company/Chats/redux/reducers";
import { paymentReducer } from "../../Company/Payment/redux/reducers";
import { emailTemplateReducer } from "../../Company/EmailTemplate/redux/reducers";
import { emailTemplateDetailReducer } from "../../Company/EmailTemplateDetail/redux/reducers";
import { emailTemplateCreateReducer } from "../../Company/EmailTemplateCreate/redux/reducers";

export const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  loginReducer: loginReducer,
  clientReducer: clientReducer,
  propertyDetailReducer: propertyDetailReducer,
  parameters: parameterReducer,
  parametersCreate: parameterCreateReducer,
  parametersDetail: parameterDetailReducer,
  propertyReducer: propertyReducer,
  ambassadorReducer: ambassadorReducer,
  exploreSectionReducer: exploreSectionReducer,
  ambassadorDetailReducer: ambassadorDetailReducer,
  exploreSectionDetailReducer: exploreSectionDetailReducer,
  dashboardReducer: dashboardReducer,
  currentiesCreateReducer: currentiesCreateReducer,
  currentiesEditReducer: currentiesEditReducer,
  currencyReducer: currencyReducer,
  transactionFeeReducer: transactionFeeReducer,
  createAmbassadorReducer: createAmbassadorReducer,
  createExploreSectionReducer: createExploreSectionReducer,
  accountsReducer: accountsReducer,
  sellerReducer: sellerReducer,
  sellerDetailReducer: sellerDetailReducer,
  chatReducer: chatReducer,
  paymentReducer: paymentReducer,
  emailTemplateReducer: emailTemplateReducer,
  emailTemplateDetailReducer: emailTemplateDetailReducer,
  emailTemplateCreateReducer: emailTemplateCreateReducer
});
