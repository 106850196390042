import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { Link } from "react-router-dom";
import EmailTemplateLangTab from "./EmailTemplateLangTab";
import { connect } from 'react-redux';

let EmailTemplateForm = ({ handleSubmit, templatesData, initialValues }) => {
    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="form__form-group">
                <span className="form__form-group-label">Template name:</span>
                <div className="form__form-group-field">
                    <Field
                        name="title"
                        component="input"
                        type="text"
                        placeholder="Template name"
                        required
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Description:</span>
                <div className="form__form-group-field">
                    <Field
                        name="description"
                        component="input"
                        type="text"
                        placeholder="Description"
                        required
                    />
                </div>
            </div>
            {/* Tabs Lang */}
            <div className="form__form-group">
                <span className="form__form-group-label">Templates:</span>
                <FieldArray name="templates" component={EmailTemplateLangTab} initialValues={initialValues} templatesData={templatesData} />
            </div>
            {/* <EmailTemplateEditor /> */}
            <ButtonToolbar className="form__button-toolbar">
                <Button color="danger" type="submit">Save changes</Button>
                <Link className="btn btn-primary" to="/company/email-template">Cancel</Link>
            </ButtonToolbar>
        </form>
    );
}

EmailTemplateForm = reduxForm({
    form: 'EmailTemplateForm', // a unique identifier for this form
    enableReinitialize: true,
    // validate
})(withTranslation('common')(EmailTemplateForm));

const selector = formValueSelector('EmailTemplateForm');

export default connect(
    state => ({
        initialValues: state.emailTemplateDetailReducer.emailTemplateData, // pull initial values from account reducer
        templatesData: selector(state, 'templates')
    }),
    {}//bind actions
)(EmailTemplateForm);