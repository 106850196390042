import React, { Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link, withRouter } from "react-router-dom";

import MatTable from '../../../../shared/components/table/components/MatTable';

const ListParameters = (props) => (
  <Fragment>
    <Col md={12}>
      <Card>
        <CardBody>
          <MatTable {...props} />
        </CardBody>
      </Card>
    </Col>
    <Col>
      <Link className="btn btn-primary btn-sm" to="/company/parameters/create">Create New</Link>
    </Col>
  </Fragment>
);

export default withRouter(ListParameters);
