import {
    FETCH_PROPERTY_DETAIL_REQUEST,
    FETCH_PROPERTY_DETAIL_SUCCESS,
    FETCH_PROPERTY_DETAIL_ERROR
} from '../actions/fetchDataAction';

import {
    REJECT_PROPERTY,
    REJECT_PROPERTY_SUCCESS,
    REJECT_PROPERTY_ERROR,
    VERIFY_PROPERTY,
    VERIFY_PROPERTY_SUCCESS,
    VERIFY_PROPERTY_ERROR,
    REJECT_RENEWAL_PAYMENT,
    REJECT_RENEWAL_PAYMENT_SUCCESS,
    REJECT_RENEWAL_PAYMENT_ERROR,
    APPROVE_RENEWAL_PAYMENT,
    APPROVE_RENEWAL_PAYMENT_REQUEST,
    APPROVE_RENEWAL_PAYMENT_SUCCESS,
    APPROVE_RENEWAL_PAYMENT_ERROR,
    TOGLE_NOTIFICATON,
    CONFIRM_PROPERTY_SUCCESS,
    CHANGE_STATUS_PROPERTY,
    CHANGE_STATUS_PROPERTY_SUCCESS,
    CHANGE_STATUS_PROPERTY_ERROR,
    POST_ON_FACEBOOK,
    POST_ON_FACEBOOK_SUCCESS,
    POST_ON_FACEBOOK_ERROR,
    POST_ON_INSTAGRAM,
    POST_ON_INSTAGRAM_SUCCESS,
    POST_ON_INSTAGRAM_ERROR,
} from '../actions/verifyPropertyAction';

import {
    FETCH_AGENTS_REQUEST,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_ERROR,
    TOGGLE_MODAL_AGENT,
    ASIGN_AGENT_ERROR,
    ASIGN_AGENT,
    ASIGN_AGENT_SUCCESS
} from '../actions/asignAgentAction';

const initialState = {
    property: null,
    showAgentList: false,
    showLoading: false,
    id: "",
    message: "",
    notificationMessage: "",
    notificationType: "",
    showAgentsModal: false,
    agents: []
}

const propertyReducer = function propertyReducer(state = initialState, action) {
    let { property } = state;
    switch (action.type) {
        case FETCH_PROPERTY_DETAIL_REQUEST:
          const result = {
              property: null,
              showAgentList: false,
              message: "",
              notificationMessage: "",
              notificationType: "",
              showAgentsModal: false,
              showLoading: true,
              id: action.id,
              agents: []
          };
          return result;

        case FETCH_PROPERTY_DETAIL_SUCCESS:
          return { ...state, showLoading: false, property: action.property };

        case FETCH_PROPERTY_DETAIL_ERROR:
          return { ...state, showLoading: false }

        case REJECT_PROPERTY:
          return { ...state, showLoading: true, message: action.message, isPaid: action.isPaid };

        case POST_ON_FACEBOOK: {
          return { ...state, showLoading: true, message: action.message };
        }

        case POST_ON_FACEBOOK_SUCCESS:
          return {...state, showLoading: false, notificationMessage: action.message, notificationType: "success" };

        case POST_ON_FACEBOOK_ERROR:
          return { ...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" };

        case POST_ON_INSTAGRAM: {
          return { ...state, showLoading: true, message: action.message };
        }

        case POST_ON_INSTAGRAM_SUCCESS:
          return {...state, showLoading: false, notificationMessage: action.message, notificationType: "success" };

        case POST_ON_INSTAGRAM_ERROR:
          return { ...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" };

        case CHANGE_STATUS_PROPERTY: {
          return { ...state, showLoading: true };
        }

        case APPROVE_RENEWAL_PAYMENT_REQUEST: {
          return { ...state, showLoading: true };
        }

        case APPROVE_RENEWAL_PAYMENT: {
          return {
            ...state,
            showLoading: true
          };
        }

        case REJECT_RENEWAL_PAYMENT: {
          return {
            ...state,
            showLoading: true,
            message: action.message
          };
        }

        case REJECT_RENEWAL_PAYMENT_SUCCESS:
          return {...state, showLoading: false, notificationMessage: action.message, notificationType: "success" };

        case REJECT_RENEWAL_PAYMENT_ERROR:
        return { ...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" };

        case APPROVE_RENEWAL_PAYMENT_SUCCESS:
          return {...state, showLoading: false, notificationMessage: action.message, notificationType: "success" };

        case APPROVE_RENEWAL_PAYMENT_ERROR:
          return {...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger"};

        case CHANGE_STATUS_PROPERTY_SUCCESS:
          return {...state, showLoading: false, notificationMessage: action.message, notificationType: "success"};

        case CHANGE_STATUS_PROPERTY_ERROR:
          return {...state, showLoading: false, notificationMessage: action.error, notificationType: "danger"};

        case REJECT_PROPERTY_SUCCESS:
            property.verified_status = "rejected";
          return { ...state, property: property, showLoading: false, notificationMessage: action.message, notificationType: "success" }

        case REJECT_PROPERTY_ERROR:
          return { ...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" }

        case VERIFY_PROPERTY:
          return { ...state, showLoading: true };

        case VERIFY_PROPERTY_ERROR:
          return { ...state, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" }

        case TOGLE_NOTIFICATON:
          return { ...state, notificationMessage: "", notificationType: "" };

        case FETCH_AGENTS_REQUEST:
          return { ...state, showLoading: true };

        case FETCH_AGENTS_SUCCESS:
          return { ...state, agents: action.agents, showAgentsModal: true, showLoading: false };

        case TOGGLE_MODAL_AGENT:
          const { showAgentsModal } = state;
          return { ...state, showAgentsModal: !showAgentsModal }

        case ASIGN_AGENT:
          return { ...state, showAgentsModal: false, showLoading: true };

        case ASIGN_AGENT_SUCCESS:
          property.verified_status = "verified";
          return { ...state, property: property, showAgentsModal: false, showLoading: false, notificationMessage: action.message, notificationType: "success" }

        case ASIGN_AGENT_ERROR:
          return { ...state, showAgentsModal: false, showLoading: false, notificationMessage: action.error.message, notificationType: "danger" }

        case CONFIRM_PROPERTY_SUCCESS:
          property.status = "confirmed";
          return { ...state, property: property, showLoading: false, notificationMessage: action.message, notificationType: "success" }

        default:
          return state
    }
}

export default propertyReducer
