import momment from "moment";
import {
  apiCaller,
  API_URL,
  HTTP_METHOD,
} from "../../../../../shared/helpers/index";
import { FORMAT_DATE, FORMAT_DATETIME } from "../../../../../shared/helpers/constants";
import { VERIFIED_STATUS } from "../../../../../shared/helpers/constants";

const fetchData = (
  status,
  fromDate,
  toDate,
  itemPerPage,
  pageNumber,
  name,
  sort,
  desc,
  paymentStatus
) => {
  let params = {
    pageNumber: pageNumber,
    itemPerPage: itemPerPage,
  };

  if (sort !== "") {
    params["sort"] = sort;
    params["desc"] = desc;
  }

  if (fromDate !== "") {
    params["fromDate"] = fromDate;
  }

  if (toDate !== "") {
    params["toDate"] = toDate;
  }

  if (name !== "") {
    params["title"] = name;
  }

  if (paymentStatus !== "") {
    params["paymentStatus"] = paymentStatus;
  }

  if (status)
    switch (status.value) {
      case "verified":
      case "none-verified":
      case "rejected":
        params["verifyStatus"] = status.value;
        break;
      case "verifying":
        params["verifyStatus"] = VERIFIED_STATUS.PENDING;
        params["status"] = "posting";
        break;
      case "closing":
      case "closed":
      case "available":
      case "confirmed":
      case "ongoing":
        params["status"] = status.value;
        break;
      case "paying":
        params["status"] = status.value;
        break;
      default:
        break;
    }

  return apiCaller(API_URL.GET_LIST_PROPERTY, HTTP_METHOD.GET, null, params);
};

const formatData = (data) => {
  const verifyType = {
    verification: "Certified",
    none: "Un-certified",
  };
  let formatData = [];
  data.forEach((element) => {
    element.desc_time = element.upd_time;
    element.upd_time = momment(element.upd_time).format(FORMAT_DATETIME);
    element.add_time = momment(element.add_time).format(FORMAT_DATETIME);
    element.status =
      element.status === "posting"
        ? element.verified_status === "rejected"
          ? "rejected"
          : "verifying"
        : element.status;
    element.verify_type = verifyType[element.verify_type];
    formatData.push(element)
  });

  return formatData.sort((a, b) => b.desc_time - a.desc_time);
};
export { fetchData, formatData };
