import { call, put, takeLatest, cancelled, select } from "redux-saga/effects";
import * as ACTIONS from "../redux/actions/chatAction";
import { history, PATH_URL } from "../../../../shared/helpers/index";
import * as services from "../services";
import { signInFirebase } from "../../../../shared/firebase/fcm";
export const getData = (state) => state.chatReducer;

function* getFirebaseTokenSagas(action) {
  try {
    const { data } = yield call(services.fetchFirebaseTokenSvc);
    if (data) {
      signInFirebase(data);
    } else {
      yield put({
        type: ACTIONS.GET_FIREBASE_TOKEN_FAILED,
        payload: { error: "Token firebase undefined" },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getListChatRoom(action) {
  try {
    const stateData = yield select(getData);
    const { pageNumber, itemPerPage, filter } = stateData;
    const result = yield call(
      services.fetchListChatRoom,
      pageNumber,
      itemPerPage,
      filter
    );

    yield put({
      type: ACTIONS.CHAT_ROOM_FETCH_SUCCEEDED,
      payload: {
        roomsData: result.data.data,
        roomsTotal: result.data.total,
        isReload: action.isReload,
      },
    });
  } catch (error) {
    yield put({ type: ACTIONS.CHAT_ROOM_FETCH_FAILED, payload: { error } });
  } finally {
    // if (yield cancelled()) {
    //     yield call(history.push, PATH_URL.HOME);
    // }
  }
}

function* getListChatMessage(action) {
  try {
    // yield put({ type: ACTIONS.LOADING });
    const stateData = yield select(getData);
    const { messageSortField, messageSortDesc } = stateData;
    const roomId = action.roomId;
    const propertyId = action.propertyId;
    const ownerId = action.ownerId;
    const groupId = action.groupId;
    const roomType = action.roomType;
    const result = yield call(
      services.fetchListMessage,
      roomId,
      messageSortField,
      messageSortDesc
    );
    yield put({
      type: ACTIONS.CHAT_MESSAGE_FETCH_SUCCEEDED,
      payload: {
        messagesData: result.data.data,
        currentRoom: { id: roomId, propertyId, ownerId, groupId, roomType },
      },
    });
  } catch (error) {
    yield put({ type: ACTIONS.CHAT_MESSAGE_FETCH_FAILED, payload: { error } });
  }
  // finally {
  //     if (yield cancelled()) {
  //         yield call(history.push, PATH_URL.HOME);
  //     }
  // }
}

function* assignAgent(action) {
  try {
    const agentId = action.agentId;
    const stateData = yield select(getData);
    const { propertyId } = stateData.currentRoom;
    yield put({ type: ACTIONS.CLOSE_AGENT_MODAL });
    yield call(services.assignAgentToProperty, propertyId, agentId);
    yield put({
      type: ACTIONS.ASSIGN_AGENT_TO_PROPERTY_SUCCEEDED,
      payload: { message: "Asigned successfully" },
    });
  } catch (error) {
    yield put({
      type: ACTIONS.ASSIGN_AGENT_TO_PROPERTY_FAILED,
      payload: { error },
    });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* addChatMessage(action) {
  try {
    const roomId = action.roomId;
    const userId = action.userId;
    const message = action.message;
    const { data } = yield call(
      services.addChatMessageSv,
      roomId,
      userId,
      message
    );
    yield put({
      type: ACTIONS.CHAT_ROOM_APPEND_NEW_MESSAGE,
      payload: { message: data },
    });
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* addChatWithOwner(action) {
  try {
    const { ownerId, propertyId, groupId } = action;
    const result = yield call(services.addChatWithOwners, ownerId, propertyId);
    const roomData = result.data;
    const stateData = yield select(getData);
    let roomsState = stateData.roomsData;
    let dataIndex = -1;
    for (let index = 0; index < roomsState.length; index++) {
      const element = roomsState[index];
      if (element.id === groupId) {
        dataIndex = index;
        break;
      }
    }
    let groupsData = roomsState[dataIndex];
    let exist = false;
    groupsData.users.forEach((element) => {
      if (element.roomId === roomData._id) {
        exist = true;
      }
    });
    if (!exist) {
      const adminId = localStorage.getItem("admin_id");
      const convertItem = {
        name: `${roomData.user_first_name} ${roomData.user_last_name}`,
        isOnline: true,
        totalMessage: roomData.unread[adminId],
        roomId: roomData._id,
        propertyId: roomData.property_id,
        ownerId: roomData.property_owner_id,
        ownerFirstName: roomData.property_owner_first_name,
        ownerLastName: roomData.property_owner_last_name,
        buyerFirstName: roomData.user_first_name,
        buyerLastName: roomData.user_last_name,
        roomType: roomData.room_type,
        agentFistName: roomData.agent_first_name,
        agentLastName: roomData.agent_last_name,
      };
      groupsData.users.push(convertItem);
      roomsState[dataIndex] = groupsData;
    }

    //    let currentRoom = { id: roomData._id, propertyId, ownerId, groupId };
    yield put({ type: ACTIONS.CHAT_WITH_OWNER_SUCCESS, rooms: roomsState });
    yield put({
      type: ACTIONS.CHAT_MESSAGE_FETCH_REQUESTED,
      roomId: roomData._id,
      propertyId,
      ownerId,
      groupId,
      roomType: roomData.room_type,
    });
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* searchListChatRoom(action) {
  try {
    const searchParam = {
      pageNumber: 0,
      itemPerPage: 10,
      filter: action.searchQuery,
    };
    const { data } = yield call(services.chatRoomSearchSvc, searchParam);
    yield put({
      type: ACTIONS.CHAT_ROOM_SEARCH_SUCCESS,
      payload: {
        roomsData: data.data,
        roomsTotal: data.total || 0,
        isReload: true,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* chatWatcher() {
  yield takeLatest(ACTIONS.CHAT_ROOM_FETCH_REQUESTED, getListChatRoom);
  yield takeLatest(ACTIONS.CHAT_MESSAGE_FETCH_REQUESTED, getListChatMessage);
  yield takeLatest(ACTIONS.ASSIGN_AGENT_TO_PROPERTY_REQUESTED, assignAgent);
  yield takeLatest(ACTIONS.CHAT_MESSAGE_ADD_REQUESTED, addChatMessage);
  yield takeLatest(ACTIONS.CHAT_WITH_OWNER, addChatWithOwner);
  yield takeLatest(ACTIONS.GET_FIREBASE_TOKEN, getFirebaseTokenSagas);
  yield takeLatest(ACTIONS.CHAT_ROOM_SEARCH_REQUEST, searchListChatRoom);
}

export default chatWatcher;
