import { takeLatest, call, put, cancelled } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers';
import { fetchData, updateTransactionFee, getGeneralValue, getPackages, configPackage } from '../services';

import * as CONSTANTS from '../actions/constants';

function* getTransactionFee() {
  try {
    yield put({ type: CONSTANTS.LOADING });
    const result = yield call(fetchData);
    const data = { data: result.data };
    yield put({ type: CONSTANTS.FETCH_TRANSACTION_FEE_SUCCESS, data });
  } catch (error) {
    yield put({ type: CONSTANTS.FETCH_TRANSACTION_FEE_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* changesTransactionFee(action) {
  try {
    const { id, typeTransaction, value } = action;
    const result = yield call(updateTransactionFee, id, typeTransaction, value);
  } catch (error) {
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* getGeneralValueSaga() {
  try {
    const result = yield call(getGeneralValue);
    yield put({ type: CONSTANTS.GET_GENERAL_VALUE_SUCCESS, data: result.data})
  } catch (error) {
    yield put({ type: CONSTANTS.GET_GENERAL_VALUE_FAILURE, data: error})
  }
}

function* getPackagesSaga() {
  try {
    const result = yield call(getPackages);
    yield put({ type: CONSTANTS.GET_PACKAGES_SUCCESS, data: result.data});
  } catch (error) {
    yield put({ type: CONSTANTS.GET_PACKAGES_FAILURE, data: error});
  }
}

function* configPackageSaga(action) {
  try {
    const result = yield call(configPackage, action.payload);
    yield put({ type: CONSTANTS.CONFIG_PACKAGE_SUCCESS, data: result.data});
    yield put({ type: CONSTANTS.GET_PACKAGES })
  } catch (error) {
    yield put({ type: CONSTANTS.GET_PACKAGES_FAILURE, data: error});
  }
}


function* transactionFeeWatcher() {
  yield takeLatest(CONSTANTS.FETCH_TRANSACTION_FEE_REQUEST, getTransactionFee);
  yield takeLatest(CONSTANTS.CHANGE_TRANSACTION_FEE, changesTransactionFee);
  yield takeLatest(CONSTANTS.GET_GENERAL_VALUE, getGeneralValueSaga);
  yield takeLatest(CONSTANTS.GET_PACKAGES, getPackagesSaga);
  yield takeLatest(CONSTANTS.CONFIG_PACKAGE, configPackageSaga);

}

export default transactionFeeWatcher 
