import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class MatTableHead extends Component {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    header: PropTypes.array.isRequired
  };

  createSortHandler = col => () => {
    const { onRequestSort } = this.props;
    onRequestSort(col);
  };

  render() {
    const { order, header, column, showId, actionColumn, percents} = this.props;
 
    return (
      <TableHead>
        <TableRow>
          {showId && (
            <TableCell
              key={"stt"}
              align={'center'}
              sortDirection={false}
              width={"50px"}
            >  
            ID
            </TableCell>
          )}
          
          {header.map((col, index) => {
            const check = order.col === column[index];
            let percent;
            if(percents !== undefined) {
              percent = percents[index];
            }
            return (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={index}
                align={'center'}
                width={percent} 
              >
                <TableSortLabel
                  active={check}
                  direction={order.value?order.value:"desc"}
                  onClick={this.createSortHandler(column[index])}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {col}
                </TableSortLabel>
              </TableCell>
            );

          })}

          {actionColumn && (
            <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-right"
            align={'center'}
            sortDirection={false}
            
          >
            <TableSortLabel
              className="material-table__sort-label"
              dir="ltr"
            >
              Action
          </TableSortLabel>
          </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default MatTableHead;
