export const LOADING = "LOADING_CHATS";
export const CHAT_ROOM_FETCH_REQUESTED = "CHAT_ROOM_FETCH_REQUESTED";
export const CHAT_ROOM_FETCH_SUCCEEDED = "CHAT_ROOM_FETCH_SUCCEEDED";
export const CHAT_ROOM_FETCH_FAILED = "CHAT_ROOM_FETCH_FAILED";
export const REMOVE_CHAT_MESSAGE_NUMBER = "REMOVE_CHAT_MESSAGE_NUMBER";
export const CHAT_MESSAGE_FETCH_REQUESTED = "CHAT_MESSAGE_FETCH_REQUESTED";
export const CHAT_MESSAGE_FETCH_SUCCEEDED = "CHAT_MESSAGE_FETCH_SUCCEEDED";
export const CHAT_MESSAGE_FETCH_FAILED = "CHAT_MESSAGE_FETCH_FAILED";
export const CHAT_MESSAGE_ADD_REQUESTED = "CHAT_MESSAGE_ADD_REQUESTED";
export const CHAT_MESSAGE_ADD_SUCCEEDED = "CHAT_MESSAGE_ADD_SUCCEEDED";
export const CHAT_MESSAGE_ADD_FAILED = "CHAT_MESSAGE_ADD_FAILED";
export const CHAT_ROOM_APPEND_NEW_MESSAGE = "CHAT_MESSAGE_APPEND_NEW_MESSAGE";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const SHOW_AGENT_MODAL = "SHOW_AGENT_MODAL";
export const CLOSE_AGENT_MODAL = "CLOSE_AGENT_MODAL";
export const ASSIGN_AGENT_TO_PROPERTY_REQUESTED =
  "ASSIGN_AGENT_TO_PROPERTY_REQUESTED";
export const ASSIGN_AGENT_TO_PROPERTY_SUCCEEDED =
  "ASSIGN_AGENT_TO_PROPERTY_SUCCEEDED";
export const ASSIGN_AGENT_TO_PROPERTY_FAILED =
  "ASSIGN_AGENT_TO_PROPERTY_FAILED";
export const CHAT_WITH_OWNER = "CHAT_WITH_OWNER";
export const CHAT_WITH_OWNER_SUCCESS = "CHAT_WITH_OWNER_SUCCESS";
export const GET_FIREBASE_TOKEN = "GET_FIREBASE_TOKEN";
export const GET_FIREBASE_TOKEN_FAILED = "GET_FIREBASE_TOKEN_FAILED";
export const CHAT_ROOM_SEARCH_REQUEST = "CHAT_ROOM_SEARCH_REQUEST";
export const CHAT_ROOM_SEARCH_SUCCESS = "CHAT_ROOM_SEARCH_SUCCESS";
export const RESET_FILTER = "RESET_FILTER";

const resetFilterState = () => {
  return {
    type: RESET_FILTER,
  };
};

const chatRoomSearch = (searchQuery) => {
  return {
    type: CHAT_ROOM_SEARCH_REQUEST,
    searchQuery,
  };
};

const getFirebaseToken = () => {
  return {
    type: GET_FIREBASE_TOKEN,
  };
};

const chatWithOnwer = (ownerId, propertyId, groupId) => {
  return {
    type: CHAT_WITH_OWNER,
    ownerId,
    propertyId,
    groupId,
  };
};
const getListChatRoom = (isInit, isReload) => {
  return {
    type: CHAT_ROOM_FETCH_REQUESTED,
    isInit,
    isReload,
  };
};

const selectChatRoom = (roomId, propertyId, ownerId, groupId, roomType) => {
  return {
    type: REMOVE_CHAT_MESSAGE_NUMBER,
    roomId,
    propertyId,
    ownerId,
    groupId,
    roomType,
  };
};

const getListChatMessage = (roomId, propertyId, ownerId, groupId, roomType) => {
  return {
    type: CHAT_MESSAGE_FETCH_REQUESTED,
    roomId,
    propertyId,
    ownerId,
    groupId,
    roomType,
  };
};

const addChatMessage = (roomId, userId, message) => {
  return {
    type: CHAT_MESSAGE_ADD_REQUESTED,
    roomId,
    userId,
    message,
  };
};

const toggleNotification = () => {
  return {
    type: TOGGLE_NOTIFICATION,
  };
};

const showAgentModal = () => {
  return {
    type: SHOW_AGENT_MODAL,
  };
};

const closeAgentModal = () => {
  return {
    type: CLOSE_AGENT_MODAL,
  };
};

const assignAgentToProperty = (agentId) => {
  return {
    type: ASSIGN_AGENT_TO_PROPERTY_REQUESTED,
    agentId,
  };
};

const appendNewMessageToCurrentRoom = (message) => {
  return {
    type: CHAT_ROOM_APPEND_NEW_MESSAGE,
    message,
  };
};

const chatWithOwnerSuccess = (rooms) => {
  return {
    type: CHAT_WITH_OWNER_SUCCESS,
    rooms,
  };
};

export {
  resetFilterState,
  chatRoomSearch,
  getFirebaseToken,
  selectChatRoom,
  getListChatRoom,
  getListChatMessage,
  toggleNotification,
  showAgentModal,
  closeAgentModal,
  assignAgentToProperty,
  addChatMessage,
  appendNewMessageToCurrentRoom,
  chatWithOnwer,
  chatWithOwnerSuccess,
};
