export const validate = (inputs) => {
    const errors = {};
    
  if (!inputs.name) {
    errors.name = 'Enter your display name';
  }
  if (!inputs.table) {
    errors.table = 'Enter your database table';
  }
  if(!inputs.field) {
      errors.field = 'Enter your data field';
  }
  return errors;
};


export const requiredInput = (input) =>
  input ? undefined : `Input is required`;

// export const correctInput = input =>
//   input !== 'rajat' ? 'Incorrect Username' : undefined;
