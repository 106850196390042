import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../shared/components/Modal';
import { URBANASSET_IMAGE_BASE_API } from '../../../../shared/helpers/api';
import defaultAvatar  from '../../../../shared/img/avatar.png';
import { validURL } from '../../../../shared/helpers/utils';
import { FORMAT_DATE }from '../../../../shared/helpers/constants';
import moment from 'moment';

class AmbassadorProfile extends Component {
  render() {
    const {
      t,
      profile,
      onActive,
      onDeActive
    } = this.props;

    const avatar = profile.avatar ? (validURL(profile.avatar) ? profile.avatar : URBANASSET_IMAGE_BASE_API + "/large/" + profile.avatar) : profile.avatar

    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="3">
              <div >
                <img alt="ambassador" className="property__ambassador-img" id="image" src={avatar ? avatar : defaultAvatar} />
              </div>
            </Col>
            <Col sm="4">
              <div >
                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.full_name')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.fullName}</strong></label>
                  </Col>
                </Row>

                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.email')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.email}</strong></label>
                  </Col>
                </Row>

                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.address_one')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.address1}</strong></label>
                  </Col>
                </Row>

                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.address_two')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.address2}</strong></label>
                  </Col>
                </Row>

                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.city')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.city}</strong></label>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col sm="5">
              <div >
                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.phone')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.phone}</strong></label>
                  </Col>
                </Row>

                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.active_date')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{moment(profile.activeDate).format(FORMAT_DATE)}</strong></label>
                  </Col>
                </Row>
                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.state')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.state}</strong></label>
                  </Col>
                </Row>
                <Row className="ambassador__infor">
                  <Col sm="4">
                    <label>{t('ambassador_detail.zipcode')}</label>
                  </Col>
                  <Col sm="8">
                    <label><strong>{profile.zip_code}</strong></label>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {profile.status === "active" ? <Modal
            color="danger"
            btn="De-active"
            title="Are you sure de-active this ambassador?"
            optionClass="property__verification-btn"
            confirmAction={onActive}
          /> : profile.status !== "" ?
            <Modal
              color="success"
              btn="Active"
              title="Are you sure active this ambassador?"
              optionClass="property__verification-btn"
              confirmAction={onDeActive}
            /> :""
          }
        </CardBody>
      </Card>
    )
  }
}

AmbassadorProfile.propTypes = {
  t: PropTypes.func.isRequired

}

export default withTranslation('common')(AmbassadorProfile);
