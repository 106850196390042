import React, { useState, useEffect } from "react";
import Scrollbar from "react-smooth-scrollbar";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getListChatRoom } from "../redux/actions/chatAction";
import { ListChatRoomProps } from "../../../../shared/prop-types/ListChatRoomProps";
import ChatRoomItem from "./ChatRoomItem";
import CircularProgress from "@material-ui/core/CircularProgress";

const SCROLL_THRESHOLD = 0.8;

const ChatRoomList = ({
  listChatRoom,
  getListChatRoom,
  chatRoomsLoading,
  roomsData,
}) => {
  const [currentScrollPos, setCurrentScrollPos] = useState(0);

  const handleScroll = (e) => {
    const percent = e.offset.y / e.limit.y;
    if (
      percent > SCROLL_THRESHOLD &&
      !chatRoomsLoading &&
      percent > currentScrollPos
    ) {
      getListChatRoom();
    }
    setCurrentScrollPos(percent);
  };

  return (
    <div className="property-list-container">
      {(listChatRoom && listChatRoom.length > 0) || chatRoomsLoading ? (
        <Scrollbar
          className="scroll chat__scroll"
          onScroll={handleScroll}
          alwaysShowTracks
        >
          <div className="property-list-wrap">
            {listChatRoom.map((item) => (
              <ChatRoomItem key={item.id} data={item}/>
            ))}
            {chatRoomsLoading && (
              <div className="property-loading-wrap">
                <CircularProgress disableShrink />
              </div>
            )}
          </div>
        </Scrollbar>
      ) : (
        <div className="message-are">Property is empty</div>
      )}
    </div>
  );
};

ChatRoomList.prototype = {
  listChatRoom: ListChatRoomProps,
};

const mapStateToProps = (state) => {
  return {
    ...state.chatReducer,
  };
};

export default connect(
  mapStateToProps,
  { getListChatRoom }
)(withTranslation("common")(ChatRoomList));
