export const FETCH_ACCOUNTS_REQUEST = 'FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_PROPERTY_ERROR';
export const CHANGE_PAGE = 'CHANGE_PAGE_USER';
export const CHANGE_ITEM_PER_PAGE = 'CHANGE_ITEM_PER_PAGE_USER';
export const ACTIVE_USER = 'ACTIVE_USER';
export const DEACTIVE_USER = 'DEACTIVE_USER';
export const ACTIVE_RESULT = 'ACTIVE_RESULT';
export const ON_SHORT_ACCOUNT = 'ON_SHORT_ACCOUNT';
export const CHANGE_FILTER_VALUE = 'CHANGE_AMBASSADOR_FILTER';
export const CHANGE_DEFAULT_PAGE = 'CHANGE_DEFAULT_PAGE';
export const RESET_STATE = 'RESET_STATE';

const fetchAccounts = function fetchAccounts() {
    return {
        type: FETCH_ACCOUNTS_REQUEST
    }
}

const fetchAccountsSuccess = function fetchAccountsSuccess(data) {
    return {
        type: FETCH_ACCOUNTS_SUCCESS,
        data
    }
}

const fetchPropertyError = function fetchPropertyError() {
    return {
        type: FETCH_ACCOUNTS_ERROR,
    }
}

const changePage = function changePage(pageNumber) {
    return {
        type: CHANGE_PAGE,
        pageNumber: pageNumber
    }
}

const changeItemPerPage = function changeItemPerPage(itemPerPage) {
    return {
        type: CHANGE_ITEM_PER_PAGE,
        itemPerPage: itemPerPage
    }
}

const activeUser = function activeUser(id) {
    return {
        type: ACTIVE_USER,
        id: id
    }
}

const deactiveUser = function deactiveUser(id) {
    return {
        type: DEACTIVE_USER,
        id: id
    }
}

const activeResult = function activeResult(data) {
    return {
        type: ACTIVE_RESULT,
        data
    }
}

const onShortAccount = (value) => {
    return {
        type: ON_SHORT_ACCOUNT,
        value
    }
}

const changeFilterValue = function changeFilterValue(filtersData) {
    return {
        type: CHANGE_FILTER_VALUE,
        filtersData
    }
}

const changeDefaultPage = () => {
    return {
        type: CHANGE_DEFAULT_PAGE,
        pageNumber: 0
    }
}

const resetState = function resetState() {
    return {
        type: RESET_STATE,
    }
}

export {
    fetchAccounts,
    fetchAccountsSuccess,
    fetchPropertyError,
    changeItemPerPage,
    changePage,
    activeUser,
    deactiveUser,
    activeResult,
    onShortAccount,
    changeFilterValue,
    changeDefaultPage,
    resetState
}

