
export const FETCH_SELLER_DETAIL_REQUEST = 'FETCH_SELLER_DETAIL_REQUEST';
export const FETCH_SELLER_DETAIL_SUCCESS = 'FETCH_SELLER_DETAIL_SUCCESS';
export const FETCH_SELLER_DETAIL_ERROR = 'FETCH_SELLER_DETAIL_ERROR';

export const FETCH_PROPERTY_BY_SELLER_REQUEST = 'FETCH_PROPERTY_BY_SELLER_REQUEST';
export const FETCH_PROPERTY_BY_SELLER_SUCCESS = 'FETCH_PROPERTY_BY_SELLER_SUCCESS';
export const FETCH_PROPERTY_BY_SELLER_ERROR = 'FETCH_PROPERTY_BY_SELLER_ERROR';

export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
export const CHANGE_ITEM_PER_PAGE = 'CHANGE_ITEM_PER_PAGE';

const fetchSellerDeatail = function fetchSellerDeatail(id) {
    return {
        type: FETCH_SELLER_DETAIL_REQUEST,
        id
    }
}

const fetchSellerDetailSuccess = function fetchSellerDetailSuccess(seller) {
    return {
        type: FETCH_SELLER_DETAIL_SUCCESS,
        seller
    }
}

const fetchSellerDetailError = function fetchSellerDetailError() {
    return {
        type: FETCH_SELLER_DETAIL_ERROR,
    }
}

const fetchPropertyBySeller = function fetchPropertyBySeller() {
    return {
        type: FETCH_PROPERTY_BY_SELLER_REQUEST,
    }
}

const fetchPropertyBySellerSuccess = function fetchPropertyBySellerSuccess(property) {
    return {
        type: FETCH_PROPERTY_BY_SELLER_SUCCESS,
        property
    }
}

const fetchPropertyBySellerError = function fetchPropertyBySellerError() {
    return {
        type: FETCH_PROPERTY_BY_SELLER_ERROR,
    }
}

const changeFilterValue = function changeFilterValue(filtersData) {
    return {
        type: CHANGE_FILTER_VALUE,
        filtersData 
    }
}

const changePage = function changePage(pageNumber) {
    return {
        type: CHANGE_PAGE,
        pageNumber: pageNumber
    }
}

const changeItemPerPage = function changeItemPerPage(itemPerPage) {
    return {
        type: CHANGE_ITEM_PER_PAGE,
        itemPerPage: itemPerPage
    }
}

export {
    fetchSellerDeatail,
    fetchSellerDetailSuccess,
    fetchSellerDetailError,

    fetchPropertyBySeller,
    fetchPropertyBySellerSuccess,
    fetchPropertyBySellerError,

    changeFilterValue,
    changeItemPerPage,
    changePage
}