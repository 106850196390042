import { checkAuthorization } from './checkAuthorization';

export function checkItemsAuthorization ({ dispatch, getState }) {
    const client = getState().clientReducer
    if (client && client.token) {
        return true;
    }
    if (checkAuthorization(dispatch)) {
        return true;
    }
    return false;
};
