import axios from "axios";
import { HTTP_METHOD, BASE_URL } from "./api";
import {PATH_URL} from "./path";

/*

Use sample:
apiCaller(API_URL.PROFILE, HTML_METHOD.GET, {
      itemPerPage: perPage,
      pageNumber: pageNumber + 1,
      name: "abc"
    })
      .then(result => this.setDataSuccess(result))
      .catch(error => this.setError(error));

*/

export default async function apiCaller(
  endPoint,
  method = HTTP_METHOD.GET,
  data = null,
  params = null,
  config = {
    "Content-Type": "application/json"
  }
) {

  const userToken = localStorage.getItem("token");

  if (userToken) {
    config.Authorization = "Bearer " + userToken;
  }

  return await axios({
    method: method,
    url: BASE_URL + "/" + endPoint,
    crossDomain: true,
    data: data,
    timeout: 100000,
    headers: config,
    params: params
  }).catch(function(error) {

    // Server Error
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 401:
          localStorage.removeItem('token');
          window.location.href = PATH_URL.BASE;
          break;
        default:
          throw(error);
      }
    }
  });

}
