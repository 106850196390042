export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

const loginRequest = function loginRequest ({ username, password, remember_me }) {

    return {
        type: LOGIN_REQUESTING,
        username,
        password,
        remember_me,
    }
}
// Since it's the only one here
export default loginRequest

