import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Row } from 'reactstrap';
import { PATH_URL, history } from '../../../shared/helpers/index';
import Loading from '../../../shared/components/Loading';
import { changeFilterValue, onShortAmbassador, handleChangeTable, handleListAmbassador, changeItemPage, changePage, changeDeafultPage, resetState } from './redux/actions/ambassadorAction';
import MatTable from '../../../shared/components/table/components/MatTable';
import Filter from './components/Filter';

class Ambassadors extends PureComponent {
  constructor() {
    super();
    this.onPageChange = this.onPageChange.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.onClickRow = this.onClickRow.bind(this);
    this.onShort = this.onShort.bind(this);
  }

  componentDidMount() {
    this.props.resetState();
    const params = { pageNumber: 0, itemPerPage: 10 };
    const { isAsign } = this.props;
    if (isAsign) {
      this.props.changeFilterValue({ status: { value: 'active', label: 'Active' } });
    }

    this.props.handleListAmbassador(params);
  }

  onClickRow(rowData) {
    const { isAsign } = this.props;
    if (isAsign) {
      this.props.selectAgent(rowData);
      return;
    }
    if (rowData && rowData._id) {
      history.push(PATH_URL.AMBASSADORS + "/" + rowData._id);
    }
  }

  onChangeRowsPerPage(event) {
    this.props.changeItemPage(event.target.value)
  }

  onPageChange(page) {
    this.props.changePage(page)
  }

  onShort(value) {
    this.props.onShortAmbassador(value);
  }

  buildDataForList(itmes) {
    let buildData = [];
    itmes.forEach(element => {
      element.assigned_properties_count = element.assigned_properties.length;
      buildData.push({ element });
    });
    return buildData;
  }

  buildHeaderForList() {
    const { isAsign } = this.props;
    if (isAsign) {
      return ["First Name", "Last Name", "Email", "Following deals", "Language"]
    } else {
      return ["First Name", "Last Name", "Email", "Status", "Following deals"]
    }
    // const idHeader = t('ambassadors.table_id');
    // const fullNameHeader =  t('ambassadors.table_fullname');
    // const statusHeader = t('ambassadors.table_status');
    // const followingHeader = t('ambassadors.table_following');

  }

  buildColumnForList() {
    const { isAsign } = this.props;
    if (isAsign) {
      return ["first_name", "last_name", "email", "assigned_properties_count", "language_code"]
    } else {
      return ["first_name", "last_name", "email", "status", "assigned_properties_count"]
    }
    // const idHeader = t('ambassadors.table_id');
    // const fullNameHeader =  t('ambassadors.table_fullname');
    // const statusHeader = t('ambassadors.table_status');
    // const followingHeader = t('ambassadors.table_following');

  }

  handleFilterListAmbassador = () => {
    this.props.changeDeafultPage();
    this.props.handleListAmbassador();
  }

  render() {
    const { items, itemPerPage, loading, count, pageNumber, isAsign } = this.props;

    return (
      <Container className="ambassador">
        <Loading isOpen={loading} />
        <Filter
          changeFilterValue={this.props.changeFilterValue}
          onFilter={this.handleFilterListAmbassador}
          data={this.props}
          isAsign={isAsign}
        />

        <Row>
          {items.length > 0 ?
            <MatTable
              data={items}
              count={count}
              currentPage={pageNumber}
              onPageChange={this.onPageChange}
              rowsPerPage={itemPerPage}
              column={this.buildColumnForList()}
              header={this.buildHeaderForList()}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              onClickRow={this.onClickRow}
              showId={true}
              onShort={this.onShort}
            /> : <span>No Data</span>
          }
        </Row>
        <Row>
          {isAsign !== true && <Link className="btn btn-primary btn-sm" to={PATH_URL.AMBASSADORS_CREATE}>+ Add new Ambassador</Link>}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.ambassadorReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleListAmbassador: (params) => { dispatch(handleListAmbassador(params)) },
    handleChangeTable: (data) => { dispatch(handleChangeTable(data)) },
    changeItemPage: (number) => { dispatch(changeItemPage(number)) },
    changePage: (page) => { dispatch(changePage(page)) },
    onShortAmbassador: (value) => { dispatch(onShortAmbassador(value)) },
    changeFilterValue: (data) => { dispatch(changeFilterValue(data)) },
    changeDeafultPage: () => { dispatch(changeDeafultPage()) },
    resetState: () => { dispatch(resetState()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Ambassadors));
