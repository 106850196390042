import numeral from 'numeral';

export function currencyFormat(no) {
    return numeral(no).format('0,0[.]00');
}


export function currencySymbol(src, ex) {
    return (src.find(i => i.code === ex) || {}).symbol;
}

export function validNumber(str) {
    return /^\d+$/.test(str);
}

export const validURL = (url) =>  Boolean(['http://', 'https://', 'ftp://'].some(protocol => url ? url.startsWith(protocol) : null))

// export function validURL(str) {
    // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    // return !!pattern.test(str);
  // }

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertPackageArrToPackageObj(packageArr) {
    let returnObj = {};
    if (packageArr && packageArr.length) {
        returnObj = Object.assign(...packageArr.map((item) => ({ [item.key]: item})))
    }
    return returnObj;
}

export function isDecimalWithTwoDigistAfter(string) {
    if (string === "") return true;
    if (string) {
        return /^[0-9]+(\.)?([0-9]{1,2})?$/.test(string);
    }
}

