import { takeLatest, call, put, cancelled } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers/index';

import * as CONSTANTS from '../actions/constants';

const itemsMock = [ 
    {id: 0, name: "Buyer FullName", table: 'Table 1', field: 'Field 1', status: "Actived"},
    {id: 1, name: "Buyer FullName 1", table: 'Table 2', field: 'Field 2',  status: "Actived"},
    {id: 2, name: "Buyer FullName 2", table: 'Table 3', field: 'Field 3',  status: "Actived"},
    {id: 3, name: "Buyer FullName 3", table: 'Table 4', field: 'Field 4',  status: "Actived"},
    {id: 4, name: "Tabasu", table: 'Table 5', field: 'Field 5',  status: "Actived"},
    {id: 5, name: "Cupcake", table: 'Table 6', field: 'Field 1',  status: "Actived"},
    {id: 6, name: "Tabasu Cupcake", table: 'Table 7', field: 'Field 7',  status: "Actived"},
    {id: 7, name: "Tabasu Minasimo", table: 'Table 8', field: 'Field 8',  status: "Actived"},
    {id: 8, name: "Minasimo", table: 'Table 9', field: 'Field 9',  status: "Actived"},
    {id: 9, name: "Tabasu", table: 'Table 10', field: 'Field 10',  status: "Actived"}
];

function* getListParameter () {
  try {
    const itemsStorage = localStorage.getItem('items');
    let items = itemsMock;
    if(!itemsStorage) {
      localStorage.setItem('items', JSON.stringify(itemsMock));
    } else {
      items = JSON.parse(itemsStorage);
    }
    yield put({ type: CONSTANTS.LOADING });
    yield put({ type: CONSTANTS.PARAMETER_FETCH_SUCCEEDED, payload: items });
    localStorage.setItem('items', JSON.stringify(items));
  } catch (error) {
    yield put({ type: CONSTANTS.PARAMETER_FETCH_FAILED, error })
  } finally {
    if(yield cancelled()) {
        yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* parameterWatcher () {
    yield takeLatest(CONSTANTS.PARAMETER_FETCH_REQUESTED, getListParameter);
}

export default parameterWatcher 
