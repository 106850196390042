export const LOADING = 'LOADING';
export const EMAIL_TEMPLATE_DETAIL_FETCH_REQUESTED = 'EMAIL_TEMPLATE_DETAIL_FETCH_REQUESTED';
export const EMAIL_TEMPLATE_DETAIL_FETCH_SUCCEEDED = 'EMAIL_TEMPLATE_DETAIL_FETCH_SUCCEEDED';
export const EMAIL_TEMPLATE_DETAIL_FETCH_FAILED = 'EMAIL_TEMPLATE_DETAIL_FETCH_FAILED';
export const EMAIL_TEMPLATE_DETAIL_UPDATE_REQUESTED = 'EMAIL_TEMPLATE_DETAIL_UPDATE_REQUESTED';
export const EMAIL_TEMPLATE_DETAIL_UPDATE_SUCCEEDED = 'EMAIL_TEMPLATE_DETAIL_UPDATE_SUCCEEDED';
export const EMAIL_TEMPLATE_DETAIL_UPDATE_FAILED = 'EMAIL_TEMPLATE_DETAIL_UPDATE_FAILED';

const getEmailTemplateDetail = (id) => {
    return {
        type: EMAIL_TEMPLATE_DETAIL_FETCH_REQUESTED,
        payload: {
            id,
        }
    }
}

const updateEmailTemplateDetail = (id, data) => {
    return {
        type: EMAIL_TEMPLATE_DETAIL_UPDATE_REQUESTED,
        payload: {
            id, data,
        }
    }
}

export {
    getEmailTemplateDetail,
    updateEmailTemplateDetail,
}