
import * as CONSTANTS from '../actions/constants';

const initialState = {
  listCurencyParams: [],
  item: null,
  loading: false,
  error: false,
  errorInfo: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.CREATE_LOADING:
    case CONSTANTS.CREATE_CURRENCY_REQUEST:
      return { ...state, loading: true };
    case CONSTANTS.CREATE_CURRENCY_SUCCESS:
      return { item: action.payload, loading: false, error: false };
    case CONSTANTS.CREATE_CURRENCY_FAILURE:
      return { ...state, loading: false, error: true, errorInfo: action.error.response.data };
    case CONSTANTS.FETCH_CURRENCY_PARAMS_REQUEST:
      return { ...state, loading: true };
    case CONSTANTS.FETCH_CURRENCY_PARAMS_SUCCESS:
      return { item: action.payload, loading: false, error: false, listCurencyParams: action.listCurencyParams };
    case CONSTANTS.FETCH_CURRENCY_PARAMS_FAILURE:
      return { ...state, loading: false, error: true };
    case CONSTANTS.TOGGLE_NOTIFICATION_CURRENCY_CREATE:
      return { ...state, error: false };
    default:
      return state;
  }
}