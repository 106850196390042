import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import renderField from '../../../../shared/components/form/Field';
import { validate, requiredInput } from './validate';

class ParameterEditForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, reset, parametersDetail, handleBack } = this.props;

    return (
      <Container className="parameterContainer">
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {
                  (parametersDetail && parametersDetail.loading) && (
                    <div className={`load${parametersDetail.loading ? '' : ' loaded'}`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  )
                }
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Display Name *</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        component={renderField}
                        type="text"
                        placeholder="Display Name"
                        validate={[requiredInput]}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Database Table *</span>
                    <div className="form__form-group-field">
                      <Field
                        name="table"
                        component={renderSelectField}
                        options={[
                          { value: 'lessor', label: 'Lessor' },
                          { value: 'seller', label: 'Seller' },
                          { value: 'buyer', label: 'Buyer' },
                        ]}
                        validate={[requiredInput]}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Data Field *</span>
                    <div className="form__form-group-field">
                      <Field
                        name="field"
                        component={renderSelectField}
                        options={[
                          { value: 'fullname', label: 'Full Name' },
                          { value: 'address', label: 'Address' },
                        ]}
                        validate={[requiredInput]}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="danger" type="submit">Submit</Button>
                    <Button type="button" onClick={handleBack}>
                      Cancel
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    )
  }
}

export default reduxForm({
  form: 'parameterForm', // a unique identifier for this form
  validate,
  fields: ['name', 'table', 'field'],
  enableReinitialize: true,
})(withTranslation('common')(ParameterEditForm));