import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../../shared/components/form/Select";
import { Col, Button, Row } from "reactstrap";
import { USER_STATUS_FILTER } from "../../../../shared/helpers/constants";

class Filter extends PureComponent {
  onChangeStatus = e => {
    this.props.changeFilterValue({ status: e });
  };

  onChangeName = e => {
    let name = e.target.value;
    name = name ? name.trim() : name;
    this.props.changeFilterValue({ name });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onFilter();
  };

  render() {
    const { status, name, isAsign } = this.props.data;
    return (
      <Row>
        <form
          style={{
            width: "100%",
            background: "white",
            padding: "28px 0 0 26px"
          }}
          className="form form--horizontal"
          onSubmit={this.handleSubmit}
        >
          <Col md={4} lg={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="property_name"
                  component="input"
                  type="text"
                  onChange={this.onChangeName}
                  className="properties__filer-border properties__status-select"
                  value={name}
                />
              </div>
            </div>
          </Col>
          {isAsign === undefined && (
            <Col md={4} lg={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    options={USER_STATUS_FILTER}
                    className="properties__status-select"
                    onChange={this.onChangeStatus}
                    value={status}
                    defaultValue={status}
                  />
                </div>
              </div>
            </Col>
          )}

          <Col md={3} lg={2}>
            <Button color="primary" action="submit" size="sm">
              Filter
            </Button>
          </Col>
        </form>
      </Row>
    );
  }
}

export default reduxForm({
  form: "horizontal_form" // a unique identifier for this form
})(withTranslation("common")(Filter));
