import { take, fork, cancel, call, put, takeLatest, select } from 'redux-saga/effects';
import { PATH_URL } from "../../../../../shared/helpers";
import {
  FETCH_PROPERTY_DETAIL_REQUEST,
  FETCH_PROPERTY_DETAIL_SUCCESS,
  FETCH_PROPERTY_DETAIL_ERROR
} from '../actions/fetchDataAction'

import {
  REJECT_PROPERTY,
  REJECT_PROPERTY_ERROR,
  REJECT_PROPERTY_SUCCESS,
  CHANGE_STATUS_PROPERTY,
  CHANGE_STATUS_PROPERTY_SUCCESS,
  CHANGE_STATUS_PROPERTY_ERROR,
  POST_ON_FACEBOOK,
  POST_ON_FACEBOOK_SUCCESS,
  POST_ON_FACEBOOK_ERROR,
  POST_ON_INSTAGRAM,
  POST_ON_INSTAGRAM_SUCCESS,
  POST_ON_INSTAGRAM_ERROR,
  VERIFY_PROPERTY,
  VERIFY_PROPERTY_SUCCESS,
  VERIFY_PROPERTY_ERROR,
  REJECT_RENEWAL_PAYMENT,
  REJECT_RENEWAL_PAYMENT_SUCCESS,
  REJECT_RENEWAL_PAYMENT_ERROR,
  APPROVE_RENEWAL_PAYMENT,
  APPROVE_RENEWAL_PAYMENT_SUCCESS,
  APPROVE_RENEWAL_PAYMENT_ERROR,
  CONFIRM_PROPERTY,
  CONFIRM_PROPERTY_SUCCESS,
  PUBLISH_PROPERTY_DETAIL_REQUEST,
  PUBLISH_PROPERTY_DETAIL_WITHOUT_PAY_REQUEST
} from '../actions/verifyPropertyAction';

import {
  FETCH_AGENTS_REQUEST,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_ERROR,
  ASIGN_AGENT,
  ASIGN_AGENT_ERROR,
  ASIGN_AGENT_SUCCESS
} from '../actions/asignAgentAction';

import {
  fetchData,
  formatData,
  paymentFormatData,
  rejectProperty,
  verifyAssignProperty,
  changeStatusProperty,
  approveRenewalProperty,
  rejectRenewalProperty,
  verifyProperty,
  getAgents,
  asignAgent,
  postOnInstagramService,
  postOnFaceBookService,
  getListCurrencies,
  confirmProperty,
  publishProperty,
  publishPropertyWithoutPay
} from '../services/index';

export const stateData = (state) => state.propertyDetailReducer;

function* getPropertyDetail() {
  try {
    const data = yield select(stateData);
    const { id } = data;
    const result = yield call(fetchData, id);
    const currencies = yield call(getListCurrencies);

    if (result.data.product) {
      yield call(paymentFormatData, result.data.product);
    }

    let property = result.data ? {...result.data, currencies: currencies} : result.data;
    yield put({ type: FETCH_PROPERTY_DETAIL_SUCCESS, property: property });
  } catch (error) {
    yield put({ type: FETCH_PROPERTY_DETAIL_ERROR, error: error.response.data })
  }
}

function* rejectPropertySaga() {
  try {
    const data = yield select(stateData);
    const { id, message, isPaid } = data;
    const result = yield call(rejectProperty, id, message, isPaid);
    yield put({ type: REJECT_PROPERTY_SUCCESS, message: result.data.message });
  } catch (error) {
    yield put({ type: REJECT_PROPERTY_ERROR, error: error.response.data })
  }
}

function* changeStatusPropertySaga() {
  try {
    const data = yield select(stateData);
    const { id } = data;
    const result = yield call(changeStatusProperty, id);
    yield put({ type: CHANGE_STATUS_PROPERTY_SUCCESS, message: 'Change status property to draft success!' });
  } catch (error) {
    yield put({ type: CHANGE_STATUS_PROPERTY_ERROR, error: error.response.data.errors[0] })
  }
}

function* postOnInstagramSaga() {
  try {
    const data = yield select(stateData);
    const { id, message } = data;

    const params = {
      id,
      message
    }

    yield call(postOnInstagramService, params);
    yield put({ type: POST_ON_INSTAGRAM_SUCCESS, message: 'Post property link on Instagram successfully!' });
  } catch (error) {
    yield put({ type: POST_ON_INSTAGRAM_ERROR, error: error.response.data.errors[0] })
  }
}

function* postOnFaceBookSaga() {
  try {
    const data = yield select(stateData);
    const { id, property, message } = data;
    const propertyLink = PATH_URL.UA_BASE + "property/" + property.slug
    const params = {
      id,
      message,
      propertyLink
    }

    yield call(postOnFaceBookService, params);
    yield put({ type: POST_ON_FACEBOOK_SUCCESS, message: 'Post property link on Facebook Page successfully' });
  } catch (error) {
    yield put({ type: POST_ON_FACEBOOK_ERROR, error: error.response.data.errors[0] })
  }
}

function* verifyPropertySaga() {
  try {

    yield put({ type: FETCH_AGENTS_REQUEST });
    const result = yield call(getAgents);
    yield put({ type: FETCH_AGENTS_SUCCESS, agents: formatData(result.data.data) });
  } catch (error) {
    yield put({ type: VERIFY_PROPERTY_ERROR, error: error.response.data })
  }
}

function* approveRenewalPaymentSaga() {
  try {
    const data = yield select(stateData);
    const { id } = data;
    yield call(approveRenewalProperty, id);

    yield put({ type: APPROVE_RENEWAL_PAYMENT_SUCCESS, message: "Approve renewal successfully" });
  } catch (error) {
    yield put({ type: APPROVE_RENEWAL_PAYMENT_ERROR, error: error.response.data })
    console.log(error)
  }
}

function* rejectRenewalPaymentSaga() {
  try {
    const data = yield select(stateData);
    const { id, message } = data;
    yield call(rejectRenewalProperty, id, message);

    yield put({ type: REJECT_RENEWAL_PAYMENT_SUCCESS, message: "Reject renewal successfully" });
  } catch (error) {
    yield put({ type: REJECT_RENEWAL_PAYMENT_ERROR, error: error.response.data })
    console.log(error)
  }
}

function* asignAgentSaga(action) {

  try {
    const data = yield select(stateData);
    const { id } = data;
    // yield call(verifyProperty, id);
    // yield call(asignAgent, id, action.agentId);
    yield call(verifyAssignProperty, id, action.agentId);
    yield put({ type: ASIGN_AGENT_SUCCESS, message: "Asigned successfully" });
  } catch (error) {
    yield put({ type: ASIGN_AGENT_ERROR, error: error.response.data })
  }

}

function* confirmPropertySaga() {
  try {
    const data = yield select(stateData);
    const { id, message } = data;
    const result = yield call(confirmProperty, id, message);
    yield put({ type: CONFIRM_PROPERTY_SUCCESS, message: result.data.message });
  } catch (error) {
    yield put({ type: REJECT_PROPERTY_ERROR, error: error.response.data })
  }
}

function* publishPropertySaga() {
  try {
    const data = yield select(stateData);
    const { id } = data;

    yield call(publishProperty, id);
    yield put({ type: CONFIRM_PROPERTY_SUCCESS, message: 'Publish property success.' });
  } catch (error) {
    console.error(error);
  }
}

function* publishPropertyWithoutPaySaga() {
  try {
    const data = yield select(stateData);
    const { id } = data;

    yield call(publishPropertyWithoutPay, id);
    yield put({ type: CONFIRM_PROPERTY_SUCCESS, message: 'Publish property success.' });
  } catch (error) {
    console.error(error);
  }
}

function* propertyDetailWatcher() {
  yield takeLatest(FETCH_PROPERTY_DETAIL_REQUEST, getPropertyDetail);
  yield takeLatest(REJECT_PROPERTY, rejectPropertySaga);
  yield takeLatest(VERIFY_PROPERTY, verifyPropertySaga);
  yield takeLatest(APPROVE_RENEWAL_PAYMENT, approveRenewalPaymentSaga);
  yield takeLatest(REJECT_RENEWAL_PAYMENT, rejectRenewalPaymentSaga);
  yield takeLatest(ASIGN_AGENT, asignAgentSaga);
  yield takeLatest(CONFIRM_PROPERTY, confirmPropertySaga);
  yield takeLatest(POST_ON_FACEBOOK, postOnFaceBookSaga);
  yield takeLatest(POST_ON_INSTAGRAM, postOnInstagramSaga);
  yield takeLatest(CHANGE_STATUS_PROPERTY, changeStatusPropertySaga);
  yield takeLatest(PUBLISH_PROPERTY_DETAIL_REQUEST, publishPropertySaga);
  yield takeLatest(PUBLISH_PROPERTY_DETAIL_WITHOUT_PAY_REQUEST, publishPropertyWithoutPaySaga);
}

export default propertyDetailWatcher
