import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  FETCH_SELLER_DETAIL_REQUEST,
  FETCH_SELLER_DETAIL_SUCCESS,
  FETCH_SELLER_DETAIL_ERROR,
  FETCH_PROPERTY_BY_SELLER_REQUEST,
  FETCH_PROPERTY_BY_SELLER_SUCCESS,
  FETCH_PROPERTY_BY_SELLER_ERROR,
  CHANGE_PAGE,
  CHANGE_ITEM_PER_PAGE
} from '../actions/fetchDataAction'

import {
  fetchData,
  fetchDataProperties,
  formatDataProperty
} from '../services/index';

export const getStateData = (state) => {
  return state.sellerDetailReducer
};

function* getSellerDetail() {
  try {
    const data = yield select(getStateData);
    const { id } = data;
    const result = yield call(fetchData, id);
    let seller = {...result.data};
    yield put({ type: FETCH_SELLER_DETAIL_SUCCESS, seller: seller });
    yield call(getPropertyBySeller);

  } catch (error) {
    yield put({ type: FETCH_SELLER_DETAIL_ERROR, error })
  }
}

function* getPropertyBySeller() {
  try {
    const stateData = yield select(getStateData);
    const { status, fromDate, toDate, itemPerPage, pageNumber, seller } = stateData;
    const result = yield call(fetchDataProperties, status, fromDate, toDate, itemPerPage, pageNumber, seller._id);
    const formatedData = yield call(formatDataProperty, result.data.data);
    const data = {data: formatedData, count: "total" in result.data ? result.data.total.count : 0};
    yield put({ type: FETCH_PROPERTY_BY_SELLER_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: FETCH_PROPERTY_BY_SELLER_ERROR, error })
  }
}


function* changePage() {
  yield put({ type: FETCH_PROPERTY_BY_SELLER_REQUEST });
}

function* changeItemPerPage() {
  yield put({ type: FETCH_PROPERTY_BY_SELLER_REQUEST });
}

function* sellerDetailWatcher() {
  yield takeLatest(FETCH_SELLER_DETAIL_REQUEST, getSellerDetail);
  yield takeLatest(FETCH_PROPERTY_BY_SELLER_REQUEST, getPropertyBySeller);
  yield takeLatest(CHANGE_PAGE, changePage);
  yield takeLatest(CHANGE_ITEM_PER_PAGE, changeItemPerPage);
}

export default sellerDetailWatcher 
