import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

class Confirm extends PureComponent {

    render() {
        const { color, message, isOpen, toggle, confirm } = this.props;
        let Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        let title = "Confirm";
        return (
            <div >
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--success`}
                >
                    <div className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
                        {Icon}
                        <h4 className="text-modal  modal__title">{title}</h4>
                    </div>
                    <div className="modal__body">
                        {message}
                    </div>
                    <ButtonToolbar className="modal__footer">
                        <Button className="modal_cancel" onClick={toggle}>Cancel</Button>
                        <Button className="modal_ok" color="success" onClick={confirm}>Ok</Button>
                    </ButtonToolbar>
                </Modal>
            </div>
        );
    }
}

export default Confirm;
