import { call, put, takeLatest, cancelled, select } from "redux-saga/effects";
import * as ACTIONS from "../redux/actions/createExploreSectionAction";
import { history, PATH_URL } from "../../../../shared/helpers/index";
import {
  createExploreSection,
  createExploreSectionImageUrl
} from "../services/index";

function* createExplore(dataInput) {
  try {
    const imageUrl = yield call(createExploreSectionImageUrl, dataInput.data);
    let imgObj;
    const { success, data } = imageUrl && imageUrl.data;

    if (success) {
      imgObj = data[0];
    }

    let exploreSectionObj = {
      description: dataInput.data.description,
      order: Number(dataInput.data.order),
      status: dataInput.data.status,
      title: dataInput.data.title,
      type: dataInput.data.type,
      image: imgObj
    };

    if (dataInput.data.value) {
      exploreSectionObj = { ...exploreSectionObj, value: dataInput.data.value };
    }

    yield call(createExploreSection, exploreSectionObj);
    yield put({ type: ACTIONS.CREATE_EXPLORE_SECTION_SUCCESS });
  } catch (error) {
    yield put({
      type: ACTIONS.CREATE_EXPLORE_SECTION_FAILED,
      error: JSON.stringify(error.response.data.message)
    });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* createExploreSectionWatcher() {
  yield takeLatest(ACTIONS.CREATE_EXPLORE_SECTION, createExplore);
}

export default createExploreSectionWatcher;
