import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import Filter from "./components/Filter";
import MatTable from "../../../shared/components/table/components/MatTable";
import { history, PATH_URL } from "../../../shared/helpers/index";
import { PROPERTY_STATUS } from "../../../shared/helpers/constants";
import Loading from "../../../shared/components/Loading";
import {
  onShortProperties,
  fetchProperty,
  changeFilterValue,
  setPublishTypeValue,
  changePage,
  changeItemPerPage,
  changeDeafultPage,
  resetState
} from "./redux/actions/index";
import { withRouter } from "react-router";

class Properties extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onClickRow = this.onClickRow.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
  }

  componentWillMount() {
    this.props.resetState();
    this.props.fetchProperty();
  }

  componentDidMount() {
    if (this.props.location.state) {
      const status = this.props.location.state.status;

      if (status === "verifying") {
        this.props.changeFilterValue({
          status: {
            value: "verifying",
            label: "Verifying"
          }
        });
      }

      if (status === "closed") {
        this.props.changeFilterValue({
          status: {
            value: "closed",
            label: "Closed"
          }
        });
      }
      this.onFilter();
    }
  }

  onPageChange(page, orders) {
    this.props.changePage(page);
  }

  onClickRow(data) {
    const detailURL = "company/property/" + data._id;
    // const detailURL = "property/" + data._id;
    // history.push(detailURL);

    const newWindow = window.open(PATH_URL.BASE + detailURL, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  }

  onChangeRowsPerPage(event) {
    this.props.changeItemPerPage(event.target.value);
  }

  changeFilterValue = filtersData => {
    this.props.changeFilterValue(filtersData);
  };

  setPublishTypeValue = publishType => {
    this.props.setPublishTypeValue(publishType);
  };

  onFilter = () => {
    this.props.changeDeafultPage();
    this.props.fetchProperty();
  };

  onEdit = (id, status, stage) => {
    const { tokenUA } = this.props;

    if (status === PROPERTY_STATUS.DRAFT) {
      window.open(
        PATH_URL.DEV_EDIT +
          id +
          "/" +
          tokenUA +
          "/" +
          PROPERTY_STATUS.DRAFT +
          "/" +
          stage,
        "_blank"
      );
    }
    if (
      status === PROPERTY_STATUS.VERIFYING ||
      status === PROPERTY_STATUS.AVAILABLE
    ) {
      window.open(
        PATH_URL.DEV_EDIT +
          id +
          "/" +
          tokenUA +
          "/" +
          PROPERTY_STATUS.VERIFYING +
          "/" +
          stage,
        "_blank"
      );
    }
  };

  onClickCell = (columnId, row) => {
    // const detailURL = "property/" + row._id;
    // history.push(detailURL);
    const detailURL = "company/property/" + row._id;
    const newWindow = window.open(PATH_URL.BASE + detailURL, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  };

  render() {
    const {
      result,
      pageNumber,
      itemPerPage,
      showLoading,
      status,
      fromDate,
      toDate,
      name
    } = this.props;

    const { data, count } = result;

    return (
      <Container className="properties">
        <Loading isOpen={showLoading} />
        <Row>
          <Filter
            status={{ ...status }}
            fromDate={fromDate}
            toDate={toDate}
            changeFilterValue={this.changeFilterValue}
            onFilter={this.onFilter}
            setPublishTypeValue={this.setPublishTypeValue}
            name={name}
          />

          <Col md={12} lg={12}>
            {data.length > 0 ? (
              <MatTable
                dataType="property"
                actionColumn={[{ action: this.onEdit }]}
                data={data}
                onShort={this.props.onShortProperties}
                count={count}
                currentPage={pageNumber}
                onPageChange={this.onPageChange}
                rowsPerPage={parseInt(itemPerPage)}
                column={[
                  "title",
                  "products",
                  "owner_type",
                  "status",
                  "availability",
                  "is_publish",
                  "verify_type",
                  "add_time",
                  "upd_time"
                ]}
                header={[
                  "Title",
                  "Payer",
                  "Seller Type",
                  "Status",
                  "Available",
                  "Paid",
                  "Posting Type",
                  "Created Date",
                  "Last Update"
                ]}
                percents={["20%", "10%", "8%", "8%", "5%", "5%", "10%", "10%", "10%"]}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                // onRowsPerPageChange={this.onChangeRowsPerPage}
                // onClickRow={this.onClickRow}
                showId={true}
                onClickCell={this.onClickCell}
              />
            ) : (
              <span>No Data</span>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.propertyReducer,
    ...state.clientReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProperty: () => {
      dispatch(fetchProperty());
    },
    changeFilterValue: filtersData => {
      dispatch(changeFilterValue(filtersData));
    },
    setPublishTypeValue: publishType => {
      dispatch(setPublishTypeValue(publishType));
    },
    changePage: pageNumber => {
      dispatch(changePage(pageNumber));
    },
    changeItemPerPage: itemPerPage => {
      dispatch(changeItemPerPage(itemPerPage));
    },
    onShortProperties: value => {
      dispatch(onShortProperties(value));
    },
    changeDeafultPage: () => {
      dispatch(changeDeafultPage());
    },
    resetState: () => {
      dispatch(resetState());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation("common")(Properties)));
