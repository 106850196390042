import * as ACTIONS from "../actions/createExploreSectionAction";
const initialState = {
  loading: false,
  message: "",
  showNotification: false,
  error: false
};

export default function createExploreSectionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACTIONS.CREATE_EXPLORE_SECTION:
      return { ...state, loading: true };
    case ACTIONS.CREATE_EXPLORE_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        showNotification: true,
        message: "Create Successfully"
      };
    case ACTIONS.CREATE_EXPLORE_SECTION_FAILED:
      const errors = action.error ? JSON.parse(action.error) : "";
      let message = "";
      return {
        ...state,
        loading: false,
        error: true,
        showNotification: true,
        message: message || errors
      };
    case ACTIONS.CLOSE_EXPLORE_SECTION_MODAL:
      return { ...state, showNotification: false };

    default:
      return state;
  }
}
