import * as ACTIONS from '../actions/createAmbassadorAction';
import { capitalizeFirstLetter } from '../../../../../shared/helpers/utils';
const initialState = {
  loading: false,
  message: "",
  shownotification: false,     
  error: false
}

export default function createAmbassadorReducer(state = initialState, action) {

  switch (action.type) {
    case ACTIONS.CREATE:
      return { ...state, loading: true };
    case ACTIONS.CREATE_SUCCESS:
      
      return { ...state, loading: false, error: false, shownotification: true, message: "Create Successfully" };
    case ACTIONS.CREATE_FAILED:
      const errors = action.error ? JSON.parse(action.error) : '';
      let message = '';
      if (errors && Array.isArray(errors) && errors.length) {
        errors.map(errMes => {
          if (errMes === 'phone_number must be a phone number') {
            message += 'Phone number should be 10-13 numeric characters \n';
          } else if (errMes === 'email must be an email') {
            message += 'Email is invalid format \n';
          } else {
            message += `${capitalizeFirstLetter(errMes)} \n`;
          }
        })
      }
      return { ...state, loading: false, error: true, shownotification: true, message: message || errors };

    case ACTIONS.CLOSE_MODAL:
      return { ...state, shownotification: false };

    default:
      return state;
  }
}
