export const BASE_URL = `${process.env.API_BASE_URL}`;
export const IMAGE_BASE_URL = `${process.env.IMAGE_BASE_URL}`;
export const URBANASSET_BASE_API = `${process.env.URBANASSET_BASE_API}`;
export const URBANASSET_IMAGE_BASE_API = `${process.env.URBANASSET_IMAGE_BASE_API}`;
export const STORAGE_API_URL = `${process.env.STORAGE_API_URL}`;

export const API_URL = {
  LOGIN: BASE_URL + "/auth/login",
  LOGIN_UA: URBANASSET_BASE_API + "/user/login",
  LOGOUT_UA: URBANASSET_BASE_API + "/user/logout",
  CHANGE_STATUS_PROPERTY: URBANASSET_BASE_API + "/property/my/change-status",
  AUTHO_ME: "auth/me",
  LOGOUT: BASE_URL + "/company/user/logout",
  FORGOT_PASSWORD: BASE_URL + "/company/user/forgot-pwd",
  PROFILE: BASE_URL + "/company/user/me",
  GET_LIST_PROPERTY: "property/get",
  PROPERTY_DETAIL: "property",
  VERIFY_PROPERTY: "property/verify",
  VERIFY_ASSIGN_PROPERTY: "property/verify_assign",
  REJECT_PROPERTY: "property/reject",
  CONFIRM_PROPERTY: "property/confirm",
  POST_PROPERTY_ON_SOCIAL_NETWORK: "property/publish_social_network",
  GET_LIST_AGENT: "user/agents",
  GET_NEWS_CATEGORIES: "newscategory",
  NEWS_CATEGORIES_DETAIL: "newscategory",
  NEWS_CATEGORIES_DELETE: "newscategory/delete",
  UPDATE_NEWS_CATEGORIES: "newscategory/update",
  CREATE_NEWS_CATEGORIES: "newscategory/create",
  ASIGN_AGENT: "property/assign",
  AGENT_DETAIL: "user/agent",
  USER_COUNT: "user/role-Count",
  MOST_ACTIVE_USERS: "user/most-acting-agents",
  TAKE_OVER_PROPERTY: "property/takeover",
  DEACTIVE_AMBASSADOR: "user/deactive",
  ACTIVE_AMBASSADOR: "user/active",
  GENERAL_PARAM: URBANASSET_BASE_API + "/general/value",
  GET_USERS: "user",
  ACTIVE_USER: "user/active/",
  DEACTIVE_USER: "user/deactive/",
  CREATE_AMBASSADOR: "user/create_agent",
  GET_LIST_SELLER: "user/sellers",
  GET_SELLER_DETAIl: "user/seller",
  CHAT_ROOM: "chatroom",
  CHAT_MESSAGE: "chatmessage",
  ADD_CHAT_MESSAGE: "chatmessage/add",
  TOKEN_CHAT: "chatmessage/token",
  PAY_MENT: "payment",
  CURRENCY: "currency",
  EMAIL_TEMPLATE_GET: "email_template",
  EMAIL_TEMPLATE_DETAIL: "email_template/detail",
  EMAIL_TEMPLATE_CREATE: "email_template/create",
  EMAIL_TEMPLATE_DELETE: "email_template/delete",
  EMAIL_TEMPLATE_UPDATE: "email_template/update",
  GET_TRANSACTION_FEE: "setting",
  UPDATE_TRANSACTION_FEE: "setting/update/",
  EMAIL_TYPE: "email_template/template_type_params",
  CHAT_WITH_OWNER: "chatroom/get_room",
  GET_PACKAGE: "packages",
  CONFIG_PACKAGE: "packages/config",
  PUBLISH_PROPERTY: URBANASSET_BASE_API + "/property/admin/publish",
  PUBLISH_PROPERTY_WITHOUT_PAY: BASE_URL + "/property/publish_without_paying",
  GET_EXPLORE_SECTION: "taxonomy",
  EXPLORE_SECTION_DETAIL: "taxonomy",
  CREATE_EXPLORE_SECTION: "taxonomy/create",
  EXPLORE_SECTION_DELETE: "taxonomy/delete",
  UPDATE_EXPLORE_SECTION: "taxonomy/update",
  UPLOAD_EXPLORE_SECTION_IMAGE: STORAGE_API_URL + "/storage/adminupload",
  RENEWAL_PAYMENT: "property/approve_renew_agent_payment"
};

export const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT"
};
