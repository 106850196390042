import { call, put, takeLatest, cancelled } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import { createEmailTemplateDetail, loadEmailType } from '../services';

export const getData = (state) => state.chatReducer;

function* createEmailTemplate(action) {
    try {
        const { data } = action.payload;
        yield put({ type: ACTIONS.LOADING });
        const result = yield call(createEmailTemplateDetail, data);
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_CREATE_SUCCEEDED, payload: { data: result.data.data } });
        yield call(history.push, PATH_URL.EMAIL_TEMPLATE);

    } catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_CREATE_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}

function* loadTemplateEmail(action) {
    try {
        
        const result = yield call(loadEmailType);
        yield put({ type: ACTIONS.LOAD_EMAIL_SUCCESS, data: { data: result.data } });

    } catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_CREATE_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}

function* emailTemplateCreateWatcher() {
    yield takeLatest(ACTIONS.EMAIL_TEMPLATE_CREATE_REQUESTED, createEmailTemplate);
    yield takeLatest(ACTIONS.LOAD_EMAIL_TYPES, loadTemplateEmail);
}

export default emailTemplateCreateWatcher;
