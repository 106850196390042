import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index'

const fetchListAmbassador = (itemPerPage, pageNumber,name, status, sort, desc) => {
  return apiCaller(API_URL.GET_LIST_AGENT, HTTP_METHOD.GET, null, {
    pageNumber,
    itemPerPage,
    name,
    status,
    sort,
    desc
  })
}


const countUsers = () => apiCaller(API_URL.USER_COUNT, HTTP_METHOD.GET);

export { fetchListAmbassador, countUsers };
