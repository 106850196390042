import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { Component } from "react";

class SelectField extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ]).isRequired
  };

  static defaultProps = {
    placeholder: "",
    options: []
  };

  handleChange = selectedOption => {
    const { onChange } = this.props;
    onChange(selectedOption);
  };

  render() {
    const {
      value,
      name,
      placeholder,
      options,
      defaultValue,
      required
    } = this.props;

    return (
      <>
        <Select
          ref={ref => (this.setSelect = ref)}
          name={name}
          value={defaultValue}
          onChange={this.handleChange}
          options={options}
          clearable={false}
          placeholder={placeholder}
          classNamePrefix="react-select"
          // defaultValue={defaultValue}
          // minHeight={30}
        />
        {required && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            value={value}
            required={required}
            onFocus={() => this.setSelect.focus()}
          />
        )}
      </>
    );
  }
}

const renderSelectField = props => {
  const {
    input,
    meta,
    options,
    placeholder,
    className,
    defaultValue,
    required
  } = props;

  return (
    <div className={`form__form-group-input-wrap ${className}`}>
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        required={required}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  className: PropTypes.string
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: "",
  className: ""
};

export default renderSelectField;
