import {
  call,
  put,
  takeLatest,
  takeEvery,
  cancelled,
  select
} from "redux-saga/effects";
import * as ACTIONS from "../redux/actions/exploreSectionDetailAction";
import { history, PATH_URL } from "../../../../shared/helpers/index";
import {
  fetchDetailExploreSection,
  formatExploreSectionDetail,
  updateExploreSectionService,
  createExploreSectionImageUrl
} from "../services/index";

function* detailExploreSection({ id }) {
  try {
    const result = yield call(fetchDetailExploreSection, id);
    const formatedData = yield call(formatExploreSectionDetail, result.data);

    yield put({
      type: ACTIONS.EXPLORE_SECTION_DETAIL_FETCH_SUCCESS,
      payload: { data: formatedData }
    });
  } catch (error) {
    yield put({
      type: ACTIONS.UPDATE_EXPLORE_SECTION_FAILED,
      error: JSON.stringify(error.response.data.message)
    });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* updateExploreSection(action) {
  try {
    const { id, data: editData } = action.payload;

    let exploreSectionObj;
    if (!editData.hasChangeImage) {
      exploreSectionObj = {
        description: editData.description,
        order: Number(editData.order),
        status: editData.status,
        title: editData.title,
        type: editData.type,
        image: editData.image[0].name
      };

      if (editData.value) {
        exploreSectionObj = {
          ...exploreSectionObj,
          value: editData.value
        };
      }

      yield call(updateExploreSectionService, id, exploreSectionObj);
      yield put({
        type: ACTIONS.UPDATE_EXPLORE_SECTION_SUCCESS
      });
    } else {
      const imageUrl = yield call(createExploreSectionImageUrl, editData);
      const { success, data } = imageUrl && imageUrl.data;
      let exploreSectionObj;

      if (success) {
        exploreSectionObj = {
          description: editData.description,
          order: Number(editData.order),
          status: editData.status,
          title: editData.title,
          type: editData.type,
          image: data[0]
        };
        yield call(updateExploreSectionService, id, exploreSectionObj);
        yield put({
          type: ACTIONS.UPDATE_EXPLORE_SECTION_SUCCESS
        });
      }
    }
  } catch (error) {
    yield put({
      type: ACTIONS.UPDATE_EXPLORE_SECTION_FAILED,
      error: JSON.stringify(error.response.data.message)
    });
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* exploreSectionDetailWatcher() {
  yield takeEvery(
    ACTIONS.EXPLORE_SECTION_DETAIL_FETCH_REQUESTED,
    detailExploreSection
  );
  yield takeEvery(
    ACTIONS.UPDATE_EXPLORE_SECTION_REQUESTED,
    updateExploreSection
  );
}

export default exploreSectionDetailWatcher;
