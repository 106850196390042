import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import {
  change,
  untouch,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector
} from "redux-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import renderSelectField from "../../../../shared/components/form/Select";
import renderDropZoneField from "../../../../shared/components/form/DropZone";
import { createMuiTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class ExploreSectionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cateStatus: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
      ],
      sectionType: [
        { value: "development", label: "Development" },
        { value: "category", label: "Category" },
        { value: "area", label: "Area" }
      ],
      tabIndex: 0,
      value: 0,
      selectedStatus: undefined,
      selectedType: undefined,
      changeState: false,
      changeTypeState: false,
      exploreSectionId: this.props.id
    };
  }
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  componentDidUpdate = (prevState, prevProps) => {
    if (prevState.selectedStatus !== this.state.selectedStatus) {
      this.setState({ changeState: true });
    }
    if (prevState.selectedType !== this.state.selectedType) {
      this.setState({ changeTypeState: true });
    }
  };

  onChangeStatus = e => {
    this.setState({ selectedStatus: e });
  };

  resetValue() {
    const fields = ["enValue", "thValue"];

    for (let field of fields) {
      this.props.change(field, null);
      this.props.untouch(field);
    }
  }

  onChangeType = e => {
    this.resetValue();
    this.setState({ selectedType: e });
  };

  submit = values => {
    const {
      changeState,
      changeTypeState,
      selectedStatus,
      selectedType,
      exploreSectionId
    } = this.state;

    const { status, type } = this.props.data;
    const { value: statusValue } = status;
    const { value: statusType } = type;
    const hasChangeImage = values.files[0].path ? true : false;

    let params = {
      title: {
        en: values.enTitle,
        th: values.thTitle
      },
      description: {
        en: values.enDesc,
        th: values.thDesc
      },

      image: values.files,
      hasChangeImage: hasChangeImage,
      order: values.order,
      status: changeState ? selectedStatus.value : statusValue,
      type: changeTypeState ? selectedType.value : statusType
    };

    if (
      selectedType === undefined &&
      this.props.initialValues.type &&
      this.props.initialValues.type.value === "area"
    ) {
      params = {
        ...params,
        value: {
          en: values.enValue,
          th: values.thValue
        }
      };
    }

    if (selectedType && selectedType.value === "area") {
      params = {
        ...params,
        value: {
          en: values.enValue,
          th: values.thValue
        }
      };
    }

    this.props.handleUpdate(exploreSectionId, params);
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  setTabIndex = index => {
    this.setState({ tabIndex: index });
  };

  render() {
    const { handleSubmit, initialValues } = this.props;
    const { label, value } = initialValues;
    const {
      cateStatus,
      selectedStatus,
      selectedType,
      sectionType,
      changeTypeState,
      tabIndex
    } = this.state;

    return (
      <Col md={12} lg={12}>
        <form className="form" onSubmit={handleSubmit(this.submit)}>
          <Card>
            <CardBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => this.setTabIndex(index)}
              >
                <TabList>
                  <Tab>English</Tab>
                  <Tab>Thai</Tab>
                </TabList>
                <TabPanel>
                  <div
                    className="form__form-group"
                    style={{ marginTop: "40px" }}
                  >
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <Field
                        name="enTitle"
                        component="input"
                        type="text"
                        placeholder="Title *"
                        required
                      />
                    </div>
                  </div>
                  {selectedType
                    ? selectedType.value === "area" && (
                        <div
                          className="form__form-group"
                          style={{ marginTop: "40px" }}
                        >
                          <span className="form__form-group-label">
                            Find Place (ex.Chatuchak, Bangkok, Thailand)
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="enValue"
                              component="input"
                              type="text"
                              placeholder="Value * Example Chatuchak, Bangkok, Thailand"
                              required
                            />
                          </div>
                        </div>
                      )
                    : this.props.initialValues.type &&
                      this.props.initialValues.type.value === "area" && (
                        <div
                          className="form__form-group"
                          style={{ marginTop: "40px" }}
                        >
                          <span className="form__form-group-label">
                            Find Place (ex.Chatuchak, Bangkok, Thailand)
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="enValue"
                              component="input"
                              type="text"
                              placeholder="Value * Example Chatuchak, Bangkok, Thailand"
                              required
                            />
                          </div>
                        </div>
                      )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <Field
                        name="enDesc"
                        component="input"
                        type="text"
                        placeholder="Description *"
                        required
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="form__form-group"
                    style={{ marginTop: "40px" }}
                  >
                    <span className="form__form-group-label">ชื่อเรื่อง</span>
                    <div className="form__form-group-field">
                      <Field
                        name="thTitle"
                        component="input"
                        type="text"
                        placeholder="ชื่อ *"
                        required
                      />
                    </div>
                  </div>
                  {selectedType
                    ? selectedType.value === "area" && (
                        <div
                          className="form__form-group"
                          style={{ marginTop: "40px" }}
                        >
                          <span className="form__form-group-label">
                            พื้นที่ ที่ค้นหา (ตัวอย่าง.Chatuchak, Bangkok,
                            Thailand)
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="thValue"
                              component="input"
                              type="text"
                              placeholder="Value *"
                              required
                            />
                          </div>
                        </div>
                      )
                    : this.props.initialValues.type &&
                      this.props.initialValues.type.value === "area" && (
                        <div
                          className="form__form-group"
                          style={{ marginTop: "40px" }}
                        >
                          <span className="form__form-group-label">
                            พื้นที่ ที่ค้นหา (ตัวอย่าง.Chatuchak, Bangkok,
                            Thailand)
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="thValue"
                              component="input"
                              type="text"
                              placeholder="Value *"
                              required
                            />
                          </div>
                        </div>
                      )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">รายละเอียด</span>
                    <div className="form__form-group-field">
                      <Field
                        name="thDesc"
                        component="input"
                        type="text"
                        placeholder="รายละเอียด *"
                        required
                      />
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              <hr style={({ marginTop: "50px" }, { backgroundColor: "red" })} />
              <div className="form__form-group">
                <span className="form__form-group-label">Order(รายการ)</span>
                <div className="form__form-group-field">
                  <Field
                    name="order"
                    component="input"
                    type="number"
                    placeholder="Order *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Status(สถานะ)</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-field">
                    <Field
                      name="status"
                      component={renderSelectField}
                      options={cateStatus}
                      className="properties__status-select"
                      onChange={this.onChangeStatus}
                      value={selectedStatus}
                      defaultValue={
                        this.state.changeState
                          ? selectedStatus
                          : initialValues.status
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Type(ประเภท)</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-field">
                    <Field
                      name="status"
                      component={renderSelectField}
                      options={sectionType}
                      className="properties__status-select"
                      onChange={this.onChangeType}
                      value={selectedType}
                      defaultValue={
                        this.state.changeTypeState
                          ? selectedType
                          : initialValues.type
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Image(รูป)</span>
                <div
                  className="form__form-group-field"
                  style={{ margin: "20px 0px 50px", padding: "20px 0px 50px" }}
                >
                  <div className="form__form-group-img">
                    <div className="form__form-group-field-img">
                      <Field name="files" component={renderDropZoneField} />
                    </div>
                  </div>
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="danger" type="submit">
                  Update
                </Button>
                <Link
                  className="btn btn-primary"
                  to="/company/explore-sections"
                >
                  Cancel
                </Link>
              </ButtonToolbar>
            </CardBody>
          </Card>
        </form>
      </Col>
    );
  }
}

ExploreSectionForm = reduxForm({
  form: "ExploreSectionForm", // a unique identifier for this form
  enableReinitialize: true
  // validate
})(withTranslation("common")(ExploreSectionForm));

export default connect(
  state => ({
    initialValues: state.exploreSectionDetailReducer.exploreSectionData // pull initial values from account reducer
    // templatesData: selector(state, "templates")
  }),
  {} //bind actions
)(ExploreSectionForm);
