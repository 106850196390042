import { takeLatest, call, put, cancelled, take } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers/index';

import * as CONSTANTS from '../actions/constants';

function* createParameter ({item}) {
  try {
    yield put({ type: CONSTANTS.CREATE_LOADING });
    yield put({ type: CONSTANTS.PARAMETER_CREATE_FETCH_SUCCEEDED, payload: item });
  } catch (error) {
    yield put({ type: CONSTANTS.PARAMETER_CREATE_FETCH_FAILED, error })
  } finally {
    if(yield cancelled()) {
        yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* createParameterWatcher () {
    yield takeLatest(CONSTANTS.PARAMETER_CREATE_FETCH_REQUESTED, createParameter);
}

export default createParameterWatcher 
