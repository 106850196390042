import { takeLatest, call, put, cancelled } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers/index';
import { editCurrency, fetchData, formatData, fetchDataDetail } from '../services'
import * as CONSTANTS from '../actions/constants';


function* fetchCurrencyParams() {
  try {
    yield put({ type: CONSTANTS.LOADING });
    const result = yield call(fetchData)
    const listCurencyParams = yield call(formatData, result.data)
    yield put({ type: CONSTANTS.FETCH_CURRENCY_PARAMS_SUCCESS, listCurencyParams });
  } catch (error) {
    yield put({ type: CONSTANTS.FETCH_CURRENCY_PARAMS_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* editCurrencies({ data }) {
  const id = data._id;
  const formatData = {
    name: data.name,
    code: data.code,
    symbol: data.symbol,
    status: data.status,
    rent_max_value: data.rent_max_value,
    max_value: data.max_value
  }

  try {
    yield put({ type: CONSTANTS.LOADING });
    yield call(editCurrency, id, formatData);
    yield put({ type: CONSTANTS.EDIT_CURRENCY_SUCCESS });
    yield call(history.replace, PATH_URL.CURRENCIES);
  } catch (error) {
    yield put({ type: CONSTANTS.EDIT_CURRENCY_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* fetchCurrencyDetail({ id }) {
  try {
    yield put({ type: CONSTANTS.LOADING });
    const { data } = yield call(fetchDataDetail, id);
    const currency = data;
    yield put({ type: CONSTANTS.FETCH_CURRENCY_DETAIL_SUCCESS, currency });
  } catch (error) {
    yield put({ type: CONSTANTS.FETCH_CURRENCY_DETAIL_FAILURE, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* editCurrenciesWatcher() {
  yield takeLatest(CONSTANTS.EDIT_CURRENCY_REQUEST, editCurrencies);
  yield takeLatest(CONSTANTS.FETCH_CURRENCY_PARAMS_REQUEST, fetchCurrencyParams);
  yield takeLatest(CONSTANTS.FETCH_CURRENCY_DETAIL_REQUEST, fetchCurrencyDetail);
}

export default editCurrenciesWatcher 
