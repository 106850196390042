import React, { PureComponent } from 'react';
import { Modal } from 'reactstrap';
import loading from "../img/loading.gif";

const Loading = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            modalClassName={`ltr-support`}
            className={"custom-loading-container"}
        >
            <img src={loading} alt="loading" />
        </Modal>
    )
}

export default Loading;
