export const FETCH_SELLER_REQUEST = 'FETCH_SELLER_REQUEST';
export const FETCH_SELLER_SUCCESS = 'FETCH_SELLER_SUCCESS';
export const FETCH_SELLER_ERROR = 'FETCH_SELLER_ERROR';
export const CHANGE_PAGE = 'CHANGE_SELLER_PAGE';
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
export const CHANGE_ITEM_PER_PAGE = 'CHANGE_SELLER_ITEM_PER_PAGE';
export const ON_SHORT_SELLER = 'ON_SHORT_SELLER';
export const CHANGE_DEFAULT_PAGE = 'CHANGE_DEFAULT_PAGE';
export const RESET_STATE = 'RESET_STATE';

const fetchSeller = function fetchSeller() {
    return {
        type: FETCH_SELLER_REQUEST
    }
}

const fetchSellerSuccess = function fetchSellerSuccess(data) {
    return {
        type: FETCH_SELLER_SUCCESS,
        data
    }
}

const fetchSellerError = function fetchSellerError() {
    return {
        type: FETCH_SELLER_ERROR,
    }
}

const changeFilterValue = function changeFilterValue(filtersData) {
    return {
        type: CHANGE_FILTER_VALUE,
        filtersData
    }
}

const changePage = function changePage(pageNumber) {
    return {
        type: CHANGE_PAGE,
        pageNumber: pageNumber
    }
}

const changeItemPerPage = function changeItemPerPage(itemPerPage) {
    return {
        type: CHANGE_ITEM_PER_PAGE,
        itemPerPage: itemPerPage
    }
}

const onShortSeller = (value) => {
    return {
        type: ON_SHORT_SELLER,
        value
    }
}

const changeDeafultPage = function changeDeafultPage() {
    return {
        type: CHANGE_DEFAULT_PAGE,
        pageNumber: 0,
    }
}

const resetState = function resetState() {
    return {
        type: RESET_STATE
    }
}

export {
    fetchSeller,
    fetchSellerSuccess,
    fetchSellerError,
    changeFilterValue,
    changeItemPerPage,
    changePage,
    onShortSeller,
    changeDeafultPage,
    resetState
}

