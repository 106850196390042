import * as ACTIONS from '../actions/ambassadorAction';
const initialState = {
  items: [],
  loading: true,
  itemPerPage: 10,
  count: 0,
  pageNumber: 0,
  showLoading: false,
  rowsPerPage: 10,
  sort: "",
  desc: 0,
  name: "",
  status: { value: '', label: 'All' }
}

export default function ambassadorReducer(state = initialState, action) {

  switch (action.type) {
    case ACTIONS.LOADING:
    case ACTIONS.AMBASSADOR_FETCH_REQUESTED:
      return { ...state, loading: true };
    case ACTIONS.AMBASSADOR_FETCH_SUCCEEDED:
        var ambassadorData = action.payload
        if (ambassadorData.items && ambassadorData.items.length) {
          ambassadorData.items.map(item => {
            if(!item.verified) {
              item.status = 'unverified';
            }
          })
        }
      return { ...state, items: ambassadorData.items, count: action.payload.count, loading: false, error: false };
    case ACTIONS.AMBASSADOR_FETCH_FAILED:
      return { ...state, loading: false, error: true };
    case ACTIONS.HANDLE_CHANGE_TABLE:
      return { ...state, ...action.payload };
    case ACTIONS.CHANGE_ITEM_PAGE:
      return { ...state, itemPerPage: action.number };
    case ACTIONS.CHANGE_DEFAULT_PAGE:
    case ACTIONS.CHANGE_PAGE:
      return { ...state, pageNumber: action.pageNumber };
    case ACTIONS.ON_SHORT_AMBASSADOR:
      return { ...state, sort: action.value.col, desc: action.value.value === 'desc' ? -1 : 0 };
    case ACTIONS.CHANGE_FILTER_VALUE:
      const { name, status } = action.filtersData;
      return { ...state, name: name !== undefined ? name : state.name, status: status !== undefined ? status : state.status };
    case ACTIONS.RESET_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
