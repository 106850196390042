import * as ACTIONS from "../actions/exploreSectionAction";
const initialState = {
  exploreSectionData: [],
  loading: true,
  itemPerPage: 10,
  count: 0,
  pageNumber: 0,
  showLoading: false,
  rowsPerPage: 10,
  sort: "",
  desc: 0,
  title: "",
  status: { value: "", label: "All" },
  type: { value: "", lable: "All" },
  uploadImage: false
};

export default function exploreSectionReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.LOADING:
    case ACTIONS.EXPLORE_SECTION_FETCH_REQUESTED:
      return { ...state, loading: true };
    case ACTIONS.EXPLORE_SECTION_FETCH_SUCCEEDED:
      var exploreSectionData = action.payload;
      return {
        ...state,
        exploreSectionData: exploreSectionData.items,
        count: action.payload.count,
        loading: false,
        error: false
      };
    case ACTIONS.EXPLORE_SECTION_FETCH_FAILED:
      return { ...state, loading: false, error: true };
    case ACTIONS.HANDLE_CHANGE_TABLE:
      return { ...state, ...action.payload };
    case ACTIONS.CHANGE_ITEM_PAGE:
      return { ...state, itemPerPage: action.number };
    case ACTIONS.CHANGE_DEFAULT_PAGE:
    case ACTIONS.CHANGE_PAGE:
      return { ...state, pageNumber: action.pageNumber };
    case ACTIONS.ON_SORT_EXPLORE_SECTION:
      return {
        ...state,
        sort: action.value.col,
        desc: action.value.value === "desc" ? -1 : 0
      };
    case ACTIONS.CHANGE_FILTER_VALUE:
      const { title, status, type } = action.filtersData;
      return {
        ...state,
        title: title !== undefined ? title : state.title,
        status: status !== undefined ? status : state.status,
        type: type !== undefined ? type : state.type
      };
    case ACTIONS.RESET_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
