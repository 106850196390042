import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import ExampleCard from './components/ExampleCard';

const Emails = ({ t }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('emails.page_title')}</h3>
      </Col>
    </Row>
    <Row>
      <ExampleCard />
    </Row>
  </Container>
);

export default withTranslation('common')(Emails);
