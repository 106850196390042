import React, { Component } from "react";
import { Card, CardBody, Col } from "reactstrap";
import FormCreateExploreSections from "./components/FormCreateExploreSections";
import {
  handleCreate,
  createFail,
  createSuccess,
  closeModal
} from "./redux/actions/createExploreSectionAction";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import NotificationModal from "../../../shared/components/NotificationModal";
import Loading from "../../../shared/components/Loading";
import { history } from "../../../shared/helpers/index";

class ExploreSectionsCreate extends Component {
  closeModalSuccess = error => {
    this.props.closeModal();
    if (!error) history.goBack();
  };

  render() {
    const {
      loading,
      message,
      showNotification,
      error,
      closeModal
    } = this.props;

    return (
      <Col md={12}>
        <Loading isOpen={loading} />
        <NotificationModal
          isOpen={showNotification}
          color={error ? "danger" : "success"}
          message={message}
          toggle={() => {
            this.closeModalSuccess(error);
          }}
        />
        <Card>
          <CardBody>
            <FormCreateExploreSections handleCreate={this.props.handleCreate} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.createExploreSectionReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCreate: data => {
      dispatch(handleCreate(data));
    },
    createSuccess: () => {
      dispatch(createSuccess());
    },
    createFail: error => {
      dispatch(createFail(error));
    },
    closeModal: () => {
      dispatch(closeModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ExploreSectionsCreate));
