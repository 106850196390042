import React from 'react';
import { Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Carousel from '../../../../shared/components/carousel/CarouselMultiply';

const RelatedPropertiesCarousel = ({ t, items, title }) => (
  <Col md={12} lg={12} xl={12}>
    
        <div >
        <h4 className="bold-text">{title}</h4>
        <hr />
       </div>

        <Carousel>
          {items}
        </Carousel>

  </Col>
);

RelatedPropertiesCarousel.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(RelatedPropertiesCarousel);
