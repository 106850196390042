import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import Loading from '../../../../shared/components/Loading';
import { validate, requiredInput } from './validate';
import renderField from '../../../../shared/components/form/Field';
import { connect } from 'react-redux';

class CurrenciesEditForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, currenciesEdit, handleBack, submitting, pristine } = this.props;
    return (
      <Container className="currenciesContainer">
        <Loading isOpen={currenciesEdit.loading} />
        <Row>
          <Col md={12} lg={3}>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Currency name: </span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        type="text"
                        component="input"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Price max range for rent: </span>
                    <div className="form__form-group-field">
                      <Field
                        style={{ width: "60px" }}
                        name="rent_max_value"
                        type="number"
                        component={renderField}
                        validate={[requiredInput]}
                        onChange={e => {
                          const price = e.target.value;
                          if (!isNaN(price)) {
                            this.props.change('max_value', price * 300)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Price max range for sale: </span>
                    <div className="form__form-group-field">
                      <Field
                        name="max_value"
                        type="number"
                        component="input"
                        validate={[requiredInput]}
                        disabled
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button size="sm" color="danger" type="submit" disabled={submitting || pristine}>Update</Button>
                    <Button size="sm" type="button" onClick={handleBack}>
                      Cancel
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={3}>
          </Col>
        </Row>
      </Container>

    )
  }
}

const CurrenciesEditReduxForm = reduxForm({
  form: 'currenciesEditForm', // a unique identifier for this form
  validate,
  fields: ['name', 'rent_max_value', 'max_value'],
  enableReinitialize: true,
})(CurrenciesEditForm);

export default connect(
  null,
  {
    change,
  }
)(withTranslation('common')(CurrenciesEditReduxForm));