import React from 'react';
import {Col, Row, Card, CardBody, Button} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import ActionGroup from './ActionGroup';
import ProductGallery from "../../../../shared/components/ProductGallery";
import {currencyFormat, IMAGE_BASE_URL, currencySymbol, IMA} from '../../../../shared/helpers';


function createImageGalleryData(images) {
    let imageDatas = [];
    if (!!images) {
        images.forEach(img => {
            let thumbUrl = ""
            if (!!img.id && img.id.startsWith('video/')) {
                let subId = img.id.substring(6)
                let video = ('videos/').concat(subId)
                thumbUrl = IMAGE_BASE_URL + '/thumbnail/' + subId.slice(0, subId.lastIndexOf(".")) + '.png'
                imageDatas.push({src: IMAGE_BASE_URL.replace('images', video), ext: img.ext, thumbUrl})
            } else {
                thumbUrl = IMAGE_BASE_URL + '/small/' + img.id
                imageDatas.push({src: `${IMAGE_BASE_URL}/large/${img.id}`, thumbUrl})
            }
        });
    } else {
        imageDatas.push({src: ''});
    }

    return imageDatas;
}

function getDefaultPrice(prices, currencies) {
    let priceLable = "";

    prices.forEach(pri => {

        if (pri.is_default) {
            // priceLable = pri.price + " " + pri.currency_code.toUpperCase();
            priceLable = `${currencyFormat(pri.price)} ${currencySymbol(currencies, pri.currency_code)}`;
        }
    });

    return priceLable;
}

const PropertyHeader = (props) => {
    const {
        availability,
        galleries,
        title,
        address,
        prices,
        product,
        postOnInstagram,
        postOnFB,
        verified_status,
        verify_type,
        rejectProperty,
        changeStatusProperty,
        approveRenewalPayment,
        rejectRenewalPayment,
        publishProperty,
        publishWithoutPay,
        verifyProperty,
        currencies,
        confirmProperty,
        status,
        owner,
        isPublish
    } = props;

    return (
        <Card>
            <CardBody>
                <div>
                    <h3 className="page-title">{title}</h3>
                    <h5 className="property__detail__address"><span className="lnr lnr-map-marker"></span> {address}
                    </h5>
                </div>
                <div className="property__infor-img">
                    {galleries &&
                      <ProductGallery verify_type={verify_type} images={createImageGalleryData(galleries)}/>
                    }
                </div>

                <Row>
                    <Col xs={6} md={6} lg={6}>
                        <h3 className="property__detail__price">Price</h3>
                        <p className="property__detail__pricenumber">{getDefaultPrice(prices, currencies)}</p>
                    </Col>

                    <Col xs={6} md={6} lg={6}>
                        {(status !== 'ongoing') &&
                          <ActionGroup
                            postOnFB={postOnFB}
                            postOnInstagram={postOnInstagram}
                            verify_type={verify_type}
                            rejectProperty={rejectProperty}
                            verifyProperty={verifyProperty}
                            changeStatusProperty={changeStatusProperty}
                            approveRenewalPayment={approveRenewalPayment}
                            rejectRenewalPayment={rejectRenewalPayment}
                            verified_status={verified_status}
                            owner={owner}
                            availability={availability}
                            status={status}
                            product={product}
                            isPublish={isPublish}
                            publishProperty={publishProperty}
                            publishWithoutPay={publishWithoutPay}
                          />}
                            {
                              status === "closed" && <Button onClick={confirmProperty}
                              className="property__verification-btn"
                              color="info">Confirm</Button>
                            }
                    </Col>
                </Row>

            </CardBody>
        </Card>

    );
}


export default withTranslation('common')(PropertyHeader);
