
import {
    FETCH_SELLER_DETAIL_REQUEST,
    FETCH_SELLER_DETAIL_SUCCESS,
    FETCH_SELLER_DETAIL_ERROR,

    FETCH_PROPERTY_BY_SELLER_REQUEST,
    FETCH_PROPERTY_BY_SELLER_SUCCESS,
    FETCH_PROPERTY_BY_SELLER_ERROR,

    CHANGE_PAGE,
    CHANGE_FILTER_VALUE,
    CHANGE_ITEM_PER_PAGE
} from '../actions/fetchDataAction';

const initialState = {
    seller: null,
    showAgentList: false,
    showLoading: false,
    id: "",
    message: "",
    notificationMessage: "",
    notificationType: "",
    showAgentsModal: false,
    agents: [],
    // property
    result: { data: [], count: 0 },
    itemPerPage: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    status: { value: '', label: 'All' },
    fromDate: "",
    toDate: ""
}

const sellerReducer = function sellerReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SELLER_DETAIL_REQUEST:
            const result = {
                seller: null,
                showAgentList: false,
                message: "",
                notificationMessage: "",
                notificationType: "",
                showAgentsModal: false,
                showLoading: true,
                id: action.id,
                agents: [],
                result: { data: [], count: 0 },
                itemPerPage: 10,
                pageNumber: 0,
                rowsPerPage: 10,
                status: { value: '', label: 'All' },
                fromDate: "",
                toDate: ""
            };
            return result;

        case FETCH_SELLER_DETAIL_SUCCESS:
            return { ...state, showLoading: false, seller: action.seller };

        case FETCH_SELLER_DETAIL_ERROR:
            return { ...state, showLoading: false };

        case FETCH_PROPERTY_BY_SELLER_REQUEST:
            return { ...state, showLoading: true };

        case FETCH_PROPERTY_BY_SELLER_SUCCESS:

            return { ...state, showLoading: false, result: action.data };

        case FETCH_PROPERTY_BY_SELLER_ERROR:
            return { ...state, showLoading: false };

        case CHANGE_FILTER_VALUE:
            const { status, fromDate, toDate } = action.filtersData;
            return { ...state, status, fromDate, toDate }

        case CHANGE_PAGE:
            return { ...state, pageNumber: action.pageNumber }

        case CHANGE_ITEM_PER_PAGE:
            return { ...state, itemPerPage: action.itemPerPage }

        default:
            return state
    }
}

export default sellerReducer
