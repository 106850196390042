import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  // static propTypes = {
  //   title: PropTypes.string.isRequired,
  //   icon: PropTypes.string.isRequired,
  //   path: PropTypes.string.isRequired,
  // };

  render() {
    const { title, icon, path, onClick } = this.props;
    if(!path){
      return (
        <a className="topbar__link" onClick={onClick}>
          <span className={`topbar__link-icon lnr lnr-${icon}`} />
          <p className="topbar__link-title">{title}</p>
        </a>
      );
    }
    return (
      <Link className="topbar__link" to={path}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }
}
