import {
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
} from '../actions/loginAction';
import {
    LOGOUT_REQUESTING
} from '../actions/logoutAction'
const initialState = {
    requesting: false,
    successful: false,
    messages: '',
    errors: '',
}

const reducer = function loginReducer(state = initialState, action) {
    switch (action.type) {
        // Set the requesting flag and append a message to be shown
        case LOGIN_REQUESTING:
            return {
                requesting: true,
                successful: false,
                messages: 'Logging in...',
                errors: '',
            }

        // Successful?  Reset the login state.
        case LOGIN_SUCCESS:
            return {
                requesting: false,
                successful: true,
                messages: '',
                errors: '',
            }

        // Append the error returned from our api
        // set the success and requesting flags to false
        case LOGIN_ERROR:
            return {
                requesting: false,
                successful: false,
                messages: action.token.message ? action.token.message.toString(): '',
                errors: action.token.message ? action.token.message.toString(): '',
            }
        case LOGOUT_REQUESTING:
            return {
                requesting: false,
                successful: false,
                messages: '',
                errors: '',
            }

        default:
            return state
    }
}

export default reducer
