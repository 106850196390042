import * as CONSTANTS from './constants'; 

const handleListParameter = (params) => {
    return {
        type: CONSTANTS.PARAMETER_FETCH_REQUESTED,
        params
    }
}

const handleChangeTable = (data) => {
    return {
        type: CONSTANTS.HANDLE_CHANGE_TABLE,
        payload: data
    }
}

export {
    handleListParameter,
    handleChangeTable
} 

