import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import { PATH_URL, history } from "../../../shared/helpers/index";
import Loading from "../../../shared/components/Loading";
import {
  changeFilterValue,
  onSortExploreSection,
  handleChangeTable,
  handleListExploreSection,
  changeItemPage,
  changePage,
  changeDefaultPage,
  handleDeleteExploreSection,
  resetState
} from "./redux/actions/exploreSectionAction";
import MatTable from "../../../shared/components/table/components/MatTable";
import Filter from "./components/Filter";
import Confirm from "../../../shared/components/Confirm";

class ExploreSections extends PureComponent {
  constructor() {
    super();

    this.state = {
      showConfirm: false,
      selectExploreSection: null,
      editData: false
    };
  }

  componentDidMount() {
    this.props.resetState();
    const params = { pageNumber: 0, itemPerPage: 10 };

    this.props.changeFilterValue({
      status: { value: "", label: "All" },
      type: { value: "", label: "All" }
    });

    this.props.handleListExploreSection(params);
  }

  toggleConfirm = () => {
    this.setState({ showConfirm: false });
  };

  confirmDelete = () => {
    this.props.handleDeleteExploreSection(this.state.selectExploreSection);
    this.setState({ showConfirm: false });
  };

  onClickRow = rowData => {
    if (rowData && rowData._id) {
      this.setState({ editData: true }, () => {
        const { editData } = this.state;
        history.push({
          pathname: PATH_URL.EXPLORE_SECTIONS + "/" + rowData._id,
          state: { data: editData }
        });
      });
    }
  };

  onChangeRowsPerPage = event => {
    this.props.changeItemPage(event.target.value);
  };

  onPageChange = page => {
    this.props.changePage(page);
  };

  onSort = value => {
    this.props.onSortExploreSection(value);
  };

  buildDataForList(items) {
    let buildData = [];
    items.forEach(element => {
      element.assigned_properties_count = element.assigned_properties.length;
      buildData.push({ element });
    });
    return buildData;
  }

  buildHeaderForList = () => {
    return ["Title", "Type", "Order", "Status", "Add Time", "Update Time"];
  };

  buildColumnForList = () => {
    return ["title", "type", "order", "status", "add_time", "upd_time"];
  };

  handleFilterListExploreSection = () => {
    this.props.changeDefaultPage();
    this.props.handleListExploreSection();
  };

  render() {
    const {
      exploreSectionData,
      itemPerPage,
      loading,
      count,
      pageNumber,
      isAsign,
      t
    } = this.props;

    return (
      <Container className="ambassador">
        <Confirm
          toggle={this.toggleConfirm}
          isOpen={this.state.showConfirm}
          title={"Confirm"}
          message={"Are you sure to delete this explore section?"}
          confirm={this.confirmDelete}
        />
        <Row>
          <h3 className="page-title">{t("explore_sections.page_title")}</h3>
        </Row>
        <Row>
          <Link
            className="btn btn-primary btn-sm"
            to={PATH_URL.EXPLORE_SECTIONS_CREATE}
          >
            {t("explore_sections.create_explore_sections")}
          </Link>
        </Row>
        <Loading isOpen={loading} />
        <Filter
          changeFilterValue={this.props.changeFilterValue}
          onFilter={this.handleFilterListExploreSection}
          data={this.props}
          isAsign={isAsign}
        />

        <Row>
          {exploreSectionData.length > 0 ? (
            <MatTable
              data={exploreSectionData}
              count={count}
              currentPage={pageNumber}
              onPageChange={this.onPageChange}
              rowsPerPage={itemPerPage}
              column={this.buildColumnForList()}
              header={this.buildHeaderForList()}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              // onRowsPerPageChange={this.onChangeRowsPerPage}
              onClickRow={this.onClickRow}
              showId={true}
              onShort={this.onSort}
              actionColumnCustom={[
                {
                  title: "Delete",
                  color: "danger",
                  action: id => {
                    this.setState({
                      selectExploreSection: id,
                      showConfirm: true
                    });
                  }
                }
              ]}
            />
          ) : (
            <span>No Data</span>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.exploreSectionReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleListExploreSection: params => {
      dispatch(handleListExploreSection(params));
    },
    handleDeleteExploreSection: id => {
      dispatch(handleDeleteExploreSection(id));
    },
    handleChangeTable: data => {
      dispatch(handleChangeTable(data));
    },
    changeItemPage: number => {
      dispatch(changeItemPage(number));
    },
    changePage: page => {
      dispatch(changePage(page));
    },
    onSortExploreSection: value => {
      dispatch(onSortExploreSection(value));
    },
    changeFilterValue: data => {
      dispatch(changeFilterValue(data));
    },
    changeDefaultPage: () => {
      dispatch(changeDefaultPage());
    },
    resetState: () => {
      dispatch(resetState());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ExploreSections));
