import * as CONSTANTS from "./constants";

const fetchListCurrency = () => {
  return {
    type: CONSTANTS.FETCH_CURRENCY_REQUEST
  };
};

const changePage = pageNumber => {
  return {
    type: CONSTANTS.CHANGE_PAGE,
    pageNumber: pageNumber
  };
};
const changeItemPerPage = ({ itemPerPage }) => {
  return {
    type: CONSTANTS.CHANGE_ITEM_PER_PAGE,
    itemPerPage
  };
};

const removeCurrency = data => {
  return {
    type: CONSTANTS.REMOVE_CURRENCY,
    data
  };
};

export { fetchListCurrency, changePage, changeItemPerPage, removeCurrency };
