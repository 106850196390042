
import * as CONSTANTS from '../actions/constants';

const initialState = {
    item: null,
    loading: false,
    error: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
      case CONSTANTS.CREATE_LOADING:
      case CONSTANTS.PARAMETER_CREATE_FETCH_REQUESTED:
        return { ...state, loading: true };
      case CONSTANTS.PARAMETER_CREATE_FETCH_SUCCEEDED:
        handleData(action.payload);
        return { item: action.payload, loading: false, error: false };
      case CONSTANTS.PARAMETER_CREATE_FETCH_FAILED: 
        return { ...state, loading: false, error: true };
      default:
        return state;
    }
  }
  
const handleData = item => {
  const itemsStorage = JSON.parse(localStorage.getItem('items')) || [];
  item.id = itemsStorage.length + 1;
  item.status = 'Actived';
  const items = [...itemsStorage, item];
  localStorage.setItem('items', JSON.stringify(items));
}