export const REJECT_PROPERTY = 'REJECT_PROPERTY';
export const REJECT_PROPERTY_SUCCESS = 'REJECT_PROPERTY_SUCCESS';
export const REJECT_PROPERTY_ERROR = 'REJECT_PROPERTY_ERROR';

export const CHANGE_STATUS_PROPERTY = 'CHANGE_STATUS_PROPERTY';
export const CHANGE_STATUS_PROPERTY_SUCCESS = 'CHANGE_STATUS_PROPERTY_SUCCESS';
export const CHANGE_STATUS_PROPERTY_ERROR = 'CHANGE_STATUS_PROPERTY_ERROR';

export const POST_ON_FACEBOOK = 'POST_ON_FACEBOOK';
export const POST_ON_FACEBOOK_SUCCESS = 'POST_ON_FACEBOOK_SUCCESS';
export const POST_ON_FACEBOOK_ERROR = 'POST_ON_FACEBOOK_ERROR';

export const POST_ON_INSTAGRAM = 'POST_ON_INSTAGRAM';
export const POST_ON_INSTAGRAM_SUCCESS = 'POST_ON_INSTAGRAM_SUCCESS';
export const POST_ON_INSTAGRAM_ERROR = 'POST_ON_INSTAGRAM_ERROR';

export const REJECT_RENEWAL_PAYMENT = 'REJECT_RENEWAL_PAYMENT';
export const REJECT_RENEWAL_PAYMENT_SUCCESS = 'REJECT_RENEWAL_PAYMENT_SUCCESS';
export const REJECT_RENEWAL_PAYMENT_ERROR = 'REJECT_RENEWAL_PAYMENT_ERROR';

export const APPROVE_RENEWAL_PAYMENT = 'APPROVE_RENEWAL_PAYMENT';
export const APPROVE_RENEWAL_PAYMENT_REQUEST = 'APPROVE_RENEWAL_PAYMENT_REQUEST';
export const APPROVE_RENEWAL_PAYMENT_SUCCESS = 'APPROVE_RENEWAL_PAYMENT_SUCCESS';
export const APPROVE_RENEWAL_PAYMENT_ERROR = 'APPROVE_RENEWAL_PAYMENT_ERROR';

export const VERIFY_PROPERTY = 'VERIFY_PROPERTY';
export const VERIFY_PROPERTY_SUCCESS = 'VERIFY_PROPERTY_SUCCESS';
export const VERIFY_PROPERTY_ERROR = 'VERIFY_PROPERTY_ERROR';
export const TOGLE_NOTIFICATON = 'TOGLE_NOTIFICATON';
export const CONFIRM_PROPERTY = 'CONFIRM_PROPERTY';
export const CONFIRM_PROPERTY_SUCCESS = 'CONFIRM_PROPERTY_SUCCESS';

export const PUBLISH_PROPERTY_DETAIL_REQUEST = 'PUBLISH_PROPERTY_DETAIL_REQUEST';
export const PUBLISH_PROPERTY_DETAIL_WITHOUT_PAY_REQUEST = 'PUBLISH_PROPERTY_DETAIL_WITHOUT_PAY_REQUEST';

const rejectProperty = function rejectProperty(message, isPaid) {
  return {
    type: REJECT_PROPERTY,
    message,
    isPaid
  }
}

const postOnInstagram = (message) => {
  return {
    type: POST_ON_INSTAGRAM,
    message
  }
}

const postOnFB = (message) => {
  return {
    type: POST_ON_FACEBOOK,
    message
  }
}

const changeStatusProperty = function changeStatusProperty() {
  return {
    type: CHANGE_STATUS_PROPERTY,
  }
}

const approveRenewalPaymentRequest = function approveRenewalPaymentRequest() {
  return {
    type: APPROVE_RENEWAL_PAYMENT_REQUEST,
  }
}

const rejectRenewalPayment = function rejectRenewalPayment(message) {
  return {
    type: REJECT_RENEWAL_PAYMENT,
    message
  }
}

const approveRenewalPayment = function approveRenewalPayment() {
  return {
    type: APPROVE_RENEWAL_PAYMENT,
  }
}

const confirmProperty = function confirmProperty(message) {
  return {
    type: CONFIRM_PROPERTY,
    message
  }
}

const rejectPropertySuccess = function rejectPropertySuccess() {
  return {
    type: REJECT_PROPERTY_SUCCESS
  }
}

const rejectPropertyError = function rejectPropertyError(error) {
  return {
    type: REJECT_PROPERTY_ERROR,
    error
  }
}

const verifyProperty = function verifyProperty() {
  return {
    type: VERIFY_PROPERTY
  }
}

const verifyPropertySuccess = function verifyPropertySuccess() {
  return {
    type: VERIFY_PROPERTY_SUCCESS
  }
}

const verifyPropertyError = function verifyPropertyError(error) {
  return {
    type: VERIFY_PROPERTY_ERROR,
    error
  }
}

const toggleNotification = function toggleNotification() {
  return {
    type: TOGLE_NOTIFICATON
  }
}

const publishProperty = function publishProperty() {
  return {
    type: PUBLISH_PROPERTY_DETAIL_REQUEST,
  }
}

const publishWithoutPay = function publishWithoutPay() {
  return {
    type: PUBLISH_PROPERTY_DETAIL_WITHOUT_PAY_REQUEST,
  }
}

export {
  rejectProperty,
  postOnInstagram,
  postOnFB,
  changeStatusProperty,
  approveRenewalPaymentRequest,
  approveRenewalPayment,
  rejectRenewalPayment,
  rejectPropertySuccess,
  rejectPropertyError,
  verifyProperty,
  verifyPropertySuccess,
  verifyPropertyError,
  toggleNotification,
  confirmProperty,
  publishProperty,
  publishWithoutPay
}