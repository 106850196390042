import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_ERROR,
  CHANGE_FILTER_VALUE,
  SET_PUBLISH_TYPE,
  CHANGE_ITEM_PER_PAGE,
  CHANGE_PAGE,
  ON_SHORT_PROPERTIES,
  CHANGE_DEFAULT_PAGE,
  RESET_STATE,
} from "../actions/index";

const initialState = {
  result: { data: [], count: 0 },
  itemPerPage: 10,
  pageNumber: 0,
  showLoading: false,
  rowsPerPage: 10,
  status: { value: "", label: "All" },
  fromDate: "",
  toDate: "",
  name: "",
  sort: "",
  desc: 0,
};

const propertyReducer = function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_REQUEST:
      const result = { ...state, showLoading: true };
      return result;

    case FETCH_PROPERTY_SUCCESS:
      return { ...state, showLoading: false, result: action.data };

    case FETCH_PROPERTY_ERROR:
      return { ...state, showLoading: false };

    case SET_PUBLISH_TYPE:
      const { paymentStatus } = action.publishType;
      if (paymentStatus === null) {
        const newState = { ...state, paymentStatus };
        return { ...newState };
      }
      return { ...state, paymentStatus };

    case CHANGE_FILTER_VALUE:
      const { status, fromDate, toDate, name } = action.filtersData;

      return {
        ...state,
        status,
        fromDate: fromDate === "Invalid date" ? "" : fromDate,
        toDate: toDate === "Invalid date" ? "" : toDate,
        name,
      };

    case CHANGE_DEFAULT_PAGE:
    case CHANGE_PAGE:
      return { ...state, pageNumber: action.pageNumber };

    case CHANGE_ITEM_PER_PAGE:
      return { ...state, itemPerPage: action.itemPerPage };

    case ON_SHORT_PROPERTIES:
      return {
        ...state,
        sort: action.value.col,
        desc: action.value.value === "desc" ? -1 : 0,
      };

    case RESET_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default propertyReducer;
