import * as CONSTANTS from './constants'; 


const handleCreateParameter = (item) => {
    return {
        type: CONSTANTS.PARAMETER_CREATE_FETCH_REQUESTED,
        item,
    }
}

export {
    handleCreateParameter
} 

