import { setClient } from '../../Company/LogIn/redux/actions/clientAction';

export function checkAuthorization (dispatch) {
  const token = localStorage.getItem('token')
  const tokenUA = localStorage.getItem('token_UA')
  if (token) {
    //TODO
    // const createdDate = new Date(token.created)
    // const created = Math.round(createdDate.getTime() / 1000)
    // const ttl = 1209600
    // const expiry = created + ttl
    // if (created > expiry) return false
    dispatch(setClient(token, tokenUA))
    return true
  }
  return false
}
