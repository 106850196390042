import React, { useMemo, useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  selectChatRoom,
  getListChatRoom,
  getListChatMessage,
  appendNewMessageToCurrentRoom,
} from "../redux/actions/chatAction";
import {
  initializePush,
  onMessagesRef,
  offMessagesRef,
} from "../../../../shared/firebase/fcm";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    fontSize: "15px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ChatRoomItem = ({
  data,
  selectChatRoom,
  getListChatRoom,
  getListChatMessage,
  appendNewMessageToCurrentRoom,
}) => {
  
  return (
    <div className="flex-column blocks">
      <HtmlTooltip
        title={
          <React.Fragment>
            <span>{data.name}</span>
          </React.Fragment>
        }
      >
        <header className="name">
          {data.name} {` - Created on : `}
          {moment(data.createTime).format("MMM DD, YYYY hh:mm:ss A")}
        </header>
      </HtmlTooltip>
      {data &&
        data.users &&
        data.users.length > 0 &&
        data.users.map((item) => {
          const roomtypes = item.roomType.split("_");
          const capitals = [];
          roomtypes.forEach((element) => {
            capitals.push(element.charAt(0).toUpperCase() + element.slice(1));
          });
          const roomtype = capitals.join(" ");
          let roomName = roomtype;
          let timeStamp = item.timeStamp
            ? " - " + moment(item.timeStamp).format("MMM DD, YYYY hh:mm:ss A")
            : "";
          switch (roomtype) {
            case "Deal Price":
              roomName +=
                " : Buyer- " +
                item.buyerFirstName +
                " " +
                item.buyerLastName +
                " / Owner-" +
                item.ownerFirstName +
                " " +
                item.ownerLastName +
                timeStamp;
              break;
            case "Company Chat":
              if (item.role === "user" || item.role === "user_role")
                roomName +=
                  " : Buyer-" +
                  item.buyerFirstName +
                  " " +
                  item.buyerLastName +
                  timeStamp;
              else
                roomName +=
                  " : Owner-" +
                  item.ownerFirstName +
                  " " +
                  item.ownerLastName +
                  timeStamp;
              break;
            case "Book View":
              roomName +=
                " : Ambassador-" +
                item.agentFistName +
                " " +
                item.agentLastName +
                " / Buyer- " +
                item.buyerFirstName +
                " " +
                item.buyerLastName +
                timeStamp;
              break;
            default:
              break;
          }
          return (
            <div
              key={item.roomId}
              onClick={() => {
                selectChatRoom(
                  item.roomId,
                  item.propertyId,
                  item.ownerId,
                  data.id,
                  item.roomType
                );
                getListChatMessage(
                  item.roomId,
                  item.propertyId,
                  item.ownerId,
                  data.id,
                  item.roomType
                );
              }}
              className="flex-row user-info-area"
            >
              <span className="property-indicator property-indicator-online"></span>
              <h5 className="title">{roomName}</h5>
              {item.totalMessage != 0 && (
                <span className="badge badge-danger">{item.totalMessage}</span>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.chatReducer,
  };
};

export default connect(
  mapStateToProps,
  {
    selectChatRoom,
    getListChatRoom,
    getListChatMessage,
    appendNewMessageToCurrentRoom,
  }
)(withTranslation("common")(ChatRoomItem));
