import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index';

const fetchEmailTemplate = (pageNumber, itemPerPage) => {
    return apiCaller(API_URL.EMAIL_TEMPLATE_GET, HTTP_METHOD.GET, null, {
        pageNumber,
        itemPerPage,
    });
}

const deleteEmailTemplateAPI = (id) => {
    return apiCaller(`${API_URL.EMAIL_TEMPLATE_DELETE}/${id}`, HTTP_METHOD.PUT, null, null);
}

export { fetchEmailTemplate, deleteEmailTemplateAPI };