import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw, faGraduationCap, faWifi, faCity, faStore, faBed, faHotTub, faRestroom, faWarehouse, faBuilding, faBus, faSwimmer, faSwimmingPool, faParking, faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { FACILITIES_ARR } from '../../../../shared/helpers/constants'

const FacilityItem = ({ name, value, icon }) => (
    <Row className="key__infor__row">
        <Col xs={6} md={6} lg={6}>
            <FontAwesomeIcon className="key__infor_name" icon={icon} style={{ marginRight: "10px" }} />
            <span className="key__infor_name">{name}</span>
        </Col>
        <Col xs={6} md={6} lg={6}>
            <span className="key__infor_value">{value ? value : ''}</span>
        </Col>
    </Row>
);

const FacilityIcon = ({ icon, name }) => (
    <Col className='key__info__icon_warpper' xs={3} md={3} lg={3}>
        <FontAwesomeIcon className="key__infor__icon" icon={icon} />
        <span className="key__infor__name">{name}</span>
    </Col>
);

const renderFacilities = (data) => {

    let result = data.map((item, index) => {
        return <FacilityItem key={index} name={item.name} value={item.value} icon={item.icon} />;
    });
    return result;
}

const renderFacilityIcon = (data) => {
    let result = data.map((item, index) => {
        return <FacilityIcon key={index} icon={item.icon} name={item.name} />;
    });
    return result;
}


const Facilities = (props) => {

    const { bathrooms,
        bedrooms,
        toilets,
        furnitures,
        floors,
        facilities } = props;

    let icons = [];
    let sortedArr = []

    if(facilities) {
        FACILITIES_ARR.map(facItem => {
            const index = facilities.findIndex(item => item.name === facItem)
            if(index > -1) {
                sortedArr.push(facItem)
            }
        })
    }

    !!sortedArr && sortedArr.forEach(element => {
        switch (element) {
            case "near_super_market":
                icons.push({ icon: faStore, name: "Near Supert Market" })
                break;
            case "wifi":
                icons.push({ icon: faWifi, name: "Wifi" })
                break;
            case "allow_pets":
                icons.push({ icon: faPaw, name: "Allow Pets" })
                break;
            case "finish_construction":
                icons.push({ icon: faCity, name: "Finished construction" })
                break;
            case "near_school":
                icons.push({ icon: faGraduationCap, name: "Near School" })
                break;
            case "swimming_pool":
                icons.push({ icon: faSwimmer, name: 'Swimming Pool' })
                break;
            case "fitness_facility":
                icons.push({ icon: faDumbbell, name: 'Fitness facility' })
                break;
            case "parking_space":
                icons.push({ icon: faParking, name: 'Parking space' })
                break;
            case "near_public_transport":
                icons.push({ icon: faBus, name: 'Near public transport' })
                break;
            default:
                break;
        }
    });

    let facilitiesData = [];
    if (Array.isArray(bedrooms) && bedrooms.length > 0) {
        facilitiesData.push({ icon: faBed, name: "Bedroom", value: bedrooms[0].total > 4 ? '4 more' : `${bedrooms[0].total}` })
    } else {
        facilitiesData.push({ icon: faBed, name: "Bedroom", value: !!bedrooms ? bedrooms.total > 4 ? '4 more' : `${bedrooms.total}` : '' })
    }

    if (Array.isArray(bathrooms) && bathrooms.length > 0) {
        const noStubs = bathrooms[0].no_of_tubs > 0 ? 'with tubs' : 'without tubs'
        facilitiesData.push({ icon: faHotTub, name: "Bathrooms", value: bathrooms[0].total > 4 ? `4 more ${noStubs}` : `${bathrooms[0].total} ${noStubs}` })
    } else {
        const noStubs = (!!bathrooms && bathrooms.no_of_tubs > 0) ? 'with tubs' : 'without tubs'
        facilitiesData.push({ icon: faHotTub, name: "Bathrooms", value: !!bathrooms ? (bathrooms.total > 4 ? `4 more ${noStubs}` : `${bathrooms.total} ${noStubs}`) : '' })
    }

    if (Array.isArray(toilets) && toilets.length > 0) {
        facilitiesData.push({ icon: faRestroom, name: "Toilet", value: toilets[0].total > 4 ? '4 more' : `${toilets[0].total}` })
    } else {
        facilitiesData.push({ icon: faRestroom, name: "Toilet", value: !!toilets ? (toilets.total > 4 ? '4 more' : `${toilets.total}`) : '' })
    }

    if (Array.isArray(furnitures) && furnitures.length > 0) {
        const stoveTypes = furnitures.map(f => {
            switch (f.type) {
                case "gas":
                    return "Gas";
                case "electric":
                    return "Electric Smooth-Top";
                case "induction":
                    return "Induction";
                case "coil":
                    return "Coil";
                case "modular":
                    return "Modular/Custom";
                default:
                    return f.type;
            }
        })
        facilitiesData.push({ icon: faWarehouse, name: "Stove type", value: `${stoveTypes ? stoveTypes.join(", ") : stoveTypes}` })
    } else {
        facilitiesData.push({ icon: faWarehouse, name: "Stove type", value: !!furnitures ? `${furnitures.type ? furnitures.type : ''}` : '' })
    }

    // if (Array.isArray(floors) && floors.length > 0) {
    //     facilitiesData.push({ icon: faBuilding, name: "Floor", value: `${floors[0].total}` })
    // } else {
    //     facilitiesData.push({ icon: faBuilding, name: "Floor", value: `${floors.total}` })
    // }


    return (
        <Card>
            <CardBody>
                <div>
                    <h3 className="page-title">Facilities</h3>
                    <hr />

                    <div>
                        {renderFacilities(facilitiesData)}
                        <Row className="key__infor__row facility">
                            {renderFacilityIcon(icons)}
                        </Row>
                    </div>
                </div>

            </CardBody>
        </Card>
    );


}


export default withTranslation('common')(Facilities);
