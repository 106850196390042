
import * as CONSTANTS from '../actions/constants';

const initialState = {
  result: { data: [], count: 0 },
  error: false,
  loading: false,
  generalValue: null,
  packages: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.LOADING:
      return { ...state, loading: true };
    case CONSTANTS.FETCH_TRANSACTION_FEE_REQUEST:
      return { ...state };
    case CONSTANTS.FETCH_TRANSACTION_FEE_SUCCESS:
      return { ...state, result: action.data, loading: false };
    case CONSTANTS.FETCH_TRANSACTION_FEE_FAILURE:
      return { ...state, error: true, loading: false };
    case CONSTANTS.CHANGE_TRANSACTION_FEE:
      return { ...state };
    case CONSTANTS.GET_GENERAL_VALUE_SUCCESS:
      return { ...state, generalValue: action.data };
    case CONSTANTS.GET_PACKAGES_SUCCESS: 
      return { ...state, packages: action.data }
    default:
      return state;
  }
}

