import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { styled } from '@material-ui/styles';

class ModalComponent extends PureComponent {

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
      message: null
    };

    this.toggle = this.toggle.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
  }

  changeMessage(event) {
    this.setState({ message: event.target.value });
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const {
      color, btn, title, message, colored, header, optionClass, withTextMessage,
      confirmAction, renewal, isPaid, logoIcon
    } = this.props;

    const divStyle = {
      backgroundImage: `url('https://static1.bigstockphoto.com/8/6/3/large1500/368557969.jpg')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left',
    };

    const { modal } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div className={optionClass}>
        <Button
          color={color} onClick={this.toggle} size="sm">
          {btn}
        </Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
            {withTextMessage && <div>
              <textarea rows="4" cols="40" onChange={this.changeMessage}>
              </textarea>
            </div>}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}
            <Button className="modal_ok" outline={colored} color={color} onClick={() => {
              this.toggle();
              if (confirmAction) {
                if (withTextMessage) {
                  confirmAction(this.state.message, isPaid);
                } else {
                  confirmAction();
                }

                // else if (withTextMessage && renewal === false) confirmAction(this.state.message, renewal);
                // else if (renewal === true) confirmAction(renewal);
              }

            }}>Ok</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
