import * as ACTIONS from '../actions';

const initialState = {
    loading: false,
    emailTemplateData: [],
    notificationType: '',
    notificationMessage: '',
    emailTypes: []
}

function emailTemplateCreateReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.EMAIL_TEMPLATE_CREATE_REQUESTED:
            return { ...state, loading: true };
        case ACTIONS.EMAIL_TEMPLATE_CREATE_SUCCEEDED:
            const { data } = action.payload;
            return { ...state, emailTemplateData: data, loading: false };
        case ACTIONS.EMAIL_TEMPLATE_CREATE_FAILED:
            return { ...state, loading: false };
        case ACTIONS.LOAD_EMAIL_TYPES:
            return { ...state, loading: true };

        case ACTIONS.LOAD_EMAIL_SUCCESS: 
            const types  = action.data.data;
            return { ...state, emailTypes: types, loading: false };
        default:
        return state;
    }
}

export { emailTemplateCreateReducer }