import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, Form } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import { Alert } from 'reactstrap';
import loginRequest from './redux/actions/loginAction';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { PATH_URL, history } from '../../../shared/helpers/index';
import Loading from '../../../shared/components/Loading';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      isError: false
    };
  }

  componentDidMount(){
    const rememberMe = localStorage.getItem('remember_me')
    const token = localStorage.getItem('token')
    const tokenUA = localStorage.getItem('token_UA')
    if(rememberMe && token && tokenUA){
      history.push(PATH_URL.DASHBOARD)
    }
  }
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  submit = (values) => {
    if ((values.username === undefined || values.username.trim() === '') || ( values.password === undefined || values.password.trim() === '')) {
      this.setState({
        isError: true
      })
    } else
      this.props.loginRequest(values);
  }

  render() {
    const { 
      t, 
      handleSubmit, 
      errors,
      requesting
    } = this.props;

    const { showPassword, isError } = this.state;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            {/* <div className="account__head">
              <h3 className="account__title">{t('Welcome to')}
                <span className="account__logo"> Real
                  <span className="account__logo-accent">Estate</span>
                </span>
              </h3>
              <h4 className="account__subhead subhead">Start your business easily</h4>
            </div> */}
            <Form className="form" onSubmit={handleSubmit(this.submit)}>
              <Alert
                color="danger"
                isOpen={!!errors}
              >
                {errors}
              </Alert>
              <Alert
                color="danger"
                isOpen={isError}
              >
                {"Username and Password cannot be empty!"}
              </Alert>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('home.username')}</span>
                <div className="form__form-group-field">
                  <span className="form__form-group-icon lnr lnr-user"></span>
                  <Field
                    name="username"
                    component="input"
                    type="text"
                    placeholder={t('home.username')}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('home.password')}</span>
                <div className="form__form-group-field">
                  {/* <div className="form__form-group-icon"> */}
                  <span className="form__form-group-icon lnr lnr-lock"></span>
                    {/* <KeyVariantIcon /> */}
                  {/* </div> */}
                  <Field
                    name="password"
                    component="input"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('home.password')}
                  />
                  <button
                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                    onClick={e => this.showPassword(e)}
                    type="button"
                  ><EyeIcon />
                  </button>
                </div>
                {/* <div className="account__forgot-password">
                  <a href={PATH_URL.RESET}>{t('home.forgot')}</a>
                </div> */}
              </div>
              {/* <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="remember_me"
                    component={renderCheckBoxField}
                    label={t('home.remember')}
                  />
                </div>
              </div> */}
              <button className="btn btn-primary account__btn account__btn--small" 
                action="submit">{t('home.login')} 
              </button>
            </Form>
          </div>
        </div>
        <div className="loadingContainer">
          <Loading isOpen={requesting} />
        </div>
      </div>
    )
  }
}

Login.defaultProps = {
  requesting: false,
  successful: false,
  messages: '',
  errors: '',
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  loginRequest: PropTypes.func,
  requesting: PropTypes.bool,
  successful: PropTypes.bool,
  messages: PropTypes.string,
  errors: PropTypes.string,
}

const mapStateToProps = (state) => {
  return state.loginReducer;
};

const connected = connect(mapStateToProps, { loginRequest })(withTranslation('common')(Login))

// in our Redux's state, this form will be available in 'form.login'
const formed = reduxForm({  
  form: 'login-form',
})(connected)

// Export our well formed login component
export default formed
