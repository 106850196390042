import {
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_ERROR,
  CHANGE_PAGE,
  CHANGE_ITEM_PER_PAGE,
  ACTIVE_USER,
  DEACTIVE_USER,
  ACTIVE_RESULT,
  ON_SHORT_ACCOUNT,
  CHANGE_FILTER_VALUE,
  CHANGE_DEFAULT_PAGE,
  RESET_STATE
} from "../actions/index";

const initialState = {
  result: { data: [], count: 0 },
  itemPerPage: 10,
  pageNumber: 0,
  showLoading: false,
  rowsPerPage: 10,
  fromDate: "",
  toDate: "",
  sort: "",
  desc: 0,
  email: "",

  status: { value: "", label: "All" }
};

const accountsReducer = function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST:
      const result = { ...state, showLoading: true };
      return result;

    case FETCH_ACCOUNTS_SUCCESS:
      return { ...state, showLoading: false, result: action.data };

    case FETCH_ACCOUNTS_ERROR:
      return { ...state, showLoading: false };

    case CHANGE_DEFAULT_PAGE:
    case CHANGE_PAGE:
      return { ...state, pageNumber: action.pageNumber };
    case CHANGE_ITEM_PER_PAGE:
      return { ...state, itemPerPage: action.itemPerPage };

    case ACTIVE_USER:
    case DEACTIVE_USER:
      return { ...state, showLoading: true };

    case ACTIVE_RESULT:
      let rs = state.result;
      rs.data = action.data;
      return { ...state, result: rs, showLoading: false };

    case ON_SHORT_ACCOUNT:
      return {
        ...state,
        sort: action.value.col,
        desc: action.value.value === "desc" ? -1 : 0
      };
    case CHANGE_FILTER_VALUE:
      const { email, status } = action.filtersData;

      return {
        ...state,
        email: email !== undefined ? email : state.email,
        status: status !== undefined ? status : state.status
      };

    case RESET_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default accountsReducer;
