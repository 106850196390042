import { all } from "redux-saga/effects";

import { loginWatcher, logoutWatcher } from "../../Company/LogIn/redux/sagas";
import parameterWatcher from "../../Company/Parameters/redux/sagas";
import createParameterWatcher from "../../Company/ParametersCreate/redux/sagas";
import detailParameterWatcher from "../../Company/ParametersDetail/redux/sagas";
import propertyWatcher from "../../Company/Properties/redux/sagas";
import sellerWatcher from "../../Company/Sellers/redux/sagas";
import sellerDetailWatcher from "../../Company/SellersDetail/redux/sagas";
import ambassadorWatcher from "../../Company/Ambassadors/sagas";
import exploreSectionsWatcher from "../../Company/ExploreSections/sagas";
import createExploreSectionWatcher from "../../Company/ExploreSectionsCreate/sagas";
import ambassadorDetailWatcher from "../../Company/AmbassadorDetail/sagas";
import exploreSectionDetailWatcher from "../../Company/ExploreSectionsDetail/sagas";
import propertyDetailWatcher from "../../Company/PropertyDetail/redux/sagas";
import dashboardSaga from "../../Company/Dashboard/redux/sagas";
import currencyWatcher from "../../Company/Currencies/redux/sagas";
import transactionFeeWatcher from "../../Company/SettingTransactionFee/redux/sagas";
import createcurrenciesWatcher from "../../Company/CurrenciesCreate/redux/sagas";
import editCurrenciesWatcher from "../../Company/CurrenciesEdit/redux/sagas";
import createAmbassadorWatcher from "../../Company/Agents/sagas/createAmbassadorSaga";
import accountsWatcher from "../../Company/Accounts/redux/sagas";
import { chatWatcher } from "../../Company/Chats/sagas";
import paymentWatcher from "../../Company/Payment/redux/sagas";
import emailTemplateWatcher from "../../Company/EmailTemplate/sagas";
import emailTemplateDetailWatcher from "../../Company/EmailTemplateDetail/sagas";
import emailTemplateCreateWatcher from "../../Company/EmailTemplateCreate/sagas";

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    logoutWatcher(),
    parameterWatcher(),
    createParameterWatcher(),
    detailParameterWatcher(),
    propertyWatcher(),
    ambassadorWatcher(),
    exploreSectionsWatcher(),
    createExploreSectionWatcher(),
    ambassadorDetailWatcher(),
    exploreSectionDetailWatcher(),
    propertyDetailWatcher(),
    dashboardSaga(),
    createcurrenciesWatcher(),
    editCurrenciesWatcher(),
    currencyWatcher(),
    transactionFeeWatcher(),
    createAmbassadorWatcher(),
    accountsWatcher(),
    sellerWatcher(),
    sellerDetailWatcher(),
    chatWatcher(),
    paymentWatcher(),
    emailTemplateWatcher(),
    emailTemplateDetailWatcher(),
    emailTemplateCreateWatcher()
  ]);
}
