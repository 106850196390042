import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'

const fetchData = () => {

    return apiCaller(API_URL.CURRENCY + '/currency_params', HTTP_METHOD.GET, null)
}

const createCurrency = (data) => {
   
    return apiCaller(API_URL.CURRENCY + `/add`, HTTP_METHOD.POST, data)
}

const formatData = (data) => {
    const formatData = data.map(element => ({
        ...element,
        value: element.code,
        label: element.name
    }));

    return formatData;
}

export { createCurrency, fetchData, formatData }; 