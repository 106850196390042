import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { withRouter } from 'react-router-dom';

import CurrenciesEditForm from './components';
import { handleEditCurrencies, fetchListCurrencyParams, fetchCurrencyDetail } from './redux/actions';
import { PATH_URL } from '../../../shared/helpers/path';

class CurrenciesEdit extends Component {
  componentDidMount() {
    const { match: { params }, fetchCurrencyDetail } = this.props;
    const { id } = params;
    fetchCurrencyDetail(id);
  }
  handleSubmit = event => {
    event.preventDefault();
    const { handleEditCurrencies, errors, form } = this.props;

    if (errors && Object.keys(errors).length === 0) {
      if (form && form.currenciesEditForm && form.currenciesEditForm.values && !form.currenciesEditForm.syncErrors) {
        let data = { ...form.currenciesEditForm.values};
        handleEditCurrencies(data);
      }
    }
  }

  handleBack = event => {
    event.preventDefault();

    const { history } = this.props;
    history.push(PATH_URL.CURRENCIES);
  }

  render() {
    const { currenciesEdit, initialValues } = this.props;

    return (
      <CurrenciesEditForm
        handleBack={this.handleBack}
        currenciesEdit={currenciesEdit}
        handleSubmit={this.handleSubmit}
        initialValues={initialValues} />
    )
  }
}

const mapStateToProps = state => {
  return {
    currenciesEdit: state.currentiesEditReducer,
    form: state.form,
    initialValues: state.currentiesEditReducer.item,
    errors: getFormSyncErrors("currenciesEditForm")(state),
  }
}
const mapDispatchToProps = dispatch => {
  return {
    handleEditCurrencies: (data) => { dispatch(handleEditCurrencies(data)) },
    fetchListCurrencyParams: () => { dispatch(fetchListCurrencyParams()) },
    fetchCurrencyDetail: (id) => { dispatch(fetchCurrencyDetail(id)) },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrenciesEdit));