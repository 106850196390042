import { call, put, takeLatest, cancelled, select } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions/ambassadorDetailAction';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import {
  fetchDetailAmbassador,
  formatAmbassdor,
  deactiveAmbassador,
  activeAmbassador,
  takeOver,
  asignAgent
} from '../services/index';
export const stateData = (state) => state.ambassadorDetailReducer;

function* detailAmbassador({ id }) {
  try {
    
    yield put({ type: ACTIONS.LOADING });
    const result = yield call(fetchDetailAmbassador, id);
    
    const formatedData = yield call(formatAmbassdor, result.data);
    
    yield put({ type: ACTIONS.AMBASSADOR_DETAIL_FETCH_SUCCEEDED, payload: formatedData });
  } catch (error) {
    yield put({ type: ACTIONS.AMBASSADOR_DETAIL_FETCH_FAILED, error })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* activeAmbassadorSaga() {
  try {
    const data = yield select(stateData);
    yield call(deactiveAmbassador, data.ambassador.profile.id);
    yield put({type: ACTIONS.CHANGE_AMBASSADOR_STATUS_SUCCESS, message: "Deactive Ambassador successfully"});

  } catch (error) {
    yield put({ type: ACTIONS.AMBASSADOR_DETAIL_FETCH_FAILED, error: error.response.data.message })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* deactiveAmbassadorSaga() {
  try {
    const data = yield select(stateData);
    yield call(activeAmbassador, data.ambassador.profile.id);
    yield put({type: ACTIONS.CHANGE_AMBASSADOR_STATUS_SUCCESS, message: "Active Ambassador successfully"});

  } catch (error) {
    yield put({ type: ACTIONS.AMBASSADOR_DETAIL_FETCH_FAILED, error: error.response.data.message })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* takeOverSaga(data) {
  try {
    const datat = yield select(stateData);
    const currentId = datat.ambassador.profile.id;
    const {proId, ambasaadorId} = data;
    if(currentId === ambasaadorId) {
      throw ({response:{
        data: {
          message: "This property was asigned to this ambassador."
        }
      }});
    }

    yield put ({type: ACTIONS.LOADING})
   
    yield call(takeOver, proId, ambasaadorId);

    yield put ({type: ACTIONS.TAKE_OVER_PROPERTY_SUCCESS, propertyId :proId, message: "Take over successfully"})

  } catch (error) {
    yield put({ type: ACTIONS.AMBASSADOR_DETAIL_FETCH_FAILED, error: error.response.data.message })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}


function* ambassadorWatcher() {
  yield takeLatest(ACTIONS.AMBASSADOR_DETAIL_FETCH_REQUESTED, detailAmbassador);
  yield takeLatest(ACTIONS.CHANGE_AMBASSADOR_ACTIVE_REQUEST, activeAmbassadorSaga);
  yield takeLatest(ACTIONS.CHANGE_AMBASSADOR_DEACTIVE_REQUEST, deactiveAmbassadorSaga);
  yield takeLatest(ACTIONS.TAKE_OVER_PROPERTY_REQUEST, takeOverSaga)
}

export default ambassadorWatcher 
