import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

class NotificationModal extends PureComponent {

  render() {
    const { color, message, isOpen, toggle } = this.props;
    let Icon, title;
    switch (color) {
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        title="Success";
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        title="Error";
        break;
      default:
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
    }

    return (
      <div >
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog--${color}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
            {Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <p style={{overflow: "auto", whiteSpace: "pre-line"}}>
            {message}
            </p>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok" color={color} onClick={toggle}>Ok</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default NotificationModal;
