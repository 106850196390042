import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {IMAGE_BASE_URL} from '../../../../shared/helpers/api';
import { Link } from "react-router-dom";
import  {PATH_URL} from "../../../../shared/helpers";
import avatar from '../../../../shared/img/avatar.png';
const AgentCarouselItem = ({ t, avatarImg, name, address, numberAsignProperties, id }) => (
    <Link
    to={{
        pathname: PATH_URL.AMBASSADORS_DETAIL_PATH + id,
    }}
    style={{ height: "160px" }}
>
    <div className="slide-container" style={{paddingTop: "40px"}}>
        <img src={avatarImg ? `${IMAGE_BASE_URL}/large/${avatarImg}` : avatar} alt="Agent" className="dashboard__agent-img"/>
        <div className="slick-slider__caption agent-item">
            <p className="slick-slider__caption-title agent-name">{name}</p>
            <p className="slick-slider__caption-description agent-address">{address}</p>
            <p className="slick-slider__caption-description">{numberAsignProperties} closing deals</p>
        </div>
    </div>
    </Link>
);

AgentCarouselItem.propTypes = {
    t: PropTypes.func.isRequired,
    avatarImg: PropTypes.string.isRequired,
    name:  PropTypes.string.isRequired,
    address:  PropTypes.string.isRequired
};

export default withTranslation('common')(AgentCarouselItem);
