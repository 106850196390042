import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  ON_SHORT_PAYMENT,
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SUCCESS,
  FETCH_PAYMENT_ERROR,
  CHANGE_PAGE,
  CHANGE_ITEM_PER_PAGE
} from '../actions/index'
import { fetchData, formatData } from '../services/index';

export const getData = (state) => state.paymentReducer;

function* getPayments() {
  try {
    const stateData = yield select(getData);
    const { fromDate, toDate, itemPerPage, pageNumber, sort, desc } = stateData;
    const result = yield call(fetchData, fromDate, toDate, itemPerPage, pageNumber, sort, desc);

    const formatedData = yield call(formatData, result.data.data);
    const data = { data: formatedData, count: "total" in result.data ? result.data.total.count : 0 };
    yield put({ type: FETCH_PAYMENT_SUCCESS, data });

  } catch (error) {
    yield put({ type: FETCH_PAYMENT_ERROR, error })
  }
}



function* paymentWatcher() {
  yield takeLatest(FETCH_PAYMENT_REQUEST, getPayments);
  yield takeLatest(CHANGE_PAGE, getPayments);
  yield takeLatest(CHANGE_ITEM_PER_PAGE, getPayments);
  yield takeLatest(ON_SHORT_PAYMENT, getPayments);
}

export default paymentWatcher
