import {
    FETCH_SELLER_REQUEST,
    FETCH_SELLER_SUCCESS,
    FETCH_SELLER_ERROR,
    CHANGE_FILTER_VALUE,
    CHANGE_ITEM_PER_PAGE,
    CHANGE_PAGE,
    ON_SHORT_SELLER,
    CHANGE_DEFAULT_PAGE,
    RESET_STATE
} from '../actions/index';

const initialState = {
    result: { data: [], count: 0 },
    itemPerPage: 10,
    pageNumber: 0,
    showLoading: false,
    rowsPerPage: 10,
    status: { value: '', label: 'All' },
    fromDate: "",
    toDate: "",
    name: "",
}

const sellerReducer = function sellerReducer(state = initialState, action) {

    switch (action.type) {
        case FETCH_SELLER_REQUEST:
            const result = { ...state, showLoading: true };
            return result;

        case FETCH_SELLER_SUCCESS:

            return { ...state, showLoading: false, result: action.data }

        case FETCH_SELLER_ERROR:
            return { ...state, showLoading: false }

        case CHANGE_FILTER_VALUE:
            const { status, name } = action.filtersData;
            return { ...state, status: status !== undefined ? status : state.status, name: name !== undefined ? name : state.name }

        case CHANGE_DEFAULT_PAGE:
        case CHANGE_PAGE:
            return { ...state, pageNumber: action.pageNumber }

        case CHANGE_ITEM_PER_PAGE:
            return { ...state, itemPerPage: action.itemPerPage }
        case ON_SHORT_SELLER:
            return { ...state, sort: action.value.col, desc: action.value.value === 'desc' ? -1 : 0 };
        case RESET_STATE:
            return {...state, ...initialState};
        default:
            return state
    }
}

export default sellerReducer
