import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import MatTable from "../../../shared/components/table/components/MatTable";
import Loading from "../../../shared/components/Loading";
import { connect } from "react-redux";
import {
  onShortSeller,
  fetchSeller,
  changeFilterValue,
  changePage,
  changeItemPerPage,
  changeDeafultPage,
  resetState
} from "./redux/actions/index";
import Filter from "./components/Filter";
import { withRouter } from "react-router";

class Sellers extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onClickRow = this.onClickRow.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.props.resetState();
    this.props.fetchSeller();
  }

  onPageChange(page, orders) {
    this.props.changePage(page);
  }

  onClickRow(data) {
    const detailURL = "sellers/" + data._id;
    this.props.history.push(detailURL, { data });
  }

  onChangeRowsPerPage(event) {
    this.props.changeItemPerPage(Number(event.target.value));
  }

  changeFilterValue = filtersData => {
    this.props.changeFilterValue(filtersData);
  };

  onFilter = () => {
    this.props.changeDeafultPage();
    this.props.fetchSeller();
  };

  render() {
    const { result, pageNumber, itemPerPage, showLoading } = this.props;
    const { data, count } = result;
    return (
      <Container className="properties">
        <Loading isOpen={showLoading} />
        <Filter
          changeFilterValue={this.changeFilterValue}
          onFilter={this.onFilter}
          data={this.props}
        />

        <Row>
          {data.length > 0 ? (
            <MatTable
              data={data}
              onShort={this.props.onShortSeller}
              count={count}
              currentPage={pageNumber}
              onPageChange={this.onPageChange}
              rowsPerPage={itemPerPage}
              column={[
                "first_name",
                "last_name",
                "email",
                "status",
                "add_time",
                "posting_properties_count",
                "closed_properties_count"
              ]}
              header={[
                "First Name",
                "Last Name",
                "Email",
                "Status",
                "Joined date",
                "Posting property",
                "Close property"
              ]}
              percents={["15%", "15%", "20%", "10%", "15%", "10%", "10%"]}
              // onRowsPerPageChange={this.onChangeRowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              onClickRow={this.onClickRow}
              showId={true}
            />
          ) : (
            <span>No Data</span>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.sellerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSeller: () => {
      dispatch(fetchSeller());
    },
    changeFilterValue: filtersData => {
      dispatch(changeFilterValue(filtersData));
    },
    changePage: pageNumber => {
      dispatch(changePage(pageNumber));
    },
    changeItemPerPage: itemPerPage => {
      dispatch(changeItemPerPage(itemPerPage));
    },
    onShortSeller: value => {
      dispatch(onShortSeller(value));
    },
    changeDeafultPage: () => {
      dispatch(changeDeafultPage());
    },
    resetState: () => {
      dispatch(resetState());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation("common")(Sellers)));
