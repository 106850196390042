import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import EmailTemplateForm from './components';
import NotificationModal from '../../../shared/components/NotificationModal';
import Loading from '../../../shared/components/Loading';
import { createEmailTemplate, loadEmailTypes } from './redux/actions';

class EmailTemplateCreate extends Component  {

    componentWillMount() {
       this.props.loadEmailTypes();
    }

    render() {
        const { loading, createEmailTemplate, emailTypes} = this.props;
        return (
            <Col md={12}>
                <Loading isOpen={loading} />
                <NotificationModal
                    isOpen={false}
                    color={'error' ? "danger" : "success"}
                    message={'message'}
                    toggle={() => { this.closeModalSuccess('error') }}
                />
                <Card>
                    <CardBody>
                        <EmailTemplateForm onSubmit={values => createEmailTemplate(values)} emailTypes={emailTypes}/>
                    </CardBody>
                </Card>
            </Col>);
    }
    
}

const mapStateToProps = state => {
    return {
        loading: state.emailTemplateCreateReducer.loading,
        emailTypes: state.emailTemplateCreateReducer.emailTypes
    }
}
const mapDispatchToProps = {
    createEmailTemplate,
    loadEmailTypes
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplateCreate));