import * as ACTIONS from "../actions/chatAction";
import { IMAGE_BASE_URL } from "../../../../../shared/helpers/api";

const initialState = {
  loading: false,
  itemPerPage: 10,
  count: 0,
  pageNumber: 0,
  currentRoom: null,
  roomsData: [],
  roomsTotal: {
    count: 0,
  },
  messagesData: [],
  chatRoomsLoading: false,
  chatMessagesLoading: false,
  messageSortField: "add_time",
  messageSortDesc: 0,
  notificationType: "",
  notificationMessage: "",
  showAgentsModal: false,
  filter: {},
};

const getAvatar = (item) => {
  if (item.user) {
    return item.user.avatar
      ? `${IMAGE_BASE_URL}/large/${item.user.avatar}`
      : `https://ui-avatars.com/api/?name=${item.user.first_name}+${item.user.last_name}`;
  } else if (item.company_user) {
    return `https://ui-avatars.com/api/?name=C+P`;
  }
};
const getAvatarFirebase = (item) => {
  if (item.user_avatar) {
    return item.user.avatar ? `${IMAGE_BASE_URL}/large/${item.user.avatar}` : null;
  } else {
    if (item.role === "admin") {
      return `https://ui-avatars.com/api/?name=C+P`;
    } else {
      return `https://ui-avatars.com/api/?name=${item.user_first_name}+${item.user_last_name}`;
    }
  }
};
const chatRoomsTransform = (rawData) => {
  const adminId = localStorage.getItem("admin_id");
  return rawData
    ? rawData.map((item) => ({
        id: item._id,
        name: `Property ${item.rooms[0].property_title}`,
        createTime: item.first_message_add_time,
        users: item.rooms.map((room) => ({
          name: `${room.user_first_name} ${room.user_last_name}`,
          isOnline: true,
          totalMessage: room.unread[adminId],
          roomId: room._id,
          propertyId: room.property_id,
          ownerId: room.property_owner_id,
          ownerFirstName: room.property_owner_first_name,
          ownerLastName: room.property_owner_last_name,
          buyerFirstName: room.user_first_name,
          buyerLastName: room.user_last_name,
          roomType: room.room_type,
          agentFistName: room.agent_first_name,
          agentLastName: room.agent_last_name,
          role: room.user_role,
          timeStamp: room.last_message.add_time
            ? room.last_message.add_time
            : "",
          removeLabel: room.unread[adminId] === 0 ? true : false
        })),
      }))
    : [];
};

const chatMessagesTransform = (rawData) => {
  return rawData.map((item) => ({
    message: {
      messageType: item.msg_type,
      message: item.message,
      date: new Date(item.add_time),
      _id: item._id,
    },
    user: {
      avatar: getAvatar(item),
      isCompany: Boolean(item.company_user),
      firstName: item.user && item.user.first_name,
      lastName: item.user && item.user.last_name,
      email: item.user && item.user.email,
    },
  }));
};

const chatMessagesFirebaseTransform = (rawData) => {
  return rawData.map((item) => ({
    message: {
      messageType: item.msg_type,
      message: item.message,
      date: new Date(item.add_time),
      _id: item._id,
    },
    user: {
      avatar: getAvatarFirebase(item),
      isCompany: Boolean(item.role === "admin"),
      firstName: item.user_first_name,
      lastName: item.user_last_name,
      email: item.user_email,
    },
  }));
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.LOADING:
      return { ...state, loading: true };
    case ACTIONS.RESET_FILTER:
      return { ...state, filter: {} };
    case ACTIONS.GET_FIREBASE_TOKEN:
      return { ...state, chatRoomsLoading: true };
    case ACTIONS.CHAT_ROOM_FETCH_REQUESTED: {
      if (action.isInit && !action.isReload) {
        return { ...state, loading: true, roomsData: [], pageNumber: 0 };
      }
      const pageNumber = action.isReload ? 0 : state.pageNumber;
      return { ...state, chatRoomsLoading: true, pageNumber };
    }
    case ACTIONS.CHAT_ROOM_SEARCH_REQUEST:
      return {
        ...state,
        roomsData: [],
        pageNumber: 0,
        itemPerPage: 10,
        filter: action.searchQuery,
        chatRoomsLoading: true,
      };
    case ACTIONS.CHAT_ROOM_FETCH_SUCCEEDED:
    case ACTIONS.CHAT_ROOM_SEARCH_SUCCESS: {
      const { roomsData, roomsTotal, isReload } = action.payload;
      const newRoomsData = isReload
        ? [...chatRoomsTransform(roomsData)]
        : [...state.roomsData, ...chatRoomsTransform(roomsData)];
      return {
        ...state,
        roomsData: newRoomsData,
        roomsTotal,
        pageNumber: state.pageNumber + 1,
        chatRoomsLoading: false,
        loading: false,
      };
    }
    case ACTIONS.CHAT_ROOM_FETCH_FAILED:
      return {
        ...state,
        notificationType: "danger",
        notificationMessage: action.payload.error,
      };
    case ACTIONS.REMOVE_CHAT_MESSAGE_NUMBER: {
      const { roomId, propertyId, ownerId, groupId, roomType } = action;
      let roomsDataState = [...state.roomsData];
      // let roomsDataState = state.roomsData;
      const index = roomsDataState.findIndex(properties => properties.id === propertyId);
      let usersData = roomsDataState[index].users;
      const userIndex = usersData.findIndex(users => users.roomId === roomId);
      // const checkTotalMessage = usersData[userIndex].totalMessage === 0 ? true : false;
      // let userDataChange = usersData[userIndex].removeLabel = true;
      usersData[userIndex].removeLabel = true;
      usersData[userIndex].totalMessage = 0;
      // console.log('userDataChange ',[ ...usersData[userIndex], { removeLabel: userDataChange}])
      return {
        ...state,
        // roomsData: !checkTotalMessage ? [userDataChange] : roomsDataState
      };
    }
    case ACTIONS.CHAT_MESSAGE_FETCH_SUCCEEDED:
      const { messagesData, currentRoom } = action.payload;
      return {
        ...state,
        messagesData: chatMessagesTransform(messagesData),
        currentRoom,
        loading: false,
        chatRoomsLoading: false,
      };
    case ACTIONS.CHAT_MESSAGE_FETCH_FAILED:
      return {
        ...state,
        notificationType: "danger",
        notificationMessage: action.payload.error,
      };
    case ACTIONS.TOGGLE_NOTIFICATION:
      return { ...state, notificationType: "" };
    case ACTIONS.SHOW_AGENT_MODAL:
      return { ...state, showAgentsModal: true };
    case ACTIONS.ASSIGN_AGENT_TO_PROPERTY_SUCCEEDED:
      return {
        ...state,
        notificationType: "success",
        notificationMessage: action.payload.message,
      };
    case ACTIONS.GET_FIREBASE_TOKEN_FAILED:
    case ACTIONS.ASSIGN_AGENT_TO_PROPERTY_FAILED:
      return {
        ...state,
        notificationType: "danger",
        notificationMessage: action.payload.error,
      };
    case ACTIONS.CLOSE_AGENT_MODAL:
      return { ...state, showAgentsModal: false };
    case ACTIONS.CHAT_ROOM_APPEND_NEW_MESSAGE:
      const message = action.message || action.payload.message;
      let messagesDatas = state.messagesData;
      let exist = false;
      messagesDatas.forEach((element) => {
        if (element.message._id === message._id) {
          exist = true;
        }
      });
      let messagesDataNew = [...messagesDatas];
      if (!exist)
        messagesDataNew = (messagesDatas || []).concat(
          chatMessagesFirebaseTransform([message])
        );
      return { ...state, messagesData: messagesDataNew };

    case ACTIONS.CHAT_WITH_OWNER_SUCCESS:
      const { rooms } = action;
      return { ...state, roomsData: [...rooms] };
    default:
      return state;
  }
}
