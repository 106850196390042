import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { handleListParameter, handleChangeTable } from "./redux/actions";
import { history, PATH_URL } from "../../../shared/helpers/index";

import ListParameters from "./components";
import { Column } from "./components/constant";

class Parameters extends Component {
  componentDidMount() {
    const { handleListParameter } = this.props;
    const params = {};
    handleListParameter(params);
  }

  onChange = event => {
    event.preventDefault();
  };

  onChangeRowsPerPage = event => {
    event.preventDefault();

    const { handleChangeTable } = this.props;
    handleChangeTable({ rowsPerPage: Number(event.target.value) });
  };

  onClickRow = row => {
    if (!row || (row && !row.id)) {
      history.push();
    }

    const url = `${PATH_URL.PARAMETERS}/${row.id}`;
    history.push(url);
  };

  onPageChange = (page, order) => {
    const { handleChangeTable } = this.props;
    handleChangeTable({ page: Number(page), order });
  };

  render() {
    const { t, loading } = this.props;

    if (loading) {
      return (
        <div className={`load${loading ? "" : " loaded"}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      );
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("parameters.page_title")}</h3>
          </Col>
        </Row>
        <Row>
          <ListParameters
            onPageChange={this.onPageChange}
            column={Column}
            header={Column}
            // onRowsPerPageChange={this.onChangeRowsPerPage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            handleChangePage={this.handleChangePage}
            onClickRow={this.onClickRow}
            {...this.props}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.parameters,
    data: state.parameters.items
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleListParameter: params => {
      dispatch(handleListParameter(params));
    },
    handleChangeTable: data => {
      dispatch(handleChangeTable(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Parameters));
