import { take, fork, cancel, call, put, cancelled, select } from 'redux-saga/effects'
import { login, authome, loginUA, logoutUA } from '../../services/index';
import { history, PATH_URL } from '../../../../../shared/helpers/index'

import {  
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ERROR
} from '../actions/loginAction'

import {  
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from '../actions/logoutAction'


import {  
  setClient,
  unsetClient,
} from '../actions/clientAction'

function* loginFlow (username, password, remember_me) {
  let token
  let tokenUA
  try {
    token = yield call(login, username, password)
    tokenUA = yield call(loginUA, username, password)
    if(token && token.access_token && tokenUA && tokenUA.data) {
      yield put(setClient(token, tokenUA.data))
      yield put({ type: LOGIN_SUCCESS })
      localStorage.setItem('token', token.access_token);
      localStorage.setItem('token_UA', tokenUA.data);
      localStorage.setItem('remember_me', remember_me);
      const result = yield call(authome);
      localStorage.setItem('admin_id', result.data.userId)
      yield call(history.push, PATH_URL.DASHBOARD);
    }

    yield put({ type: LOGIN_ERROR, token })
   
  } catch (error) {
    
    yield put({ type: LOGIN_ERROR, error })

  } finally {
    if (yield cancelled()) {
      
      yield call(history.push, PATH_URL.HOME);
      
    }
  }
  return token
}


function* logout () {
  // remove our token
  localStorage.removeItem('token');
  localStorage.removeItem('remember_me');
  localStorage.removeItem('admin_id');
  localStorage.removeItem('token_UA');

  const getClientReducer = (state) => state.clientReducer
  const clientReducer = yield select(getClientReducer)
  yield call(logoutUA, clientReducer.tokenUA)
  // redirect to the /login screen
  yield call(history.replace, PATH_URL.DEFAULT);
}

function* loginWatcher () {
  while (true) {
    const { username, password, remember_me } = yield take(LOGIN_REQUESTING)

    const task = yield fork(loginFlow, username, password, remember_me)

  //   const action = yield take([CLIENT_UNSET, LOGIN_ERROR])

  //   if (action.type === CLIENT_UNSET) yield cancel(task)

  //   yield call(logout)

  }
}

function* logoutWatcher () {
  while (true) {
    const action = yield take(LOGOUT_REQUESTING)
    
    yield call(logout)

  }
}
export {
  logoutWatcher,
  loginWatcher
}
