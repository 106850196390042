import React, { Component } from "react";
import { Card, CardBody, Col } from "reactstrap";
import ExploreSectionForm from "./components/ExploreSectionForm";
import {
  handleCreate,
  handleUpdateExploreSection,
  handleDetailExploreSection,
  createFail,
  createSuccess,
  closeModal
} from "./redux/actions/exploreSectionDetailAction";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import NotificationModal from "../../../shared/components/NotificationModal";
import Loading from "../../../shared/components/Loading";
import { history } from "../../../shared/helpers/index";

class ExploreSectionsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsExploreSectionId: history.location.pathname.split("/").pop()
    };
  }
  closeModalSuccess = error => {
    this.props.closeModal();
    if (!error) history.goBack();
  };

  componentDidMount() {
    const { handleDetailExploreSection } = this.props;

    const { newsExploreSectionId } = this.state;
    handleDetailExploreSection(newsExploreSectionId);
  }

  render() {
    const {
      loading,
      message,
      showNotification,
      error,
      data,
      closeModal,
      exploreSectionData
    } = this.props;

    const { newsExploreSectionId } = this.state;

    return (
      <Col md={12}>
        <Loading isOpen={loading} />
        <NotificationModal
          isOpen={showNotification}
          color={error ? "danger" : "success"}
          message={message}
          toggle={() => {
            this.closeModalSuccess(error);
          }}
        />
        <Card>
          <CardBody>
            <ExploreSectionForm
              id={newsExploreSectionId}
              data={exploreSectionData}
              handleUpdate={this.props.handleUpdateExploreSection}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.exploreSectionDetailReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCreate: data => {
      dispatch(handleCreate(data));
    },
    handleUpdateExploreSection: (id, data) => {
      dispatch(handleUpdateExploreSection(id, data));
    },
    handleDetailExploreSection: id => {
      dispatch(handleDetailExploreSection(id));
    },
    createSuccess: () => {
      dispatch(createSuccess());
    },
    createFail: error => {
      dispatch(createFail(error));
    },
    closeModal: () => {
      dispatch(closeModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(ExploreSectionsDetail));
