import { call, put, takeLatest, select } from 'redux-saga/effects'
import { FETCH_ACCOUNTS_REQUEST, 
  FETCH_ACCOUNTS_SUCCESS, 
  FETCH_ACCOUNTS_ERROR, 
  CHANGE_PAGE, 
  CHANGE_ITEM_PER_PAGE,
  ACTIVE_USER,
  DEACTIVE_USER,
  ACTIVE_RESULT,
  ON_SHORT_ACCOUNT
} from '../actions/index'
import { fetchData, formatData, activeUser, deactiveUser, formatAfterAction } from '../services/index';

export const getData = (state) => state.accountsReducer;

function* getAccounts() {
  try {
    
    const stateData = yield select(getData);
    const { itemPerPage, pageNumber, sort, desc, email, status } = stateData;
    const result = yield call(fetchData, itemPerPage, pageNumber, sort, desc, email, status.value);
    const formatedData = yield call(formatData, result.data.data);
    const data = {data: formatedData, count: result.data.total ? result.data.total.count : 0};
    yield put({ type: FETCH_ACCOUNTS_SUCCESS, data: data});

  } catch (error) {
    yield put({ type: FETCH_ACCOUNTS_ERROR, error })
  }
}

function* changePage() {
  yield put({ type: FETCH_ACCOUNTS_REQUEST });
}

function* changeItemPerPage() {
  yield put({ type: FETCH_ACCOUNTS_REQUEST });
}

function* activeUserWatcher(data) {

  try {
    yield call(activeUser, data.id);
    const stateData = yield select(getData);
    
    let datas = stateData.result.data;
    const result = yield call(formatAfterAction, datas, data.id, "active"); 
    yield put({ type: ACTIVE_RESULT, data: result});
   } catch (error) {
    yield put({ type: FETCH_ACCOUNTS_ERROR, error })
  }
}

function* deactiveUserWatcher(data) {
  try {
    yield call(deactiveUser, data.id);
    const stateData = yield select(getData);
    let datas = stateData.result.data;
    const result = yield call(formatAfterAction, datas, data.id, "inactive"); 

    yield put({ type: ACTIVE_RESULT, data: result});
  } catch (error) {
    yield put({ type: FETCH_ACCOUNTS_ERROR, error })
  }
  
}

function* accountsWatcher() {
  yield takeLatest(FETCH_ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(CHANGE_PAGE, changePage);
  yield takeLatest(CHANGE_ITEM_PER_PAGE, changeItemPerPage);
  yield takeLatest(ACTIVE_USER, activeUserWatcher);
  yield takeLatest(DEACTIVE_USER, deactiveUserWatcher);
  yield takeLatest(ON_SHORT_ACCOUNT, getAccounts);
}

export default accountsWatcher 
