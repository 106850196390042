import React, { Component } from "react";
import { Button, Col, Row, Modal, Card, CardBody } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import renderHTML from "react-render-html";
import moment from 'moment';
import KeyInfor from "./KeyInfor";
import Facilities from "./Facilities";
import AgentProfile from "./AgentProfile";
import RelatedProperties from "./RelatedProperties";
import OnMap from "./OnMap";
import PropertyHeader from "./PropertyHeader";
import { MAP_TENANT_COLORS } from "../../../../shared/helpers/constants";
import PieChart from "react-minimal-pie-chart";
import ViewSchedule from "./ViewSchedule";
import { REQUIRED_TANANT_PROFILE } from '../../../../shared/helpers/constants';
import { currencyFormat } from '../../../../shared/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faBox, faCheckSquare, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FORMAT_DATETIME } from '../../../../shared/helpers/constants'
class PropertyInfor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTenant: false,
      modal: false,
    };
  }

  showTanent = () => {
    let { showTenant } = this.state;
    this.setState({ showTenant: !showTenant });
  };

  referTable = dataTenants => (
    <div className="referTable">
      {dataTenants.map(tnt => (
        <span className="item" key={Math.random()}>
          <span style={{ backgroundColor: tnt.color }} />
          <span>{tnt.title}</span>
          <span>&nbsp;({tnt.value}%)</span>
        </span>
      ))}
    </div>
  );

  toggle = () => {
    this.setState({ modal: !this.state.modal})
  }

  pieChartTenant = dataTenants => (
    <PieChart
      data={dataTenants}
      // label={({ data, dataIndex }) => `${data[dataIndex].value}%`}
      labelStyle={{
        fontSize: "6px",
        fontFamily: "sans-serif",
        fill: "#121212"
      }}
      lineWidth={90}
      // paddingAngle={(dataTenants.length > 1 && 5) || 0}
      className="pieChart"
    />
  );
  render() {

    const { showTenant, modal } = this.state;
    const {
      fetchPropertyDetail,
      confirmProperty,
      property,
      rejectProperty,
      postOnInstagram,
      postOnFB,
      changeStatusProperty,
      approveRenewalPayment,
      rejectRenewalPayment,
      verifyProperty,
      publishProperty,
      publishWithoutPay,
      t
    } = this.props;

    const {
      availability,
      ambassador,
      title,
      description,
      address,
      galleries,
      prices,
      listing_type,
      housing_type,
      construction_status,
      area,
      location,
      owner,
      verified_status,
      verify_type,
      bathrooms,
      bedrooms,
      toilets,
      furnitures,
      floors,
      facilities,
      currencies,
      tenants,
      viewing_schedules,
      status,
      related_properties,
      product,
      is_publish
    } = property;

    const dataTenants = !!tenants ? tenants.map(tnt => {
      let trans = `propertiesDetail.${tnt.name}`;
      return {
        title: `${t(trans)}`,
        value: tnt.percent,
        color: MAP_TENANT_COLORS[`${tnt.name}`]
      };
    }) : [];

    const housingType = {
      single_family_home: "Single-family home",
      condominium: "Condominium",
      apartment: "Apartment",
      short_stays: "Short-stay apartment",
      residential_resort: "Residential resort",
      commercial_property: "Commercial property",
      land: "Land",
    };

    const constructionStatus = {
      finished: "Finished",
      not_finished: "Not finished"
    }

    const translatePayment = product[0] ? `payment.${product[0].model_type}` : null;
    const multiplier = product[0] ? product[0].model_type.slice(-1) : null;

    return (
      <div>
        <Modal
          isOpen={showTenant}
          modalClassName={`ltr-support`}
          className={`tenantmodal`}
          title={t("propertiesDetail.tenants_profile")}
          btn="Default"
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={() => {
                this.setState({ showTenant: !showTenant });
              }}
            />
            <h4 className="text-modal  modal__title">
              {t("propertiesDetail.tenants_profile")}
            </h4>
          </div>
          {this.pieChartTenant(dataTenants)}
          {this.referTable(dataTenants)}
        </Modal>
        <Row>
          <PropertyHeader
            galleries={galleries}
            title={title}
            address={address}
            prices={prices}
            product={product}
            postOnInstagram={postOnInstagram}
            postOnFB={postOnFB}
            verified_status={verified_status}
            verify_type={verify_type}
            rejectProperty={rejectProperty}
            changeStatusProperty={changeStatusProperty}
            approveRenewalPayment={approveRenewalPayment}
            rejectRenewalPayment={rejectRenewalPayment}
            confirmProperty={confirmProperty}
            verifyProperty={verifyProperty}
            currencies={currencies}
            status={status}
            availability={availability}
            owner={owner}
            publishProperty={publishProperty}
            publishWithoutPay={publishWithoutPay}
            isPublish={is_publish}
          />
          <Col xs={12} md={12} lg={8} className="property__detail__leftcolumn">
            <Row>
              <KeyInfor
                listing_type={listing_type}
                housing_type={housing_type ? housingType[housing_type] : ''}
                construction_status={construction_status ? constructionStatus[construction_status] : ''}
                area={area ? `${currencyFormat(area)}  sq. m.` : ''}
                t={t}
                displayTenant={REQUIRED_TANANT_PROFILE.includes(housing_type)}
                showTanent={this.showTanent}
              />
            </Row>
            { ambassador && <Row>
              <Card>
                <CardBody>
                  <div className="key__infor__row">
                    <h3 className="page-title">Ambassador</h3>
                    <hr />
                    <Row className="key__infor__row">
                      <Col xs={6} md={6} lg={6}>
                        <FontAwesomeIcon className="key__infor_name" icon={faUser} style={{ marginRight: "10px" }} />
                        <span className="key__infor_name">{t('propertiesDetail.ambassador_name')}</span>
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <span className="key__infor_value">
                          {ambassador !== "tenants_profile" && ambassador.first_name + " " + ambassador.last_name}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Row> }
            <Row>
              <Card>
                <CardBody>
                  <div className="key__infor__row">
                    <h3 className="page-title">Description</h3>
                    <hr />
                    <div>{renderHTML(description)}</div>
                  </div>
                </CardBody>
              </Card>
            </Row>
            { product && product.length > 0 && (
                <Row>
                  <Card>
                    <CardBody>
                      <div className="key__infor__row">
                        <h3 className="page-title">Package Infomation</h3>
                        <hr />
                      </div>
                      <Row className="key__infor__row">
                        <Col xs={6} md={6} lg={6}>
                          <FontAwesomeIcon className="key__infor_name" icon={faBox} style={{ marginRight: "10px" }} />
                          <span className="key__infor_name">Type</span>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <span className="key__infor_value">{t(translatePayment).toUpperCase()}</span>
                          {/* <span className="key__infor_value">{(product[0].model_type || '').toUpperCase()}</span> */}
                        </Col>
                      </Row>
                      <Row className="key__infor__row">
                        <Col xs={6} md={6} lg={6}>
                          <FontAwesomeIcon className="key__infor_name" icon={faCalendar} style={{ marginRight: "10px" }} />
                          <span className="key__infor_name">Transaction Date</span>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <span className="key__infor_value">{(moment(product[0].add_time).format(FORMAT_DATETIME)).toUpperCase()}</span>
                        </Col>
                      </Row>
                      <Row className="key__infor__row">
                        <Col xs={6} md={6} lg={6}>
                          <FontAwesomeIcon className="key__infor_name" icon={faCheckSquare} style={{ marginRight: "10px" }} />
                          <span className="key__infor_name">Status</span>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <span className="key__infor_value">{product[0].status}</span>
                        </Col>
                      </Row>
                      <Row className="key__infor__row">
                        <Col xs={6} md={6} lg={6}>
                          <FontAwesomeIcon className="key__infor_name" icon={faMoneyBillWave} style={{ marginRight: "10px" }} />
                          <span className="key__infor_name">Total Price</span>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <span className="key__infor_value">{product[0].prices.amount} {(product[0].prices.currency || '').toUpperCase()}</span>
                        </Col>
                      </Row>
                      {/* <Row className="key__infor__row">
                        <Col xs={6} md={6} lg={6}>
                          <FontAwesomeIcon className="key__infor_name" icon={faMoneyBillWave} style={{ marginRight: "10px" }} />
                          <span className="key__infor_name">Total</span>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <span className="key__infor_value">{(Number(product[0].prices.amount) * Number(multiplier)).toFixed(2)} {(product[0].prices.currency || '').toUpperCase()}</span>
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Row>)
            }
            { product && product.length > 1 && <Row>
              <Button color="primary" onClick={this.toggle} size="sm">Payment List</Button>
              <Modal size="lg" style={{maxWidth: '1000px', width: '100%'}}
                isOpen={modal}
                toggle={this.toggle}
              >
                <div>
                  <Table style={{ maxWidth: '1000px' }}>
                    <TableHead>
                      <TableRow>
                      <TableCell>Model Type</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Transaction Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    { product && product.length > 1 && product.slice(1).map((item, index) => {
                      const cellMultiplier = item.model_type.slice(-1);
                      const cellTranslatePayment = `payment.${item.model_type}`;
                      return (
                        <TableRow key={index}>
                          <TableCell>{t(cellTranslatePayment)}</TableCell>
                          <TableCell>{item.prices.amount}</TableCell>
                          <TableCell>{(Number(item.prices.amount) * Number(cellMultiplier)).toFixed(2)}</TableCell>
                          <TableCell>{moment(item.add_time).format(FORMAT_DATETIME)}</TableCell>
                        </TableRow>
                      )}) }
                    </TableBody>
                  </Table>
                </div>
              </Modal>
            </Row> }
            <Row>
              <Facilities
                bathrooms={bathrooms}
                bedrooms={bedrooms}
                toilets={toilets}
                furnitures={furnitures}
                floors={floors}
                facilities={facilities}
              />
            </Row>
            <Row>
              {!!location && <OnMap location={location} />}
            </Row>
            <Row>
              {" "}
              <ViewSchedule t={t} viewing_schedules={viewing_schedules} />{" "}
            </Row>
            <Row>
              {" "}
              <RelatedProperties
                related_properties={related_properties}
                currencies={currencies}
                fetchPropertyDetail={fetchPropertyDetail}
              />{" "}
            </Row>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <Row>
              {" "}
              <AgentProfile owner={owner} />{" "}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PropertyInfor;
