import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class TopItem extends PureComponent {

  render() {
    const { number, icon, name, path, pathState } = this.props;
    
    return (

      <Col md={12} xl={3} lg={6} sm={12} xs={12} style={{ paddingRight: "0px" }}>
        <Card>
          <Link
            to={{
              pathname: path,
              state: pathState
            }}
            style={{height: "160px"}}
          >
            <CardBody className="dashboard__item">

              <span className={`dashboard__topitem__icon lnr lnr-${icon}`}></span>

              <p className="bold-text card__title-center top__item__number">{number}</p>

              <p className="dashboard__topitem__title"><strong>{name}</strong></p>

            </CardBody>
          </Link>
        </Card>
      </Col>


    );
  }

}

export default withTranslation('common')(TopItem);
