import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { currencyFormat } from "../../../shared/helpers";
import Loading from "../../../shared/components/Loading";
import Confirm from "../../../shared/components/Confirm";
import {
  fetchListCurrency,
  removeCurrency,
  changePage,
  changeItemPerPage
} from "./redux/actions";
import { history, PATH_URL } from "../../../shared/helpers/index";
import MatTable from "../../../shared/components/table/components/MatTable";
import { Link } from "react-router-dom";

class Currencies extends Component {
  constructor() {
    super();
    this.state = {
      showConfirm: false,
      currencyId: null
    };

    this.toggleConfirm = this.toggleConfirm.bind(this);
  }

  componentDidMount() {
    this.props.fetchListCurrency();
  }

  onChangeRowsPerPage = event => {
    this.props.changeItemPerPage({ itemPerPage: Number(event.target.value) });
  };

  onClickCell = (columnId, row) => {
    if (columnId === "Actions") {
      const { removeCurrency } = this.props;
      removeCurrency(row);
    }
  };

  onClickRow = row => {
    if (!row || (row && !row._id)) {
      history.push();
    }

    const url = PATH_URL.CURRENCIES_EDIT.replace(":id", row._id);
    history.push(url);
  };

  onPageChange = (page, order) => {
    this.props.changePage(Number(page));
  };

  onRemoveCurrency = () => {
    this.props.removeCurrency(this.state.currencyId);
    this.setState({ showConfirm: false });
  };

  toggleConfirm = () => {
    this.setState({ showConfirm: false });
  };

  render() {
    const { result, pageNumber, itemPerPage, showLoading, t } = this.props;
    const { data, count } = result;
    const dataDisplay = data.map(d => {
      const priceMaxRangeForSale = `${currencyFormat(d.max_value)}`;
      const priceMaxRangeForRent = `${currencyFormat(d.rent_max_value)}`;
      return { ...d, priceMaxRangeForSale, priceMaxRangeForRent };
    });

    return (
      <Container className="dashboard">
        <Confirm
          toggle={this.toggleConfirm}
          isOpen={this.state.showConfirm}
          title={"Delete"}
          message={"Are you sure remove this currency?"}
          confirm={this.onRemoveCurrency}
        />
        <Loading isOpen={showLoading} />
        <Row>
          <Col md={12} lg={12}>
            <h3 className="page-title">{t("currencies.page_title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Link
              className="btn btn-primary btn-sm"
              to="/company/currencies/create"
            >
              Create New
            </Link>
          </Col>
          <Col md={12} lg={12}>
            {dataDisplay.length > 0 ? (
              <MatTable
                data={dataDisplay}
                count={count}
                currentPage={pageNumber}
                onPageChange={this.onPageChange}
                rowsPerPage={itemPerPage}
                onClickCell={this.onClickCell}
                column={[
                  "name",
                  "status",
                  "priceMaxRangeForRent",
                  "priceMaxRangeForSale"
                ]}
                header={[
                  "Currency Name",
                  "Status",
                  "Max value for rent",
                  "Max value for sale"
                ]}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                // onRowsPerPageChange={this.onChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
                onClickRow={this.onClickRow}
                showId={true}
                actionColumnCustom={[
                  {
                    title: "Delete",
                    color: "danger",
                    // action: this.onRemoveCurrency
                    action: id => {
                      this.setState({
                        currencyId: id,
                        showConfirm: true
                      });
                    }
                  }
                ]}
              />
            ) : (
              <span>No Data</span>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.currencyReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchListCurrency: () => {
      dispatch(fetchListCurrency());
    },
    changePage: pageNumber => {
      dispatch(changePage(pageNumber));
    },
    changeItemPerPage: itemPerPage => {
      dispatch(changeItemPerPage(itemPerPage));
    },
    removeCurrency: id => {
      dispatch(removeCurrency(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Currencies));
