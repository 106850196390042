import * as ACTIONS from '../actions';
import momment from 'moment';
import {FORMAT_DATE} from '../../../../../shared/helpers/constants'

const initialState = {
    loading: false,
    emailTemplateData: [],
    count: 100,
    pageNumber: 0,
    itemPerPage: 10,
    notificationType: '',
    notificationMessage: '',
}

function emailTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.LOADING:
        case ACTIONS.EMAIL_TEMPLATE_FETCH_REQUESTED:
            return { ...state, loading: true };
        case ACTIONS.EMAIL_TEMPLATE_FETCH_SUCCEEDED:
            const { data, count } = action.payload;
            const newData = (data || []).map(item => ({
                ...item,
                upd_time: momment(item.upd_time).format(FORMAT_DATE),
            }));
            return { ...state, emailTemplateData: newData, count, loading: false };
        case ACTIONS.EMAIL_TEMPLATE_FETCH_FAILED:
            return { ...state, loading: false };
        case ACTIONS.EMAIL_TEMPLATE_CHANGE_PAGE:
            const { page } = action.payload;
            return { ...state, pageNumber: page };
        case ACTIONS.EMAIL_TEMPLATE_CHANGE_ROW_PER_PAGE:
            const { rowPerPage } = action.payload;
            return { ...state, itemPerPage: rowPerPage };
        default:
            return state;
    }
}

export { emailTemplateReducer }