import React from 'react';
import { Input } from 'reactstrap';
import ChatIcon from 'mdi-react/ChatIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import { connect } from 'react-redux';
import { showAgentModal, chatWithOnwer } from '../redux/actions/chatAction';
import { withTranslation } from 'react-i18next';

const ChatDirectional = (props) => {
    const { showAgentModal, chatWithOnwer, currentRoom } = props;
    return (
        <div className="chat__text-field chat__directional-container">
            <form className="chat-directional__form">
                <div className="flex-row">
                    <button
                        type="button"
                        className="chat__icon-button"
                        onClick={showAgentModal}
                    >
                        <span>Assign Ambassadors</span>
                        <AccountMultipleIcon />
                    </button>
                    <button
                        type="button"
                        className="chat__icon-button"
                        onClick={() => {chatWithOnwer(currentRoom.ownerId, currentRoom.propertyId, currentRoom.groupId)}}
                    >
                        <span>Chat with Owner</span>
                        <ChatIcon />
                    </button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.chatReducer,
    }
}
export default connect(mapStateToProps, { showAgentModal, chatWithOnwer })(withTranslation('common')(ChatDirectional));
