import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../../shared/components/form/Select";
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import { Col, Button, Card, CardBody } from "reactstrap";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import moment from "moment";

import Select from "react-select";
class Filter extends Component {
  state = {
    submit1: "1",
    submit2: "2",
    submit3: "3",
    submit4: "4",
  };
  onChangeStatus = (e) => {
    const { fromDate, toDate, name } = this.props;
    this.props.changeFilterValue({ status: e, fromDate, toDate, name });
  };

  onChangeFromDate = (e) => {
    const { status, toDate, name } = this.props;
    this.props.changeFilterValue({
      fromDate: moment(e).format("YYYY-MM-DD"),
      status,
      toDate,
      name,
    });
  };

  onChangeToDate = (e) => {
    const { status, fromDate, name } = this.props;
    this.props.changeFilterValue({
      toDate: moment(e).format("YYYY-MM-DD"),
      status,
      fromDate,
      name,
    });
  };

  onChangeName = (e) => {
    const { status, fromDate, toDate } = this.props;
    let name = e.target.value;
    name = name ? name.trim() : name;
    this.props.changeFilterValue({ name, status, fromDate, toDate });
  };

  buildPayload = (submitName) => {
    return submitName;
  };

  handleSubmit = (submitName) => {
    const payload = this.buildPayload(submitName);
    const { paymentStatus } = this.props;

    if (payload === "1") {
      this.props.setPublishTypeValue({ paymentStatus: null });
      this.props.onFilter();
    } else if (payload === "2") {
      this.props.setPublishTypeValue({
        paymentStatus: "paying",
      });
      this.props.onFilter();
    } else if (payload === "3") {
      this.props.setPublishTypeValue({
        paymentStatus: "undefined",
      });
      this.props.onFilter();
    } else {
      this.props.setPublishTypeValue({ paymentStatus: "paid" });
      this.props.onFilter();
    }
  };

  render() {
    const { status, fromDate, toDate, name } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className="form form--horizontal"
              onSubmit={(e) => e.preventDefault()}
            >
              <Col md={3} lg={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Title</span>
                  <div className="form__form-group-field">
                    <Field
                      name="property_name"
                      component="input"
                      type="text"
                      onChange={this.onChangeName}
                      className="properties__filer-border properties__status-select"
                    />
                  </div>
                </div>
              </Col>
              <Col md={3} lg={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Status</span>
                  <div className="form__form-group-field">
                    {
                      <Field
                        name="status"
                        component={renderSelectField}
                        options={[
                          { value: "", label: "All" },
                          { value: "verifying", label: "Verifying" },
                          { value: "rejected", label: "Rejected" },
                          { value: "available", label: "Available" },
                          { value: "verified", label: "Certified" },
                          { value: "none-verified", label: "Un-Certified" },
                          { value: "closing", label: "Closing" },
                          { value: "closed", label: "Closed" },
                          { value: "confirmed", label: "Confirmed" },
                          { value: "ongoing", label: "On-going" },
                          { value: "paying", label: "Paying" },
                        ]}
                        className="properties__status-select"
                        onChange={this.onChangeStatus}
                        defaultValue={status}
                      />
                    }
                  </div>
                </div>
              </Col>
              <Col md={3} lg={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">From</span>
                  <div className="form__form-group-field">
                    <Field
                      name="fromDate"
                      component={renderDatePickerField}
                      className="property__filter__input"
                      onChange={this.onChangeFromDate}
                      value={fromDate}
                    />
                    <div className="form__form-group-icon property__filter__calender">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={3} lg={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">To</span>
                  <div className="form__form-group-field">
                    <Field
                      name="toDate"
                      component={renderDatePickerField}
                      className="property__filter__input"
                      onChange={this.onChangeToDate}
                      value={toDate}
                      minDate={moment(fromDate, "YYYY-MM-DD").toDate()}
                    />
                    <div className="form__form-group-icon property__filter__calender">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2} lg={2}>
                <Button
                  name="normal"
                  color="primary"
                  action="submit"
                  size="sm"
                  onClick={() => this.handleSubmit(this.state.submit1)}
                  style={{
                    boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
                  }}
                >
                  Filter
                </Button>
              </Col>
              <Col md={2} lg={2}>
                <Button
                  name="red"
                  color="danger"
                  action="submit"
                  size="sm"
                  onClick={() => this.handleSubmit(this.state.submit2)}
                  style={{
                    boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
                  }}
                >
                  Red
                </Button>
              </Col>
              <Col md={2} lg={2}>
                <Button
                  name="blue"
                  color="info"
                  action="submit"
                  size="sm"
                  onClick={() => this.handleSubmit(this.state.submit3)}
                  style={{
                    boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
                  }}
                >
                  Blue
                </Button>
              </Col>
              <Col md={2} lg={2}>
                <Button
                  name="green"
                  color="success"
                  action="submit"
                  size="sm"
                  onClick={() => this.handleSubmit(this.state.submit4)}
                  style={{
                    boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
                  }}
                >
                  Green
                </Button>
              </Col>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(Filter));
