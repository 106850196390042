import * as CONSTANTS from './constants'; 

const handleDetailParameter = (id) => {
    return {
        type: CONSTANTS.PARAMETER_DETAIL_FETCH_REQUESTED,
        id,
    }
}

const handleUpdateParameter = (item) => {
    return {
        type: CONSTANTS.PARAMETER_UPDATE_FETCH_REQUESTED,
        item,
    }
}

export {
    handleDetailParameter,
    handleUpdateParameter
} 

