
import * as CONSTANTS from '../actions/constants';

const initialState = {
    items: [],
    rootItems: [],
    item: null,
    loading: true,
    error: false,
    page: 0,
    rowsPerPage: 10,
    currentPage: 0,
    count: 0,
    order: []
}

export default function (state = initialState, action) {
  switch (action.type) {
      case CONSTANTS.LOADING:
      case CONSTANTS.PARAMETER_FETCH_REQUESTED:
        return { ...state, loading: true };
      case CONSTANTS.PARAMETER_FETCH_SUCCEEDED:
        return { ...state, items: action.payload, count: action.payload.length || 0, loading: false, error: false };
      case CONSTANTS.PARAMETER_FETCH_FAILED: 
        return { ...state, loading: false, error: true };
      case CONSTANTS.HANDLE_CHANGE_TABLE:
          return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  
