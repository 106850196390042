export const validate = (inputs) => {
  const errors = {};
  if (!inputs.max_value) {
    errors.max_value = 'Enter your price sale';
  }
  if (!inputs.rent_max_value) {
    errors.rent_max_value = 'Enter your price rent';
  }
  return errors;
};


export const requiredInput = (input) =>
  input ? undefined : `Input is required`;
