import { takeLatest, call, put, cancelled, take } from 'redux-saga/effects'
import { history, PATH_URL } from '../../../../../shared/helpers/index';

import * as CONSTANTS from '../actions/constants';

function* detailParameter ({id}) {
  try {
    yield put({ type: CONSTANTS.DETAIL_LOADING });
    const items = JSON.parse(localStorage.getItem('items')) || [];
    const item = items.find(item => {
      return item.id === id;
    });
    yield put({ type: CONSTANTS.PARAMETER_DETAIL_FETCH_SUCCEEDED, payload: item });
  } catch (error) {
    yield put({ type: CONSTANTS.PARAMETER_DETAIL_FETCH_FAILED, error })
  } finally {
    if(yield cancelled()) {
        yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* updateParameter ({item}) {
  try {
    yield put({ type: CONSTANTS.UPDATE_LOADING });
    yield put({ type: CONSTANTS.PARAMETER_UPDATE_FETCH_SUCCEEDED, payload: item });
    yield put(history.push(PATH_URL.PARAMETERS));
  } catch (error) {
    yield put({ type: CONSTANTS.PARAMETER_UPDATE_FETCH_FAILED, error })
  } finally {
    if(yield cancelled()) {
        yield call(history.push, PATH_URL.HOME);
    }
  }
}

function* detailParameterWatcher () {
    yield takeLatest(CONSTANTS.PARAMETER_DETAIL_FETCH_REQUESTED, detailParameter);
    yield takeLatest(CONSTANTS.PARAMETER_UPDATE_FETCH_REQUESTED, updateParameter);
}

export default detailParameterWatcher
