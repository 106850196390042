import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardBody, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import Collapse from '../../../../shared/components/Collapse';
import { APPOINTMENT_STATUS } from '../../../../shared/helpers/constants';

const DisplayStatus = (props) => {
  if (props.status === "upcomming") {
    return <FontAwesomeIcon className="ambassador__appointment-upcomming" icon={faCircle} />
  } else if (props.status === "visited") {
    return <FontAwesomeIcon className={`ambassador__appointment-${props.status}`} icon={faCheck} />
  }
  else {
    return <FontAwesomeIcon className={`ambassador__appointment-${props.status}`} icon={faCircle} />
  }
};

const renderAppointItem = data => {
  return data.map((item, key) => (
   
      <Row style={{ margin: "10px" }} key={key}>
        <Col md={4}>
          <DisplayStatus status={APPOINTMENT_STATUS[item.status]} />
          <span className="ambassador__appointment-time">{item.beginTime}</span> - <span style={{ fontWeight: "bold" }}>{item.endTime}</span>
        </Col>
        <Col md={3}>
          <span className="ambassador__appointment-description">{item.note}</span>
        </Col>
        <Col md={5}>
          <span className="ambassador__appointment-description">{item.address}</span>
        </Col>
      </Row>
  ));
}

const Appointments = (props) => {
  const { t, appointmennts } = props;
  return (
    <Row>
      <Card>
        <CardBody>
          <h3 className="page-title">{t('ambassador_detail.appointments.title')}</h3>
          {appointmennts && appointmennts.map((appointDay, index) => {
            let dataT = appointDay.data;
            if(dataT.length === 0) return;
            return (<div key={index}>
              <hr />
              <Collapse title={appointDay.date} open={true}>
                <Col style={{ margin: "10px" }}>
                  <Row>
                    <Col md={4}>
                      <span className="ambassador__appointment-header">{t('ambassador_detail.appointments.header_time')}</span>
                    </Col>
                    <Col md={3}>
                      <span className="ambassador__appointment-header">{t('ambassador_detail.appointments.header_description')}</span>
                    </Col>
                    <Col md={5}>
                      <span className="ambassador__appointment-header">{t('ambassador_detail.appointments.header_address')}</span>
                    </Col>
                  </Row>
                  {renderAppointItem(dataT)}


                </Col>
              </Collapse>
            </div>)
          })}
        </CardBody>
      </Card>
    </Row>
  )
}

Appointments.propTypes = {
  t: PropTypes.func.isRequired,
  appointmentsList: PropTypes.arrayOf(PropTypes.object),
}

export default withTranslation('common')(Appointments);
