import { call, put, takeEvery, cancelled, select } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import { fetchEmailTemplate, deleteEmailTemplateAPI } from '../services';

export const getData = (state) => state.emailTemplateReducer;

function* getEmailTemplate() {
    try {
        yield put({ type: ACTIONS.LOADING });
        const { pageNumber, itemPerPage } = yield select(getData);
        const result = yield call(fetchEmailTemplate, pageNumber, itemPerPage);
        const datas = result.data.data;
        let resultsElement=[];
        datas.forEach(element => {
            if(element.type){
                const characters = element.type.split("_")
                element.type = characters[0] + " " + characters[1];
            }
            resultsElement.push(element);
        });
        yield put({
            type: ACTIONS.EMAIL_TEMPLATE_FETCH_SUCCEEDED, payload: {
                data: resultsElement,
                count: (result.data.total || {}).count || 0,
            }
        });
    } catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_FETCH_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}

function* deleteEmailTemplate(action) {
    try {
        const { id } = action.payload;
        yield call(deleteEmailTemplateAPI, id);
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_FETCH_REQUESTED });
    }
    catch (error) {
        yield put({ type: ACTIONS.EMAIL_TEMPLATE_DELETE_FAILED, payload: { error } });
    } finally {
        if (yield cancelled()) {
            yield call(history.push, PATH_URL.HOME);
        }
    }
}
function* emailTemplateWatcher() {
    yield takeEvery([
        ACTIONS.EMAIL_TEMPLATE_FETCH_REQUESTED,
        ACTIONS.EMAIL_TEMPLATE_CHANGE_PAGE,
        ACTIONS.EMAIL_TEMPLATE_CHANGE_ROW_PER_PAGE,
    ], getEmailTemplate);

    yield takeEvery(ACTIONS.EMAIL_TEMPLATE_DELETE_REQUESTED, deleteEmailTemplate);
}

export default emailTemplateWatcher;
