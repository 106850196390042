import React, { useState } from 'react';
import { Input } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';

const ChatField = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  return (
    <div className="chat__text-field">
      <form className="chat__form" onSubmit={(e) => { e.preventDefault(); onSendMessage(message); setMessage(''); }}>
        <Input className="chat__field-input" name="chatField" placeholder="Type here…" value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="chat__field-controls">
          <button
            type="button"
            className="chat__icon-button"
            onClick={(e) => { e.preventDefault(); onSendMessage(message); setMessage(''); }}
          >
            <SendIcon />
          </button>
        </div>
      </form>
    </div>
  )
};

export default ChatField;
