import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index';

const createEmailTemplateDetail = (data) => {
    return apiCaller(API_URL.EMAIL_TEMPLATE_CREATE, HTTP_METHOD.POST, {
        ...data,
    });
    
}

const loadEmailType = () => {
    return apiCaller(API_URL.EMAIL_TYPE, HTTP_METHOD.GET);
}

export { createEmailTemplateDetail, loadEmailType };