export const CREATE_EXPLORE_SECTION = "CREATE_EXPLORE_SECTION";
export const CREATE_EXPLORE_SECTION_SUCCESS = "CREATE_EXPLORE_SECTION_SUCCESS";
export const CREATE_EXPLORE_SECTION_FAILED = "CREATE_EXPLORE_SECTION_FAILED";
export const CLOSE_EXPLORE_SECTION_MODAL = "CLOSE_EXPLORE_SECTION_MODAL";
const closeModal = () => {
  return {
    type: CLOSE_EXPLORE_SECTION_MODAL
  };
};

const handleCreate = data => {
  return {
    type: CREATE_EXPLORE_SECTION,
    data
  };
};

const createSuccess = () => {
  return {
    type: CREATE_EXPLORE_SECTION_SUCCESS
  };
};

const createFail = error => {
  return {
    type: CREATE_EXPLORE_SECTION_FAILED,
    error
  };
};

export { handleCreate, createSuccess, createFail, closeModal };
