import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { withRouter } from 'react-router-dom';
import NotificationModal from '../../../shared/components/NotificationModal';
import CurrenciesCreateForm from './components';
import { handleCreateCurrencies, fetchListCurrencyParams, toggleNotification } from './redux/actions';
import { PATH_URL } from '../../../shared/helpers/path';

class CurrenciesCreate extends Component {
  componentDidMount() {
    this.props.fetchListCurrencyParams()
  }
  handleSubmit = event => {
    event.preventDefault();
    const { handleCreateCurrencies, errors, form } = this.props;

    if (errors && Object.keys(errors).length === 0) {
      if (form && form.currenciesForm && form.currenciesForm.values && !form.currenciesForm.syncErrors) {
        let data = { ...form.currenciesForm.values.table, rentMaxValue: form.currenciesForm.values.rentMaxValue, maxValue: form.currenciesForm.values.maxValue };
        handleCreateCurrencies(data);
      }
    }
  }

  handleBack = event => {
    event.preventDefault();

    const { history } = this.props;
    history.push(PATH_URL.CURRENCIES);
  }

  render() {
    const { form: { currency }, currenciesCreate, toggleNotification } = this.props;

    return (
      <>
        <CurrenciesCreateForm
          currency={currency}
          handleBack={this.handleBack}
          currenciesCreate={currenciesCreate}
          handleSubmit={this.handleSubmit} />
        {currenciesCreate.error &&
          <NotificationModal
            isOpen={true}
            color={"danger"}
            message={currenciesCreate.errorInfo.message || ''}
            toggle={toggleNotification}
          />}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    currencys: state.currencys,
    currenciesCreate: state.currentiesCreateReducer,
    form: state.form,
    errors: getFormSyncErrors("currencyForm")(state),
  }
}
const mapDispatchToProps = dispatch => {
  return {
    handleCreateCurrencies: (data) => { dispatch(handleCreateCurrencies(data)) },
    fetchListCurrencyParams: () => { dispatch(fetchListCurrencyParams()) },
    toggleNotification: () => { dispatch(toggleNotification()) },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrenciesCreate));