import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import Confirm from "../../../../shared/components/Confirm";
import { connect } from 'react-redux';
import { getPackages, configPackages } from '../redux/actions/fetchTransactionFee'
import { PAYMENT_PARAMETER } from '../../../../shared/helpers/constants';
import { convertPackageArrToPackageObj, isDecimalWithTwoDigistAfter } from '../../../../shared/helpers/utils';
class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      packagesObject: null,
      enableFields: [],
      values: {},
      selectedPackage: null,
      selectedItem: null,
    }
  }

  componentDidMount = () => {
    const { packages } = this.props;
    if (packages.length) {
      this.renderData();
    } else {
      this.props.getPackages();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { packages } = this.props;
    if (packages.length && (packages !== prevProps.packages)) {
      this.renderData();
    }
  }

  renderData = () => {
    const { packages } = this.props;
    if (packages.length) {
      const packagesObject = convertPackageArrToPackageObj(packages);
      let values = {};
      PAYMENT_PARAMETER.map(item => {
        values[item.key] = packagesObject[item.package_type][item.property_name];
      })
      this.setState({ packagesObject, values });
    }
  }

  confirmSubmit = () => {
    const { selectedPackage, selectedItem, values } = this.state;
    if (selectedPackage && selectedItem) {
      const payload = {
        package_id: selectedPackage._id,
        config_property_name: selectedItem.property_name,
        value: values[selectedItem.key]
      }
      this.props.configPackages(payload);
      let enableFields = this.state.enableFields;
      const indexOf = enableFields.indexOf(selectedItem.key);
      if (indexOf > -1) {
        enableFields.splice(indexOf, 1);
        this.setState({ enableFields });
      }
    }
    this.setState({ isOpen: false, selectedItem: null, selectedPackage: null });
  }

  cancelSave = () => {
    this.setState({ isOpen: false });
  }

  renderInputFields = () => {
    const { packagesObject, enableFields, values } = this.state;
    const { t } = this.props

    if (packagesObject) {
      return PAYMENT_PARAMETER.map(item => {

        const packageInfo = packagesObject[item.package_type];

        let unit = packageInfo[item.property_unit_name];
        if (unit == "MONTH") {
          unit = t(`transaction_fee.${unit}${+packageInfo[item.property_name] > 1 ? 'S' : ""}`)
        }

        const changeStatus = async () => {
          const enableFields = this.state.enableFields;
          const indexOf = enableFields.indexOf(item.key);
          if (indexOf > -1) {
            enableFields.splice(indexOf, 1);
            const { values } = this.state;
            let newValues = {
              ...values,
              [item.key]: packageInfo[item.property_name],
            };
            this.setState({ values: newValues });
          } else {
            enableFields.push(item.key);
          }
          this.setState({ enableFields });
        }

        const onSubmit = () => {
          const { values } = this.state;
          if (values[item.key]) {
            this.setState({
              isOpen: true,
              selectedPackage: packagesObject[item.package_type],
              selectedItem: item
            })
          }
        }

        const onChangeValue = (event) => {
          const { values } = this.state;
          let value = event.target.value;
          if (isDecimalWithTwoDigistAfter(value)) {
            let newValues = {
              ...values,
              [item.key]: value,
            };
            this.setState({ values: newValues });
          }
        }

        const disableInputProps = {
          disabled: true,
          type: "text",
          className: "properties__filer-border properties__status-select",
          value: t(`transaction_fee.${item.key}_description`, {
            value: packageInfo[item.property_name],
            unit,
          }),
          initValue: packageInfo[item.property_name],
        }

        const enableInputProps = {
          name: item.key,
          disabled: false,
          type: "text",
          className: "properties__filer-border properties__status-select",
          value: values[item.key],
          initValue: packageInfo[item.property_name],
          onChange: (e) => onChangeValue(e)
        }

        const saveButtonProps = {
          disabled: values[item.key] == packageInfo[item.property_name],
          color: "primary",
          onClick: onSubmit,
          className: "mb-0",
          size: "sm"
        }

        return (
          <Row key={item.key} className="param-input-wrapper">
            <Col md={3} className="m-auto">
              <span>{t(`transaction_fee.${item.key}_input_name`)}</span>
            </Col>
            <Col md={5} className="m-auto">
              {!enableFields.includes(item.key) && <input {...disableInputProps} />}
              {enableFields.includes(item.key) && <input {...enableInputProps} />}
            </Col>
            <Col md={4} className="m-auto">
              <Button className="mb-0" size="sm" onClick={changeStatus}>
                {!enableFields.includes(item.key) ? t('transaction_fee.edit') : t('transaction_fee.cancel')}
              </Button>
              <Button {...saveButtonProps}>{t('transaction_fee.save')}</Button>
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <CardBody>
          <form className="form form--horizontal">
            <Confirm
              toggle={this.cancelSave}
              isOpen={this.state.isOpen}
              title={"Notification"}
              message={t('transaction_fee.confirmMessage')}
              confirm={this.confirmSubmit} />
            <Container>
              {this.renderInputFields()}
            </Container>
          </form>
        </CardBody>
      </Card>
    );
  }
}

Transaction.propTypes = {
  value: PropTypes.number,
  onSaveSaleFee: PropTypes.func,
  onSaveRentalFee: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    getPackages: () => { dispatch(getPackages()) },
    configPackages: (payload) => { dispatch(configPackages(payload)) },
  }
}

export default connect(
  state => ({
    packages: state.transactionFeeReducer.packages,
  }), mapDispatchToProps
)(reduxForm({
  form: "TransactionFeeForm",
  enableReinitialize: true,
})(withTranslation("common")(Transaction)));