import momment from 'moment';
import { apiCaller, API_URL, HTTP_METHOD,  } from '../../../../../shared/helpers/index';
import {FORMAT_DATE} from '../../../../../shared/helpers/constants'

const fetchData = (itemPerPage, pageNumber, sort, desc, email, status) => {

    let params = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage
    };
    if(sort !== "") {
        params.sort = sort;
        params.desc = desc;
    }
    if(email !== "") {
        params.name = email;
    
    }
    if(status !== "") {
        params.status = status;
    
    }

    return apiCaller(API_URL.GET_USERS, HTTP_METHOD.GET, null, params)
}

const fetchDataCount = () => {
    return apiCaller(API_URL.GET_USERS, HTTP_METHOD.GET, null)
}

const formatData = (data) => {
    let formatData = [];
    
    data.forEach(element => {
            element.add_time = momment(element.add_time).format(FORMAT_DATE);
            element.first_name = element.first_name;
            element.last_name = element.last_name;
            element.status = !element.verified ? 'unverified' : element.status;
            formatData.push(element); 
    });
    return formatData;
}

const activeUser = (id) => {
    return apiCaller(API_URL.ACTIVE_USER+`${id}`, HTTP_METHOD.PUT)
}

const deactiveUser = (id) => {
    return apiCaller(API_URL.DEACTIVE_USER+`${id}`, HTTP_METHOD.PUT)
}

const formatAfterAction = (datas, id, status) => {
    let clones = [];
    datas.forEach(element => {
        if(element._id ===id) {
            element.status = status;
        }
        clones.push({...element});
    });

    return clones;
   
}
export { fetchData, formatData,fetchDataCount, activeUser, deactiveUser, formatAfterAction }; 