import { createStore, applyMiddleware, compose } from 'redux';
import { reducer } from './rootReducer';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

/*eslint-disable */
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&  
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
/*eslint-enable */

export const store = createStore(
    reducer,
    composeSetup(applyMiddleware(sagaMiddleware)),
);
