import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link, withRouter } from "react-router-dom";
import { currencyFormat, currencySymbol, PATH_URL} from "../../../shared/helpers";
import { withTranslation } from 'react-i18next';

const PaymentDetail = (props) => {
  const element = props.location.state.data;
  const t = props.t;

  const translatePayment = `payment.${element.model_type}`;

  return (
    <Col md={12} >
      <Card>
        <CardBody>
          <div className="card__title " >
            <h5 className="bold-text card__title-center">Payment Detail</h5>
          </div>

          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.name")} </strong></label>
            </Col>
            <Col sm="8">
              <label>{`${element.first_name} ${element.last_name}`}</label>
            </Col>
          </Row>
          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.amount")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{element.prices.amount} {element.prices.currency}</label>
              {/* <label>{`${element.transactions[0].amount.total} ${element.transactions[0].amount.currency}`}</label> */}
            </Col>
          </Row>
          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.total")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{element.total} {element.prices.currency}</label>
            </Col>
          </Row>

          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.date")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{element.add_time}</label>
            </Col>
          </Row>

          {/* <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.description")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{element.transactions[0].description}</label>
            </Col>
          </Row> */}

          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.description")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{t(translatePayment)}</label>
            </Col>
          </Row>

          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.status")}: </strong></label>
            </Col>
            <Col sm="8">
              <label>{element.status}</label>
            </Col>
          </Row>

          <Row className="ambassador__infor">
            <Col sm="1">
              <label><strong>{t("payment.property")}: </strong></label>
            </Col>
            <Col sm="8">
              <Link
                to={{
                  pathname: `${PATH_URL.PROPERTY}/${element.property._id}`,
                }} >
                {element.property.title}
            </Link>
            </Col>
          </Row>

        </CardBody>
      </Card>
    </Col>
  )
};

export default withTranslation('common')(withRouter(PaymentDetail));
