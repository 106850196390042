import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import FormCreateAmbassador from './components/FormCreateAmbassador';
import { handleCreate, createFail, createSuccees, closeModal } from './redux/actions/createAmbassadorAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NotificationModal from '../../../shared/components/NotificationModal';
import Loading from '../../../shared/components/Loading';
import {history} from '../../../shared/helpers/index';


class CreateAmbassador extends Component {

     closeModalSuccess = (error) => {
        this.props.closeModal();
        if(!error)
        history.goBack();
    }

    render() {
        const { loading, message, shownotification, error, closeModal} = this.props;
       
        return (
            <Col md={12}>
            <Loading isOpen={loading} />
                <NotificationModal 
                 isOpen={shownotification}
                 color={error ? "danger" : "success"}
                 message={message}
                 toggle={()=>{this.closeModalSuccess(error)}}
                />
                <Card>
                    <CardBody>
                        <FormCreateAmbassador handleCreate={this.props.handleCreate} />
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.createAmbassadorReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleCreate: (data) => { dispatch(handleCreate(data)) },
        createSuccees: () => { dispatch(createSuccees()) },
        createFail: (error) => { dispatch(createFail(error)) },
        closeModal: () => {dispatch(closeModal())}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CreateAmbassador));
