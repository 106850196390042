import React, { Fragment, Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import TopItem from './components/TopItem';
import BasicCarousel from "./components/BasicCarousel";
import PropertyCarouselItem from "./components/PropertyCarouselItem";
import AgentCarouselItem from "./components/AgentCarouselItem";
import {userCountRequestStart} from './redux/actions/index';
import Loading from '../../../shared/components/Loading';

class Dashboard extends Component {

  renderTopItems = () => {
    const { topItemData } = this.props;

    let items = topItemData.map((item, index) => {
      return <TopItem
        index={index}
        key={index}
        number={item.number}
        icon={item.icon}
        name={item.name}
        selectAction={this.selectTop}
        path={item.path}
        pathState={item.pathState}
      />
    });
    return <Fragment>{items}</Fragment>
  }

  renderAgents = () => {
    const { agentsData } = this.props;
    let agents = agentsData.map((item, index) => {

      return <AgentCarouselItem key={index} avatarImg={item.avatarImg} name={item.name} address={item.address} numberAsignProperties={item.numberAsignProperties} id={item.id}/>
    });

    return agents;
  }

  renderProperties = () => {
    const { verifyingProperties } = this.props;
    let properties = verifyingProperties.map((item, index) => {
      return <PropertyCarouselItem key={index} verify_type={item.verify_type} media={item.image} name={item.title} price={item.price} address={item.address} id={item.id}/>
    });
    return properties;
  }

  componentDidMount() {
    this.props.usersCount();
  }

  render() {

    const { t, showLoading } = this.props;
    const properties = this.renderProperties();
    const agents = this.renderAgents();

    return (
      <Container className="dashboard">
          <Loading isOpen={showLoading} />
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('dashboard.page_title')}</h3>
          </Col>
        </Row>

        <Row>
          {this.renderTopItems()}
        </Row>

        <Row>
          {properties.length > 0 && <BasicCarousel items={properties} title={"Verifying Properties"} />}
          {agents.length > 0 && <BasicCarousel items={agents} title={"Most Active Ambassadors"} />}

        </Row>


      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.dashboardReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    usersCount: () => { dispatch(userCountRequestStart()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Dashboard));
