export const PATH_URL = {
  // For dev
  // UA_BASE:
  //   process.env.NODE_ENV === "production"
  //     ? "https://dev.urbanassets.net/"
  //     : "http://localhost:3000/",
  // BASE:
  //   process.env.NODE_ENV === "production"
  //     ? "http://ubbo.bkitsolution.com/"
  //     : "http://localhost:3010/",
  // DEV_EDIT:
  //   process.env.NODE_ENV === "production"
  //     ? "http://dev.urbanassets.net/boedit/"
  //     : "http://localhost:3000/boedit/",

  // For Prod
  UA_BASE:
   process.env.NODE_ENV === "production"
    ? "https://urbanassets.properties/"
    : "http://localhost:3000/",
  BASE:
    process.env.NODE_ENV === "production"
      ? "https://urbanassets.company/"
      : "http://localhost:3010",
  DEV_EDIT:
    process.env.NODE_ENV === "production"
      ? "https://urbanassets.properties/boedit/"
      : "http://localhost:3000/boedit/",
  AMBASSADORS: "/company/ambassadors",
  AMBASSADORS_CREATE: "/company/ambassadors/create",
  AMBASSADORS_DETAIL: "/company/ambassadors/:id",
  CHATS: "/company/chats",
  CURRENCIES: "/company/currencies",
  CURRENCIES_CREATE: "/company/currencies/create",
  CURRENCIES_EDIT: "/company/currencies/edit/:id",
  DASHBOARD: "/company/dashboard",
  EMAILS: "/company/emails",
  PARAMETERS: "/company/parameters",
  PARAMETERS_CREATE: "/company/parameters/create",
  PARAMETERS_DETAIL: "/company/parameters/:id",
  PAYMENT: "/company/payment",
  PROPERTY: "/company/property",
  SELLERS: "/company/sellers",
  HOME: "/",
  COMPANY: "/company",
  RESET: "/company/password/reset",
  PROPERTYDETAIL: "/company/property/:id",
  SELLERETAIL: "/company/sellers/:id",
  PROPERTY_DETAIL_PATH: "/company/property/",
  ACCOUNTS: "/company/accounts",
  DEFAULT: "/",
  AMBASSADORS_DETAIL_PATH: "/company/ambassadors/",
  PAYMENT_DETAIL: "/company/payment/:id",
  EMAIL_TEMPLATE: "/company/email-template",
  EMAIL_TEMPLATE_DETAIL: "/company/email-template/:id",
  EMAIL_TEMPLATE_CREATE: "/company/email-template/create",
  SETTINGS_TRANSACTION_FEE: "/company/settings/transaction-fee",
  EXPLORE_SECTIONS: "/company/explore-sections",
  EXPLORE_SECTIONS_CREATE: "/company/explore-sections/create",
  EXPLORE_SECTIONS_DETAIL: "/company/explore-sections/:id"
};
