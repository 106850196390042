import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { compose, withProps, withStateHandlers } from 'recompose';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs,
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import CloseIcon from 'mdi-react/CloseIcon';
import {GOOGLE_MAP_KEY} from '../../../../shared/helpers/constants';


const MapWithAMarker = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '360px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  
  <GoogleMap
    defaultZoom={8}
    defaultCenter={props.defaultCenter}
  >

    
    {props.propertyLocation && props.propertyLocation.map( (map, index) => {
        return <Marker key={index} position={{ lat: map.location.lat, lng: map.location.lon }} 
        onClick={()=>{props.onToggleOpen(index)}}
        >
         
          {map.isOpen
            && (
              <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
                <div className="map__marker-label">
                  <div className="map__marker-label-content">
                    <div className="map__maker-label-close" onClick={()=>{props.onToggleOpen(index)}}><CloseIcon /></div>
                    {map.title}
                  </div>
                </div>
              </InfoBox>
            )} 
        </Marker>
      })}
  </GoogleMap>
));

class OnMap extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      propertyLocation: props.propertyLocation
    }
  }
  

  render() {
    let { t } = this.props;
    let {propertyLocation} = this.state;
    
    let defaultCenter = propertyLocation.length > 0 ? { lat:propertyLocation[0].location.lat, lng: propertyLocation[0].location.lon } :{ lat: 16.061279, lng: 108.229561 };
    let onToggleOpen = (index) => {
      
      propertyLocation[index].isOpen = ! propertyLocation[index].isOpen
      this.setState({propertyLocation})
    }
    return (
      <Card>
        <CardBody>
          <div>
            <h3 className="page-title">{t('ambassador_detail.map.title')}</h3>
            <hr />
            <div>
              {propertyLocation.length > 0 && <MapWithAMarker
                propertyLocation = {propertyLocation}
                defaultCenter={defaultCenter}
                onToggleOpen={onToggleOpen}
              />}
              
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
OnMap.defaultProps = {
  mapList: [
    {
      lat: 16.061279,
      lng: 108.229561,
      address: "346-2/9",
    },
    {
      lat: 16.061270,
      lng: 108.209161,
      address: "45-2/9",
    }
  ],
  isMarkerShown: true
}

OnMap.propTypes = {
  t: PropTypes.func.isRequired,
  mapList: PropTypes.arrayOf(PropTypes.object),
  isMarkerShown: PropTypes.bool
}

export default withTranslation('common')(OnMap);
