import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index'
import moment from 'moment';
import { compareDesc, compareAsc } from 'date-fns';

function getDefaultPrice(prices) {
  let priceLable = "";

  prices.forEach(pri => {

    if (pri.is_default) {
      priceLable = pri.price + " " + pri.currency_code.toUpperCase();
    }
  });

  return priceLable;
}

const fetchDetailAmbassador = (id) => {
  return apiCaller(`${API_URL.AGENT_DETAIL}/${id}`, HTTP_METHOD.GET)
}

const formatAmbassdor = (rawData) => {


  const profile = {
    id: rawData._id,
    fullName: `${rawData.first_name} ${rawData.last_name}`,
    email: rawData.email,
    address1: rawData.address1,
    address2: rawData.address2,
    city: rawData.city,
    avatar: rawData.avatar,
    phone: rawData.phone_number,
    activeDate: moment(rawData.add_time).format('YYYY-MM-DD'),
    state: rawData.state,
    zip_code: rawData.zip_code,
    status: rawData.status
  }

  let followingDeals = [];
  let propertyLocation = [];
  let assigned_properties = rawData.assigned_properties;
  assigned_properties.forEach(item => {
    const {property} = item;
    const {location} = property;
    propertyLocation.push({location, isOpen: false, title: property.title });
    followingDeals.push({
      image: property.galleries.length > 0 ? property.galleries[0].id : "",
      title: property.title,
      address: property.address,
      price: getDefaultPrice(property.prices),
      id: property._id,
      verify_type: property.verify_type
    })
  });

  let appointments = [];
  let rawAppointment = rawData.appointments;

  for (var key in rawAppointment) {
    if (rawAppointment.hasOwnProperty(key)) {
       var obj = rawAppointment[key];
      //  var dateKey = moment(key).format('dddd, MMMM Do YYYY');
       let dateKey = new Date(key).toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: 'Asia/Bangkok'})
       var appointItems = obj ? formatAppointItems(obj) : [];

      appointments.push({date: dateKey, data: appointItems})
    }
  }

  let appointmentsSort = appointments.sort((a, b) => {
    return compareDesc(new Date(a.date), new Date(b.date));
  });

  return { profile: profile, followingDeal: followingDeals, appointments: appointmentsSort, propertyLocation: propertyLocation };
}

const formatAppointItems = (obj) => {
  let appointItems = [];
  let objSort = obj.sort((a, b) => {
    return compareAsc(new Date(a.begin_time), new Date(b.begin_time));
  });
  objSort.forEach(item => {
    appointItems.push({
      // beginTime: moment(item.begin_time).format('hh:mm A'),
      // endTime: moment(item.end_time).format('hh:mm A'),
      beginTime: new Date(item.begin_time).toLocaleTimeString('en-US', {timeZone: 'Asia/Bangkok', hour12: true, hour: '2-digit', minute: '2-digit'}),
      endTime: new Date(item.end_time).toLocaleTimeString('en-US', {timeZone: 'Asia/Bangkok', hour12: true, hour: '2-digit', minute: '2-digit'}),
      note: item.note,
      property_title: item.property.title,
      address: item.property.address,
      status: item.status,
      property_id: item.property_id
    });
  });
  return appointItems;
}

const takeOver = (propertyId, ambassadorId) => {
  return apiCaller(API_URL.TAKE_OVER_PROPERTY, HTTP_METHOD.POST, {
    user_id: ambassadorId,
    property_id: propertyId
  })
}

const activeAmbassador = (id) => {
  return apiCaller(`${API_URL.ACTIVE_AMBASSADOR}/${id}`, HTTP_METHOD.PUT)
}

const deactiveAmbassador = (id) => {
  return apiCaller(`${API_URL.DEACTIVE_AMBASSADOR}/${id}`, HTTP_METHOD.PUT)
}

const takeOverSuccessFormatData = (ambassador, id) => {
  let { followingDeal } = ambassador;
  let newFollowingDeals = [];
  followingDeal.forEach(element => {
    if (element.id !== id) {
      newFollowingDeals.push(element)
    }
  });
  ambassador.followingDeal = newFollowingDeals;
  return ambassador;
}

const takeOverSuccessAppoinments = (ambassador, propertyId) => {
  let { appointments } = ambassador;
  if(!!appointments) {
    const newAppointments = appointments.map(appointmentItem => {
      const newData = [];
      appointmentItem.data.forEach(value => {
        if(value.property_id !== propertyId) {
          newData.push(value);
        }
      });
      return {date: appointmentItem.date, data: newData};
    })
    ambassador.appointments = newAppointments;
  }
  return ambassador;
}

const asignAgent = (propertyId, agentId) => {
  return apiCaller(API_URL.ASIGN_AGENT, HTTP_METHOD.POST, {
      property_id: propertyId,
      user_id: agentId
  })

}

export {
  fetchDetailAmbassador,
  formatAmbassdor,
  takeOver,
  activeAmbassador,
  deactiveAmbassador,
  takeOverSuccessFormatData,
  takeOverSuccessAppoinments,
  asignAgent
};
