
import * as CONSTANTS from '../actions/constants';

const initialState = {
  result: { data: [], count: 0 },
  showLoading: false,
  error: false,
  page: 0,
  itemPerPage: 5,
  pageNumber: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.LOADING:
    case CONSTANTS.FETCH_CURRENCY_REQUEST:
      return { ...state, showLoading: true };
    case CONSTANTS.FETCH_CURRENCY_SUCCESS:
      return { ...state, result: action.data, showLoading: false, };
    case CONSTANTS.FETCH_CURRENCY_FAILURE:
      return { ...state, loading: false, error: true };
    case CONSTANTS.CHANGE_PAGE:
      return { ...state, pageNumber: action.pageNumber }
    case CONSTANTS.CHANGE_ITEM_PER_PAGE:
      return { ...state, itemPerPage: action.itemPerPage }
    case CONSTANTS.REMOVE_CURRENCY:
      return { ...state, ...action.id };
    default:
      return state;
  }
}

