import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDropZoneField from '../../../../shared/components/form/DropZone';
import { listCountry } from '../../../../shared/helpers/constants';
import iso639 from "iso-639-1";
import { Link } from "react-router-dom";
import { validNumber } from '../../../../shared/helpers/utils';

class FormCreateAmbassador extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  submit = (values) => {

    this.props.handleCreate(values)
  }


  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;
    const listCodes = iso639.getAllCodes();
    const listLanguages = iso639.getLanguages(listCodes);
    let dropLans = [];
    listLanguages.forEach(element => {
      dropLans.push({
        value: element.code,
        label: element.name ? element.name.toUpperCase() : element.name
      })
    });

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={handleSubmit(this.submit)}>
              <div className="form__form-group-img">
                <div className="form__form-group-field-img">
                  <Field
                    name="files"
                    component={renderDropZoneField}

                  />
                </div>
              </div>
              <div className="form__form-group" style={{ marginTop: "40px" }}>
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="first_name"
                    component="input"
                    type="text"
                    placeholder="First Name *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="last_name"
                    component="input"
                    type="text"
                    placeholder="Last Name *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Phone</span>
                <div className="form__form-group-field">
                  <Field
                    name="phone_number"
                    component="input"
                    type="tel"
                    placeholder="Phone *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">E-mail</span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Email *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Init Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component="input"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password *"
                    required
                  />
                  <button
                    type="button"
                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                    onClick={e => this.showPassword(e)}
                  ><EyeIcon />
                  </button>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Address line 1</span>
                <div className="form__form-group-field">
                  <Field
                    name="address1"
                    component="input"
                    type="text"
                    placeholder="Address line 1 *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Address line 2</span>
                <div className="form__form-group-field">
                  <Field
                    name="address2"
                    component="input"
                    type="text"
                    placeholder="Address line 2"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">City</span>
                <div className="form__form-group-field">
                  <Field
                    name="city"
                    component="input"
                    type="text"
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">State/Province/Region</span>
                <div className="form__form-group-field">
                  <Field
                    name="state"
                    component="input"
                    type="text"
                    placeholder="State/Province/Region"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Zipcode</span>
                <div className="form__form-group-field">
                  <Field
                    name="zip_code"
                    component="input"
                    type="text"
                    placeholder="Zipcode"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Country</span>
                <div className="form__form-group-field">
                  <Field
                    name="country_code"
                    component={renderSelectField}
                    options={listCountry}
                    placeholder="Country *"
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Speaking language</span>
                <div className="form__form-group-field">
                  <Field
                    name="language_code"
                    component={renderSelectField}
                    options={dropLans}
                    placeholder="Speaking language *"
                    required
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="danger" type="submit">Submit</Button>
                {/* <Button type="button" onClick={reset}>
                  Cancel
                </Button> */}
                <Link className="btn btn-primary" to="/company/ambassadors">Cancel</Link>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(withTranslation('common')(FormCreateAmbassador));
