import { call, put, takeLatest } from 'redux-saga/effects'
import {
  USER_COUNT_REQUEST_START,
  USER_COUNT_REQUEST_SUCCESS,
  GET_VERIFYING_PROPERTIES,
  GET_CLOSED_PROPERTOES,
  GET_ACTIVE_AGENTS
} from '../actions/index';

import {
  countClosedProperties,
  countVerifyingProperties,
  countUsers,
  getActiveAgents,
  getListCurrencies
} from '../services/index';

export const getData = (state) => state.dashboardReducer;

function* loadAPI() {
  try {
    const currencies = yield call(getListCurrencies);
    const userCount = yield call(countUsers);
    yield put({ type: USER_COUNT_REQUEST_SUCCESS, data: userCount.data });

    const verifyingProperties = yield call(countVerifyingProperties);
    yield put({ type: GET_VERIFYING_PROPERTIES, data: {...verifyingProperties.data, currencies: currencies} });

    const closedProperties = yield call(countClosedProperties);
   
    yield put({ type: GET_CLOSED_PROPERTOES, data: closedProperties.data });

    const activeAgents = yield call(getActiveAgents);
   
    yield put({type: GET_ACTIVE_AGENTS, data: activeAgents.data.data})

  } catch (error) {
    console.log(error)
  }
}

function* dashboardWatcher() {
  yield takeLatest(USER_COUNT_REQUEST_START, loadAPI);
}

export default dashboardWatcher 
