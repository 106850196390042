import {
  apiCaller,
  API_URL,
  HTTP_METHOD
} from "../../../../shared/helpers/index";
import axios from "axios";

const createExploreSection = data => {
  let formatData = {
    description: data.description,
    order: Number(data.order),
    status: data.status,
    title: data.title,
    type: data.type,
    image: data.image
  };

  if (data.value) {
    formatData = { ...formatData, value: data.value };
  }

  return apiCaller(
    API_URL.CREATE_EXPLORE_SECTION,
    HTTP_METHOD.POST,
    formatData
  );
};

const createExploreSectionImageUrl = async data => {
  const formData = new FormData();

  if (data.image) {
    formData.append("images", data.image[0]);
    delete data["image"];
  }

  let token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return axios({
    method: HTTP_METHOD.POST,
    url: API_URL.UPLOAD_EXPLORE_SECTION_IMAGE,
    data: formData,
    timeout: 100000,
    headers
  }).catch(function(error) {
    throw error;
  });

  // return apiCaller(
  //   API_URL.UPLOAD_EXPLORE_SECTION_IMAGE,
  //   HTTP_METHOD.POST,
  //   formData,
  //   {
  //     external_url: true
  //   }
  // );
};

export { createExploreSection, createExploreSectionImageUrl };
