import React, { useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import ChatForm from "./components";
import Loading from "../../../shared/components/Loading";
import ChatRoomList from "./components/ChatRoomList";
import {
  getListChatRoom,
  toggleNotification,
  closeAgentModal,
  assignAgentToProperty,
  getFirebaseToken,
  resetFilterState,
} from "./redux/actions/chatAction";
import NotificationModal from "../../../shared/components/NotificationModal";
import AgentModal from "./components/AgentModal";
import ChatSearch from "./components/ChatSearch";

const Chats = ({
  t,
  getListChatRoom,
  loading,
  roomsData,
  messagesData,
  currentRoom,
  notificationType,
  notificationMessage,
  toggleNotification,
  showAgentsModal,
  closeAgentModal,
  assignAgentToProperty,
  getFirebaseToken,
  resetFilterState,
}) => {

  // const { roomState } = useSelector((state, ownProps) => ({ roomState: state.chatReducer.roomsData }));

  useEffect(() => {
    getListChatRoom(true, true);
    getFirebaseToken();
  }, []);

  useEffect(() => {
    return () => {
      resetFilterState();
    };
  }, []);

  // const chatRoomList = useMemo(() => {
  //   return <ChatRoomList listChatRoom={roomsData} />;
  // }, [roomsData]);

  return (
    <Container className="chat-container">
      <Loading isOpen={loading} />
      <NotificationModal
        isOpen={notificationType !== ""}
        color={notificationType}
        message={notificationMessage}
        toggle={toggleNotification}
      />
      <Row>
        <Col md={5}>
          <Card>
            <CardBody>
              <ChatSearch />
              <ChatRoomList listChatRoom={roomsData} />
              {/* {chatRoomList} */}
            </CardBody>
          </Card>
        </Col>
        <Col md={7}>
          <Card>
            <CardBody>
              <ChatForm
                messagesData={messagesData}
                roomData={currentRoom}
                currentUser={{
                  avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
                  name: "Roman Johanson",
                  userName: "dragonKing",
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AgentModal
        modalprop={showAgentsModal}
        closeModal={closeAgentModal}
        asignAgent={assignAgentToProperty}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.chatReducer,
  };
};
export default connect(
  mapStateToProps,
  {
    getListChatRoom,
    toggleNotification,
    closeAgentModal,
    assignAgentToProperty,
    getFirebaseToken,
    resetFilterState,
  }
)(withTranslation("common")(Chats));
