import React, { useState } from 'react';
import { Field } from 'redux-form';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import TextEditor from '../../../../shared/components/text-editor/TextEditor';

const renderTextEditor = ({ input, initValue }) => {
    return (
        <TextEditor
            {...input}
            initValue={initValue}
        />
    )
};

const EmailTemplateLangTab = ({ fields, templatesData, initialValues }) => {
    const [activeTab, setActiveTab] = useState('0');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <Nav tabs>
                {fields.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({ active: activeTab === `${index}` })}
                            onClick={() => { toggle(`${index}`); }}>
                            {templatesData[index].lang}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields.map((item, index) => {
                    return (
                        <TabPane tabId={`${index}`} key={index}>
                            <Row>
                                <Col md="12">
                                    <Container>
                                        <div className="form__form-group">
                                            <div className="form__form-group-field" style={{ marginTop: '20px' }}>
                                                <Field
                                                    name={`${item}.subject`}
                                                    component="input"
                                                    type="text"
                                                    placeholder="Subject"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={`${item}.content`}
                                                    component={renderTextEditor}
                                                    initValue={initialValues.templates[index].content}
                                                />
                                            </div>
                                        </div>
                                    </Container>
                                </Col>
                            </Row>
                        </TabPane>
                    )
                })}
            </TabContent>
        </div>
    );
}

export default EmailTemplateLangTab;