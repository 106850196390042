import * as ACTIONS from '../actions';

const initialState = {
    loading: false,
    emailTemplateData: [],
    notificationType: '',
    notificationMessage: '',
}

function emailTemplateDetailReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.LOADING:
        case ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_REQUESTED:
            return { ...state, loading: true };
        case ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_SUCCEEDED:
            const { data } = action.payload;
            return { ...state, emailTemplateData: data, loading: false };
        case ACTIONS.EMAIL_TEMPLATE_DETAIL_FETCH_FAILED:
            return { ...state, loading: false };
        case ACTIONS.EMAIL_TEMPLATE_DETAIL_UPDATE_SUCCEEDED:
            return { ...state, loading: false };
        default:
            return state;
    }
}

export { emailTemplateDetailReducer }