import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import PropertyInfor from './components/PropertyInfor';
import AgentModal from './components/AgentModal';
import Loading from '../../../shared/components/Loading';
import NotificationModal from '../../../shared/components/NotificationModal';
import { fetchPropertyDetail } from './redux/actions/fetchDataAction';
import {
  confirmProperty,
  rejectProperty,
  verifyProperty,
  toggleNotification,
  changeStatusProperty,
  postOnFB,
  postOnInstagram,
  approveRenewalPayment,
  rejectRenewalPayment,
  publishProperty,
  publishWithoutPay
} from './redux/actions/verifyPropertyAction';
import { toggleModalAgent, asignAgent } from './redux/actions/asignAgentAction';
import { history } from '../../../shared/helpers/index';

class PropertyDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showTenant: false,
      propertyId: window.location.pathname.split("/").pop() || null
    }
    this.rejectProperty = this.rejectProperty.bind(this);
    this.changeStatusProperty = this.changeStatusProperty.bind(this);
    this.verifyProperty = this.verifyProperty.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.asignAgent = this.asignAgent.bind(this);
    this.confirmProperty = this.confirmProperty.bind(this);
    this.publishProperty = this.publishProperty.bind(this);
    this.approveRenewalPayment = this.approveRenewalPayment.bind(this);
    this.rejectRenewalPayment = this.rejectRenewalPayment.bind(this);
  }

  closeModal() {
    this.props.toggleNotification();
    history.push('/');
  }

  asignAgent(agentId) {
    this.props.asignAgent(agentId);
  }

  componentDidMount() {
    // const id = window.location.pathname.split("/").pop();
    const { propertyId } = this.state
    this.props.fetchPropertyDetail(propertyId);
  }

  rejectProperty(message = "", isPaid) {
    this.props.rejectProperty(message, isPaid)
  }

  postOnFB = (message = "") => {
    this.props.postOnFB(message)
  }

  postOnInstagram = (message = "") => {
    this.props.postOnInstagram(message)
  }

  changeStatusProperty() {
    this.props.changeStatusProperty()
  }

  approveRenewalPayment() {
    this.props.approveRenewalPayment()
  }

  rejectRenewalPayment(message) {
    this.props.rejectRenewalPayment(message)
  }

  publishProperty() {
    this.props.publishProperty()
  }

  publishWithoutPay = () => {
    this.props.publishWithoutPay()
  }

  verifyProperty() {
    this.props.verifyProperty();
  }

  confirmProperty() {
    this.props.confirmProperty();
  }

  verifySuccess() {
    let { property } = this.state;
    property.verified_status = "verified";
    this.setState({ property: property, showAgentList: true });
  }

  handleRedirectPage = () => {
    history.push('/');
  }

  render() {
    const { showTenant, propertyId } = this.state;
    const {
      property,
      showAgentsModal,
      showLoading,
      notificationMessage,
      notificationType,
      agents,
      t
    } = this.props;

    return (
      <Container className="dashboard">
        <Loading isOpen={showLoading} />
        <NotificationModal
          isOpen={notificationType != ""}
          color={notificationType}
          message={notificationMessage}
          toggle={this.closeModal}
        />
        {!!property ?
          <PropertyInfor
            property={property}
            rejectProperty={this.rejectProperty}
            postOnFB={this.postOnFB}
            postOnInstagram={this.postOnInstagram}
            changeStatusProperty={this.changeStatusProperty}
            approveRenewalPayment={this.approveRenewalPayment}
            rejectRenewalPayment={this.rejectRenewalPayment}
            verifyProperty={this.verifyProperty}
            confirmProperty={this.confirmProperty}
            fetchPropertyDetail={this.props.fetchPropertyDetail}
            publishProperty={this.publishProperty}
            publishWithoutPay={this.publishWithoutPay}
            t={t}
          /> :
            <NotificationModal isOpen={!property && !showLoading}
              color={`danger`}
              message={`This Property is not exist.`}
              toggle={this.handleRedirectPage}/>
          }
        <AgentModal
          modalprop={showAgentsModal}
          closeModal={this.props.toggleModalAgent}
          asignAgent={this.asignAgent}
          agents={agents}
        />

      </Container>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state.propertyDetailReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPropertyDetail: (propertyId) => { dispatch(fetchPropertyDetail(propertyId)) },
    changeStatusProperty: () => { dispatch(changeStatusProperty()) },
    postOnFB: (message) => { dispatch(postOnFB(message)) },
    postOnInstagram: (message) => { dispatch(postOnInstagram(message)) },
    approveRenewalPayment: () => { dispatch(approveRenewalPayment()) },
    rejectRenewalPayment: (message) => { dispatch(rejectRenewalPayment(message)) },
    rejectProperty: (message, isPaid) => { dispatch(rejectProperty(message, isPaid)) },
    verifyProperty: () => { dispatch(verifyProperty()) },
    toggleNotification: () => { dispatch(toggleNotification()) },
    toggleModalAgent: () => { dispatch(toggleModalAgent()) },
    asignAgent: (agentId) => { dispatch(asignAgent(agentId)) },
    confirmProperty: () => { dispatch(confirmProperty()) },
    publishProperty: () => { dispatch(publishProperty()) },
    publishWithoutPay: (propertyId) => { dispatch(publishWithoutPay(propertyId)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(PropertyDetail));