import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import momment from 'moment';
import avatar from '../../../../shared/img/avatar.png';
import {IMAGE_BASE_URL} from '../../../../shared/helpers/api';
import { FORMAT_DATE } from '../../../../shared/helpers/constants'

const AgentProfile = (props) => {
    const {avatarImg, first_name, last_name, phone_number, posting_properties_count, role, line_account, status, owner_type, email, add_time} = props.owner

    return (
        <Card>
            <CardBody>

                <div >
                    <img alt="agent"
                    className="property__agent-img"
                    id="image"
                    src={avatarImg ? `${IMAGE_BASE_URL}/large/${avatarImg}` : avatar} />
                </div>

                <div>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Full Name:</label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{`${first_name} ${last_name}`}</strong></label>
                        </Col>
                    </Row>

                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Email: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{email}</strong></label>
                        </Col>
                    </Row>

                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Phone: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{phone_number || '-'}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>No. of Properties: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{posting_properties_count}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Seller Type: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{owner_type || role}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Line ID: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{line_account && line_account || '-'}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Status: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{status}</strong></label>
                        </Col>
                    </Row>

                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Active Date: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{momment(add_time).format(FORMAT_DATE)}</strong></label>
                        </Col>
                    </Row>
                </div>




            </CardBody>
        </Card>
    )
}



export default withTranslation('common')(AgentProfile);
