import momment from 'moment';
import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'
import { FORMAT_DATE } from '../../../../../shared/helpers/constants'

const fetchData = (status, itemPerPage, pageNumber, name, sort, desc) => {

    let params = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage,
        status: status.value,
        name
    };

    if(sort !== "") {
        params["sort"] = sort;
        params["desc"] = desc;
    }

    return apiCaller(API_URL.GET_LIST_SELLER, HTTP_METHOD.GET, null, params)
}

const formatData = (data) => {
    const formatData = data.map(element => ({
        ...element,
        name: `${element.first_name} ${element.last_name}`,
        add_time: momment(element.add_time).format(FORMAT_DATE),
        status: !element.verified ? "unverified" : element.status,
    }));

    return formatData;
}
export { fetchData, formatData }; 