import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { Col, Button, Card, CardBody } from 'reactstrap';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';

class Filter extends PureComponent {

    onChangeStatus = (e) => {
        const { fromDate, toDate } = this.props;
        this.props.changeFilterValue({ status: e, fromDate, toDate });
    }

    onChangeFromDate = (e) => {
        const { status, toDate } = this.props;
        this.props.changeFilterValue({ fromDate: moment(e).format('YYYY-MM-DD'), status,  toDate });
    }

    onChangeToDate = (e) => {
        const { status, fromDate } = this.props;
        this.props.changeFilterValue({ toDate: moment(e).format('YYYY-MM-DD'), status,  fromDate });
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onFilter();
    }


    render() {
        const { status, fromDate, toDate } = this.props;
        return (
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>

                        <form className="form form--horizontal" onSubmit={this.handleSubmit}>
                            <Col md={4} lg={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Status</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="status"
                                            component={renderSelectField}
                                            options={[
                                                { value: '', label: 'All' },
                                                { value: 'verifying', label: 'Verifying' },
                                                 { value: 'rejected', label: 'Rejected' },
                                                { value: 'available' , label: 'Available'},
                                                { value: 'verified', label: 'Certified' },
                                                { value: 'none-verified', label: 'Un-Certified' },
                                                { value: 'closing', label: 'Closing' },
                                                { value: 'closed', label: 'Closed' },
                                                { value: 'confirmed', label: 'Confirmed' },
                                                { value: 'ongoing', label: 'On-going'},
                                            ]}
                                            className="properties__status-select"
                                            onChange={this.onChangeStatus}
                                            // value={status}
                                            defaultValue={status}
                                            
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} lg={3}>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">From</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="fromDate"
                                            component={renderDatePickerField}
                                            className="property__filter__input"
                                            onChange={this.onChangeFromDate}
                                            value={fromDate}
                                        />
                                        <div className="form__form-group-icon property__filter__calender">
                                            <CalendarBlankIcon />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col md={3} lg={3}>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">To</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="toDate"
                                            component={renderDatePickerField}
                                            className="property__filter__input"
                                            onChange={this.onChangeToDate}
                                            value={toDate}
                                            minDate={moment(fromDate, 'YYYY-MM-DD').toDate()}
                                        />
                                        <div className="form__form-group-icon property__filter__calender">
                                            <CalendarBlankIcon />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={2} lg={2}>
                                <Button color="primary" action="submit" size="sm">Filter</Button>
                            </Col>
                        </form>

                    </CardBody>
                </Card>

            </Col>
        );
    }
}

export default reduxForm({
    form: 'horizontal_form', // a unique identifier for this form
})(withTranslation('common')(Filter));
