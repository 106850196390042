export const FETCH_TRANSACTION_FEE_REQUEST = 'FETCH_TRANSACTION_FEE_REQUEST';
export const FETCH_TRANSACTION_FEE_SUCCESS = 'FETCH_TRANSACTION_FEE_SUCCESS';
export const FETCH_TRANSACTION_FEE_FAILURE = 'FETCH_TRANSACTION_FEE_FAILURE';
export const CHANGE_TRANSACTION_FEE = 'CHANGE_TRANSACTION_FEE';
export const LOADING = 'LOADING';

export const GET_GENERAL_VALUE = 'GET_GENERAL_VALUE';
export const GET_GENERAL_VALUE_SUCCESS = 'GET_GENERAL_VALUE_SUCCESS';
export const GET_GENERAL_VALUE_FAILURE = 'GET_GENERAL_VALUE_FAILURE';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAILURE = 'GET_PACKAGES_FAILURE';

export const CONFIG_PACKAGE = 'CONFIG_PACKAGE';
export const CONFIG_PACKAGE_SUCCESS = 'CONFIG_PACKAGE_SUCCESS';
export const CONFIG_PACKAGE_FAILURE = 'CONFIG_PACKAGE_FAILURE';

