import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../shared/components/form/Select";
import { Col, Button } from "reactstrap";
import { USER_STATUS_FILTER } from "../../../shared/helpers/constants";

class Filter extends PureComponent {
  onChangeStatus = e => {
    this.props.changeFilterValue({ status: e });
  };

  onChangeName = e => {
    let email = e.target.value;
    email = email ? email.trim() : email;
    this.props.changeFilterValue({ email });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onFilter();
  };

  render() {
    const { status, email } = this.props.data;
    return (
      <form
        style={{ width: "100%", background: "white", padding: "28px 0 0 26px" }}
        className="form form--horizontal"
        onSubmit={this.handleSubmit}
      >
        <Col md={4} lg={4}>
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component="input"
                type="text"
                onChange={this.onChangeName}
                className="properties__filer-border properties__status-select"
                value={email}
              />
            </div>
          </div>
        </Col>
        <Col md={4} lg={4}>
          <div className="form__form-group">
            <span className="form__form-group-label">Status</span>
            <div className="form__form-group-field">
              <Field
                name="status"
                component={renderSelectField}
                options={USER_STATUS_FILTER}
                className="properties__status-select"
                onChange={this.onChangeStatus}
                value={status}
                defaultValue={status}
              />
            </div>
          </div>
        </Col>

        <Col md={3} lg={2}>
          <Button color="primary" action="submit" size="sm">
            Filter
          </Button>
        </Col>
      </form>
    );
  }
}

export default reduxForm({
  form: "horizontal_form" // a unique identifier for this form
})(withTranslation("common")(Filter));
