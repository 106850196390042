import React, { useState, memo } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { connect } from "react-redux";
import Scrollbar from "react-smooth-scrollbar";
// import Scrollbar from 'smooth-scrollbar/plugins/overscroll';
import {
  CurrentUserProps,
  ContactsProps,
} from "../../../../shared/prop-types/ChatProps";
import ChatBubble from "./ChatBubble";
import ChatField from "./ChatField";
import ChatDirectional from "./ChatDirectional";
import {
  addChatMessage,
  appendNewMessageToCurrentRoom,
  getListChatRoom,
} from "../redux/actions/chatAction";
import {
  initializeApp,
  initializePush,
  onMessagesRef,
  offMessagesRef,
  onUsersRef,
} from "../../../../shared/firebase/fcm";
import ChatTopbar from "./ChatTopbar";

initializeApp();
class ChatForm extends React.PureComponent {
  async componentDidMount() {
    // await initializePush();
    const adminId = localStorage.getItem("admin_id");

    onUsersRef(adminId, (snapshot) => {
      let messagesObj = snapshot.val();
      this.props.getListChatRoom(true, true);
    });
  }

  componentDidUpdate() {
    if ((this.$container || {}).scrollbar) {
      this.$container.scrollbar.scrollTo(
        0,
        this.$container.scrollbar.size.content.height
      );
    }
  }
  render() {
    const {
      roomData,
      messagesData,
      addChatMessage,
      currentRoom,
      appendNewMessageToCurrentRoom,
    } = this.props;
    const adminId = localStorage.getItem("admin_id");
    const chatClass = classNames({
      chat: true,
      "chat--open": true,
    });
    if (roomData) {
      offMessagesRef();
      onMessagesRef(roomData.id, (e) => {
        const message = e.val();
        appendNewMessageToCurrentRoom(message);
      });
    }
    return (
      <div className={chatClass} role="presentation">
        {!roomData ? (
          <div className="chat__dialog">
            <div className="chat__dialog-select-message">
              <p>Select a chat to start messaging</p>
            </div>
          </div>
        ) : (
          <div className="chat__dialog">
            <ChatTopbar
              contact={this.props.roomsData.find(
                (c) => c.id === roomData.groupId
              )}
              roomType={!!roomData && roomData.roomType}
              // onClick={this.onOpenContacts}
            />
            <Scrollbar
              className={`scroll chat__scroll ${
                currentRoom.roomType === "company_chat" ? "company__chat" : ""
              }`}
              alwaysShowTracks
              ref={(c) => (this.$container = c)}
            >
              <div className="chat__dialog-messages-wrap">
                {!messagesData || messagesData.length === 0 ? (
                  <div className="chat__dialog-messages">
                    <div className="chat__dialog-messages-empty">
                      <p>No messages</p>
                    </div>
                  </div>
                ) : (
                  <div className="chat__dialog-messages">
                    {messagesData.map((m, i) => (
                      <ChatBubble
                        key={m.message._id}
                        contact={m.user}
                        message={m.message}
                        date={m.message.date}
                        active={true}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Scrollbar>
            {currentRoom.roomType !== "company_chat" && <ChatDirectional />}
            <ChatField
              onSendMessage={(message) => {
                addChatMessage(roomData.id, adminId, message);
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.chatReducer,
  };
};

export default connect(
  mapStateToProps,
  {
    addChatMessage,
    appendNewMessageToCurrentRoom,
    getListChatRoom,
  }
)(
  reduxForm({
    form: "chatForm", // a unique identifier for this form
    // fields: ['name', 'table', 'field'],
    enableReinitialize: true,
  })(withTranslation("common")(ChatForm))
);
