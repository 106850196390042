import * as CONSTANTS from './constants'; 


const handleCreateCurrencies = (data) => {
    return {
        type: CONSTANTS.CREATE_CURRENCY_REQUEST,
        data,
    }
}

const fetchListCurrencyParams = () => {
    return {
        type: CONSTANTS.FETCH_CURRENCY_PARAMS_REQUEST,
    }
}

const toggleNotification = () => {
    return {
        type: CONSTANTS.TOGGLE_NOTIFICATION_CURRENCY_CREATE,
    }
}

export {
    handleCreateCurrencies,
    fetchListCurrencyParams,
    toggleNotification
} 
