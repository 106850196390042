import {
  apiCaller,
  API_URL,
  HTTP_METHOD,
} from "../../../../shared/helpers/index";

const fetchFirebaseTokenSvc = () => {
  return apiCaller(API_URL.TOKEN_CHAT, HTTP_METHOD.GET);
};

const fetchListChatRoom = (pageNumber, itemPerPage, filter) => {
  const params =
    Object.keys(filter).length > 0 && filter.constructor === Object
      ? { pageNumber: pageNumber, itemPerPage: itemPerPage, ...filter }
      : { pageNumber: pageNumber, itemPerPage: itemPerPage };
  return apiCaller(API_URL.CHAT_ROOM, HTTP_METHOD.GET, null, params);
};

const fetchListMessage = (room_id, sort, desc) => {
  return apiCaller(
    `${API_URL.CHAT_MESSAGE}/${room_id}`,
    HTTP_METHOD.GET,
    null,
    {
      sort: sort,
      desc: desc,
    }
  );
};

const assignAgentToProperty = (propertyId, agentId) => {
  return apiCaller(API_URL.TAKE_OVER_PROPERTY, HTTP_METHOD.POST, {
    property_id: propertyId,
    user_id: agentId,
  });
};

const addChatMessageSv = (roomId, userId, message) => {
  return apiCaller(API_URL.ADD_CHAT_MESSAGE, HTTP_METHOD.POST, {
    room_id: roomId,
    user_id: userId,
    role: "admin",
    message: message,
    status: "sent",
    msg_type: "text",
    add_time: Date.now(),
  });
};

const addChatWithOwners = (ownerId, propertyId) => {
  return apiCaller(
    `${API_URL.CHAT_WITH_OWNER}/company_chat/${propertyId}/${ownerId}`,
    HTTP_METHOD.GET
  );
};

const chatRoomSearchSvc = (searchParam) => {
  // return apiCaller(API_URL.CHAT_ROOM_SEARCH, HTTP_METHOD.GET, searchParam);
  return apiCaller(API_URL.CHAT_ROOM, HTTP_METHOD.GET, null, {
    pageNumber: searchParam.pageNumber,
    itemPerPage: searchParam.itemPerPage,
    ...searchParam.filter,
  });
};

export {
  fetchListChatRoom,
  fetchListMessage,
  assignAgentToProperty,
  addChatMessageSv,
  addChatWithOwners,
  fetchFirebaseTokenSvc,
  chatRoomSearchSvc,
};
