import {
    USER_COUNT_REQUEST_START,
    USER_COUNT_REQUEST_SUCCESS,
    GET_VERIFYING_PROPERTIES,
    GET_CLOSED_PROPERTOES,
    GET_ACTIVE_AGENTS
} from '../actions/index';

import  {PATH_URL, currencyFormat, currencySymbol} from "../../../../../shared/helpers";

const initialState = {
    topItemData: [
        { number: 0, icon: "apartment", name: "Verifying properties", path: PATH_URL.PROPERTY, pathState: {status: "verifying"} },
        { number: 0, icon: "dice", name: "Closed properties", path: PATH_URL.PROPERTY, pathState: {status: "closed"}},
        { number: 0, icon: "license", name: "Lessors Sellers", path: PATH_URL.SELLERS, pathState: {} },
        { number: 0, icon: "users", name: "Ambassadors", path: PATH_URL.AMBASSADORS, pathState: {} },
    ],
    verifyingProperties: [],
    agentsData: [],
    showLoading: false
}

const dashboardReducer = function dashboardReducer(state = initialState, action) {
    let { topItemData, agentsData } = state;
    const { data } = action;

    switch (action.type) {

        case USER_COUNT_REQUEST_START:
            const result = { ...state, showLoading: true };
            return result;

        case USER_COUNT_REQUEST_SUCCESS:
            data.forEach(element => {
                if (element._id === 'agent') {
                    topItemData[3].number = element.count
                }

                if (element._id === 'property_owner') {
                    topItemData[2].number = element.count
                }
            });
            return { ...state, topItemData };
            
        case GET_VERIFYING_PROPERTIES: 
            let verifyingProperties = [];
            
            topItemData[0].number = data.total ? data.total.count : 0;
            data.data.forEach(element => {
             
                let priceLable = "";
                element.prices.forEach(pri => {
                    if (pri.is_default) {
                        priceLable = `${currencyFormat(pri.price)} ${currencySymbol(data.currencies, pri.currency_code)}`;
                    }
                });
            
            
                verifyingProperties.push({
                    title: element.title,
                    image: element.galleries[0] && element.galleries[0].id,
                    price: priceLable,
                    address: element.address,
                    id: element._id,
                    verify_type: element.verify_type
                })
            });
            return { ...state, verifyingProperties, topItemData };

        case GET_CLOSED_PROPERTOES:
            
            topItemData[1].number = data.total ? data.total.count: 0;
            return { ...state, topItemData }

        case GET_ACTIVE_AGENTS:
            (data || []).forEach(element => {
                agentsData.push({
                    avatarImg: element.avatar ? element.avatar : "",
                    name: `${element.first_name} ${element.last_name}`,
                    address: element.address1,
                    numberAsignProperties: element.assigned_properties.length,
                    id: element._id
                })
            });
            return { ...state, agentsData, showLoading: false }

        default:
            return state
    }
}

export default dashboardReducer
