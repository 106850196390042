import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'
import { VERIFIED_STATUS } from '../../../../../shared/helpers/constants'

const countUsers = () => apiCaller(API_URL.USER_COUNT, HTTP_METHOD.GET);

const countPostingProperties = () => apiCaller(API_URL.GET_LIST_PROPERTY, HTTP_METHOD.GET, null, { status: "posting" });

const countVerifyingProperties = () => apiCaller(API_URL.GET_LIST_PROPERTY, HTTP_METHOD.GET, null, { status: "posting", verifyStatus: VERIFIED_STATUS.PENDING });

const getMostActiveUsers = () => apiCaller(API_URL.MOST_ACTIVE_USERS, HTTP_METHOD.GET);

const countClosedProperties = () => apiCaller(API_URL.GET_LIST_PROPERTY, HTTP_METHOD.GET, null, { status: "closed" });   

const getActiveAgents = () =>  apiCaller(API_URL.MOST_ACTIVE_USERS, HTTP_METHOD.GET)

const fetListCurrencies = async () => {
    const requestOptions = {
        method: 'GET'
    };
    const response = await fetch(API_URL.GENERAL_PARAM, requestOptions);
    const res = response.json();
    return res;
}

const getListCurrencies = async () => {
    const value = await fetListCurrencies();
    return value.data.system.currencyCodes;
}

export { 
    countUsers,
    countPostingProperties,
    countVerifyingProperties,
    getMostActiveUsers,
    countClosedProperties,
    getActiveAgents,
    getListCurrencies
};