export const LOADING = 'SHOW_LOADING';
export const AMBASSADOR_DETAIL_FETCH_REQUESTED = 'AMBASSADOR_DETAIL_FETCH_REQUESTED';
export const AMBASSADOR_DETAIL_FETCH_SUCCEEDED = 'AMBASSADOR_DETAIL_FETCH_SUCCEEDED';
export const AMBASSADOR_DETAIL_FETCH_FAILED = 'AMBASSADOR_DETAIL_FETCH_FAILED';
export const HANDLE_CHANGE_TABLE = 'HANDLE_CHANGE_TABLE';
export const TAKE_OVER_PROPERTY_REQUEST = 'TAKE_OVER_PROPERTY_REQUEST';
export const TAKE_OVER_PROPERTY_SUCCESS = 'TAKE_OVER_PROPERTY_SUCCESS';
export const TAKE_OVER_PROPERTY_ERROR = 'TAKE_OVER_PROPERTY_ERROR';
export const CHANGE_AMBASSADOR_ACTIVE_REQUEST = 'CHANGE_AMBASSADOR_ACTIVE_REQUEST';
export const CHANGE_AMBASSADOR_DEACTIVE_REQUEST = 'CHANGE_AMBASSADOR_DEACTIVE_REQUEST';
export const CHANGE_AMBASSADOR_STATUS_SUCCESS = 'CHANGE_AMBASSADOR_STATUS_SUCCESS';
export const CHANGE_AMBASSADOR_STATUS_ERRROR = 'CHANGE_AMBASSADOR_STATUS_ERRROR';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

const handleDetailAmbassador = (id) => {
  return {
    type: AMBASSADOR_DETAIL_FETCH_REQUESTED,
    id,
  }
}

const handleUpdateAmbassador = (item) => {
  return {
    type: HANDLE_CHANGE_TABLE,
    payload: item
  }
}

export const takeoverPropertyRequest = (proId, ambasaadorId) => {

  return {
    type: TAKE_OVER_PROPERTY_REQUEST,
    proId,
    ambasaadorId
  }
}

export const takeoverPropertySuccess = (id, message) => {
  return {
    type: TAKE_OVER_PROPERTY_SUCCESS,
    id,
    message
  }
} 

export const takeoverPropertyError = (message) => {
  return {
    type: TAKE_OVER_PROPERTY_ERROR,
    message
  }
}

export const changeAmbassadorActiveReqest = () => {
  return {
    type: CHANGE_AMBASSADOR_ACTIVE_REQUEST
  }
}

export const changeAmbassadorDeactiveRequest = () => {
  return {
    type: CHANGE_AMBASSADOR_DEACTIVE_REQUEST
  }
}

export const changeAmbassadorStatusSuccess = (message) => {
  return {
    type: CHANGE_AMBASSADOR_STATUS_SUCCESS,
    message
  }
}

export const changeAmbassadorStatusError = (message) => {
  return {
    type: CHANGE_AMBASSADOR_STATUS_ERRROR,
    message
  }
}

export const toggleNotification = () => {
  return {
    type: TOGGLE_NOTIFICATION
  }
}
export {
  handleDetailAmbassador,
  handleUpdateAmbassador
} 
