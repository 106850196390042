import { call, put, takeLatest, cancelled, select } from 'redux-saga/effects';
import * as ACTIONS from '../redux/actions/createAmbassadorAction';
import { history, PATH_URL } from '../../../../shared/helpers/index';
import { createAmbassador } from '../services/index';



function* createAmba(data) {
  try {
    yield call(createAmbassador, data.data);
    yield put({ type: ACTIONS.CREATE_SUCCESS })
    } catch (error) {
    yield put({ type: ACTIONS.CREATE_FAILED, error: JSON.stringify(error.response.data.message) })
  } finally {
    if (yield cancelled()) {
      yield call(history.push, PATH_URL.HOME);
    }
  }
}



function* createAmbassadorWatcher() {
  yield takeLatest(ACTIONS.CREATE, createAmba);

}

export default createAmbassadorWatcher 
