export const CLIENT_SET = 'CLIENT_SET'  
export const CLIENT_UNSET = 'CLIENT_UNSET'  

export function setClient (token, tokenUA) {  
  return {
    type: CLIENT_SET,
    token,
    tokenUA
  }
}

export function unsetClient () {  
  return {
    type: CLIENT_UNSET,
  }
}
