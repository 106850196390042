import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import EmailTemplateForm from './components';
import NotificationModal from '../../../shared/components/NotificationModal';
import Loading from '../../../shared/components/Loading';
import Confirm from '../../../shared/components/Confirm';
import { getEmailTemplateDetail, updateEmailTemplateDetail } from './redux/actions';

const EmailTemplateDetail = ({
    loading,
    getEmailTemplateDetail,
    updateEmailTemplateDetail,
    templateData,
    match: { params },
}) => {
    const EmailTemplateId = params.id;

    const [showConfirm, setShowConfirm] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    useEffect(() => {
        EmailTemplateId && getEmailTemplateDetail(EmailTemplateId);
    }, []);

    const toggleConfirm = () => {
        setShowConfirm(false);
    }

    const confirmDelete = () => {
        setShowConfirm(false);
        updateEmailTemplateDetail(EmailTemplateId, currentData);
    }

    return (
        <Col md={12}>
            <Confirm toggle={toggleConfirm} isOpen={showConfirm} title={"Confirm"} message={"Are you sure to update this email template?"} confirm={confirmDelete} />
            <Loading isOpen={loading} />
            <NotificationModal
                isOpen={false}
                color={'error' ? "danger" : "success"}
                message={'message'}
                toggle={() => { this.closeModalSuccess('error') }}
            />
            <Card>
                <CardBody>
                    <EmailTemplateForm initData={templateData} onSubmit={values => {
                        setCurrentData(values);
                        setShowConfirm(true);
                    }} />
                </CardBody>
            </Card>
        </Col>);
}

const mapStateToProps = state => {
    return {
        templateData: state.emailTemplateDetailReducer.emailTemplateData,
        loading: state.emailTemplateDetailReducer.loading,
    }
}
const mapDispatchToProps = {
    getEmailTemplateDetail,
    updateEmailTemplateDetail,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplateDetail));