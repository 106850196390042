import React from 'react';
import MenuIcon from 'mdi-react/MenuIcon';

const ChatTopbar = ({ contact, roomType }) => {
  const type = roomType && roomType.split('_').map(element => {
    return (element.charAt(0).toUpperCase() + element.slice(1));
  }).join(" ");
  return (
    <div className="chat__topbar">
      <button className="chat__topbar-button chat__topbar-button--menu" type="button">
        <MenuIcon className="chat__topbar-button-icon" />
      </button>
      {contact
        && (
          <div className="chat__topbar-contact">
            <p className="chat__topbar-contact-name">{type}</p>
            <p className="chat__topbar-contact-post">{contact.name}</p>
          </div>
        )}
    </div>
  )
};

export default ChatTopbar;
