import React, { Component } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import AgentTable from './ListAgent/AgentTable';
import Confirm from '../../../../shared/components/Confirm';

class AgentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      showAgents: null,
      agent: null
    }

    this.selectAgent = this.selectAgent.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.confirmAsign = this.confirmAsign.bind(this);
  }

  selectAgent(agent) {
    this.setState({showConfirm: true, showAgents: false, agent: agent})
  }

  toggleConfirm() {
    this.setState({
      showConfirm: false,
      showAgents: null
    })
  }

  confirmAsign() {
    const {agent} = this.state;
    this.setState({
      showAgents: null,
      showConfirm: false
    });
    this.props.asignAgent(agent._id);
  }

  render() {
    const {
      modalprop, closeModal, agents
    } = this.props;

    const {showAgents} = this.state;
    return (
      <div className={``}>
        <Confirm toggle={this.toggleConfirm} isOpen={this.state.showConfirm} title={"Confirm"} message={"Are you sure to asign this ambassador?"} confirm={this.confirmAsign}/>
        <Modal
          isOpen={showAgents !== null ? showAgents : modalprop}
          modalClassName={`ltr-support`}
          className={``}
          size="lg"
        >
          <div className="modal__header">
            <button onClick={()=>{closeModal()}} className="lnr lnr-cross modal__close-btn" type="button"  />
            <h3 className="text-modal  modal__title">Please asign our ambassadors</h3>
          </div>
          <div className="modal__body">
            <AgentTable selectAgent={this.selectAgent} agents={agents} />
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={closeModal}>Cancel</Button>{' '}
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}



export default AgentModal;
