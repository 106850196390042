import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from '../../../../shared/helpers/api';
import { VERIFY_TYPES } from '../../../../shared/helpers/constants';
import { PATH_URL } from "../../../../shared/helpers";
import CertifiedThumbLogo from "../../../../shared/img/logo/logo_certified_thumb.png"
import video_thumb from '../../../../shared/img/video_thumb.png';
import no_image from '../../../../shared/img/no_image.png';

const PropertyCarouselItem = ({ t, verify_type, media, name, price, address, id, fetchPropertyDetail }) => {
    let mediaThumbUrl = `${IMAGE_BASE_URL}/large/${media}`;
    let isVideo = false;
    if (!media) mediaThumbUrl = no_image;
    if (!!media && media.startsWith('video/')) {
        isVideo = true;
        let subId = media.substring(6)
        mediaThumbUrl = IMAGE_BASE_URL + '/thumbnail/' + subId.slice(0, subId.lastIndexOf(".")) + '.png'
    }
    return (
        <div className="slide-container">
            <Link
                to={{
                    pathname: PATH_URL.PROPERTY_DETAIL_PATH + id,
                }}

                onClick={() => {
                    if (fetchPropertyDetail !== undefined)
                        fetchPropertyDetail(id);
                }}
            >
                <div className="property_carousel_item_header">
                    <img src={mediaThumbUrl} alt="Property" />
                    {isVideo && <img className="property_carousel_item_header video-thumb" src={video_thumb} />}
                    {verify_type === VERIFY_TYPES.VERIFICATION &&
                        <img src={CertifiedThumbLogo} className="property_carousel_item_header img-certified-logo" />}
                </div>
                <div className="slick-slider__caption">
                    <p className="slick-slider__caption-title property__tem-title">{name}</p>
                    <p className="slick-slider__caption-description">{price}</p>
                    <p className="slick-slider__caption-address">{address}</p>
                </div>
            </Link>
        </div>
    );
}

PropertyCarouselItem.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PropertyCarouselItem);
