import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/auth';
// import storage from 'services/storage';
let messagesRef = null;
const initializeApp = () => {
  if (!firebase.apps.length) {
    const firebaseConfig = process.env.FIREBASE_CONFIG
    firebase.initializeApp(firebaseConfig);
  }
};

const initializePush = async () => {
  try {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      'BOAAVmUIL5bdTyk08TIOj9uXyxCal_W44c9Ik881quw6TSn_F0YgyppvTd0ZwK_ADxnbfh2QqXI0WX6P0vyyv9A'
    );
    await messaging.requestPermission();

    const token = await messaging.getToken();
    // storage.set('fcmToken', { token });
  } catch (error) {
    if (
      error.code === 'messaging/permission-blocked' ||
      error.code === 'messaging/permission-default'
    ) {
      console.log('Please Unblock Notification Request Manually');
    } else {
      console.log('Error Occurred', error);
    }
  }
};

const signInFirebase = (token) => {
  firebase.auth().signInWithCustomToken(token)
    .then(function () {
      console.log('Sign in firebase success');
    })
    .catch(function (error) {
      if (error.code === 'auth/invalid-custom-token') {
        console.log('reconnect firebase');
        signInFirebase(token);
      } else {
        console.log("Error sign in custom token", error);
      }
    });
}

const signOutFirebase = () => {
  firebase.auth().signOut().then(function () {
    console.log("Sign-out successful.");
  }).catch(function (error) {
    console.log("Error sign out custom token", error);
  });
}

const onMessageListener = fn => {
  const messaging = firebase.messaging();
  messaging.onMessage(payload => fn(payload));
};

const connectDatabase = () => firebase.database();

const onMessagesRef = (roomId, fn) => {
  messagesRef = connectDatabase()
    .ref(`${process.env.FIREBASE_ENV}/room-messages/${roomId}`)
    .orderByKey()
    .limitToLast(1);
  messagesRef.on('child_added', snapshot => fn(snapshot));
};

const onUsersRef = (userId, fn) => {
  const messagesRef = connectDatabase()
    .ref(`${process.env.FIREBASE_ENV}/users/${userId}`)
    .orderByKey()
    .limitToLast(100);

  // messagesRef.on('child_changed', snapshot => fn(snapshot));
  messagesRef.on('value', snapshot => fn(snapshot));
};

const offMessagesRef = () => {
  messagesRef && messagesRef.off();
}

const statusPathRef = userId => connectDatabase().ref(`${process.env.FIREBASE_ENV}/user-status/${userId}/status`);

const setUserStatus = (userId, status) => statusPathRef(userId).set(status);

const getUserStatus = (userId, fn) => statusPathRef(userId).on('value', snapshot => fn(snapshot));

export {
  initializeApp,
  initializePush,
  onMessageListener,
  onMessagesRef,
  onUsersRef,
  offMessagesRef,
  setUserStatus,
  getUserStatus,
  signInFirebase,
  signOutFirebase,
};
