export const LOGOUT_REQUESTING = 'LOGOUT_REQUESTING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'


const logoutRequest = function logoutRequest () {
    return {
        type: LOGOUT_REQUESTING,
    }
}
// Since it's the only one here
export default logoutRequest

