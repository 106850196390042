import { apiCaller, API_URL, HTTP_METHOD } from '../../../../shared/helpers/index';

const fetchEmailTemplateDetail = (id) => {
    return apiCaller(`${API_URL.EMAIL_TEMPLATE_DETAIL}/${id}`, HTTP_METHOD.GET, null, null);
}

const updateEmailTemplateDetailAPI = (id, data) => {
    return apiCaller(`${API_URL.EMAIL_TEMPLATE_UPDATE}/${id}`, HTTP_METHOD.PUT, {
        ...data
    });
}

export { fetchEmailTemplateDetail, updateEmailTemplateDetailAPI };