import {
  apiCaller,
  API_URL,
  HTTP_METHOD
} from "../../../../shared/helpers/index";
import momment from "moment";
import { FORMAT_DATE } from "../../../../shared/helpers/constants";

const fetchListExploreSection = ({
  itemPerPage,
  pageNumber,
  title,
  status,
  type,
  sort,
  desc
}) => {
  let params = {
    pageNumber: pageNumber,
    itemPerPage: itemPerPage
  };

  if (sort !== "") {
    params.sort = sort;
    params.desc = desc;
  }

  if (title !== "") {
    params.title = title;
  }

  if (type !== "") {
    params.type = type;
  }

  if (status !== "") {
    params.status = status;
  }

  return apiCaller(API_URL.GET_EXPLORE_SECTION, HTTP_METHOD.GET, null, params);
};

const formatData = data => {
  let formatData = [];

  data.forEach(element => {
    element.add_time = momment(element.add_time).format(FORMAT_DATE);
    element.upd_time = momment(element.upd_time).format(FORMAT_DATE);
    element.title = element.title.en;
    element.order = element.order;
    element.status = element.status;
    element.description = !element.description.en ? "" : element.description.en;
    formatData.push(element);
  });
  return formatData;
};

const deleteExploreSectionService = id => {
  return apiCaller(`${API_URL.EXPLORE_SECTION_DELETE}/${id}`, HTTP_METHOD.PUT);
};

export { fetchListExploreSection, formatData, deleteExploreSectionService };
