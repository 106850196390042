import {
  apiCaller,
  API_URL,
  HTTP_METHOD,
  URBANASSET_IMAGE_BASE_API
} from "../../../../shared/helpers/index";

import axios from "axios";

const fetchDetailExploreSection = id => {
  return apiCaller(`${API_URL.EXPLORE_SECTION_DETAIL}/${id}`, HTTP_METHOD.GET);
};

const updateExploreSectionService = (
  id,
  { description, order, status, title, type, image, value }
) => {
  let formatData = {
    description: description,
    order: order,
    status: status,
    title: title,
    type: type,
    image: image
  };

  if (value) {
    formatData = { ...formatData, value };
  }

  return apiCaller(
    `${API_URL.UPDATE_EXPLORE_SECTION}/${id}`,
    HTTP_METHOD.PUT,
    formatData
  );
};

const formatExploreSectionDetail = rawData => {
  let enTitle;
  let thTitle;
  let enDesc;
  let thDesc;

  let fileArr = [];

  const imagePath = URBANASSET_IMAGE_BASE_API + "/large/" + rawData.image;

  const imageUrl = new File(
    [URBANASSET_IMAGE_BASE_API + "/large/" + rawData.image],
    rawData.image,

    {
      type: "image/jpg",
      path: imagePath
    }
  );

  fileArr.push(imageUrl);

  const order = rawData.order;
  let status = {};

  let type = {};

  // let title = {};

  let params = {};

  if (rawData.status === "active") {
    status["label"] = "Active";
    status["value"] = "active";
  } else {
    status["label"] = "Inactive";
    status["value"] = "inactive";
  }

  if (rawData.type === "development") {
    type["label"] = "Development";
    type["value"] = "development";
  } else if (rawData.type === "category") {
    type["label"] = "Category";
    type["value"] = "category";
  } else {
    type["label"] = "Area";
    type["value"] = "area";
  }

  const titleObj = rawData["title"];

  for (var titleKey in titleObj) {
    // title[titleKey] = titleObj[titleKey];

    if (titleKey === "en") {
      enTitle = titleObj["en"];
    } else {
      thTitle = titleObj["th"];
    }
  }

  const descriptionObj = rawData["description"];

  for (var descriptionKey in descriptionObj) {
    if (descriptionKey === "en") {
      enDesc = descriptionObj["en"];
    } else {
      thDesc = descriptionObj["th"];
    }
  }

  params = {
    order: order,
    status: status,
    type: type,
    enTitle: enTitle,
    thTitle: thTitle,
    enDesc: enDesc,
    thDesc: thDesc,
    files: fileArr
  };

  const valueObj = rawData["value"];

  if (valueObj) {
    let enValue;
    let thValue;

    enValue = valueObj["en"];
    thValue = valueObj["th"];

    params = {
      ...params,
      enValue,
      thValue
    };
  }

  return params;
};

const createExploreSectionImageUrl = async data => {
  const formData = new FormData();

  if (data.image) {
    formData.append("images", data.image[0]);
    delete data["image"];
  }

  let token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return axios({
    method: HTTP_METHOD.POST,
    url: API_URL.UPLOAD_EXPLORE_SECTION_IMAGE,
    data: formData,
    timeout: 100000,
    headers
  }).catch(function(error) {
    throw error;
  });
};

export {
  fetchDetailExploreSection,
  formatExploreSectionDetail,
  updateExploreSectionService,
  createExploreSectionImageUrl
};
