export const FETCH_PROPERTY_REQUEST = "FETCH_PROPERTY_REQUEST";
export const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS";
export const FETCH_PROPERTY_ERROR = "FETCH_PROPERTY_ERROR";
export const CHANGE_PAGE = "CHANGE_PRO_PAGE";
export const CHANGE_FILTER_VALUE = "CHANGE_FILTER_VALUE";
export const CHANGE_ITEM_PER_PAGE = "CHANGE_PRO_ITEM_PER_PAGE";
export const ON_SHORT_PROPERTIES = "ON_SHORT_PROPERTIES";
export const CHANGE_DEFAULT_PAGE = "CHANGE_DEFAULT_PAGE";
export const RESET_STATE = "RESET_STATE";
export const SET_PUBLISH_TYPE = "SET_PUBLISH_TYPE";

const fetchProperty = function fetchProperty() {
  return {
    type: FETCH_PROPERTY_REQUEST,
  };
};

const fetchPropertySuccess = function fetchPropertySuccess(data) {
  return {
    type: FETCH_PROPERTY_SUCCESS,
    data,
  };
};

const fetchPropertyError = function fetchPropertyError() {
  return {
    type: FETCH_PROPERTY_ERROR,
  };
};

const changeFilterValue = function changeFilterValue(filtersData) {
  return {
    type: CHANGE_FILTER_VALUE,
    filtersData,
  };
};

const setPublishTypeValue = function setPublishTypeValue(publishType) {
  return {
    type: SET_PUBLISH_TYPE,
    publishType,
  };
};

const changePage = function changePage(pageNumber) {
  return {
    type: CHANGE_PAGE,
    pageNumber: pageNumber,
  };
};

const changeItemPerPage = function changeItemPerPage(itemPerPage) {
  return {
    type: CHANGE_ITEM_PER_PAGE,
    itemPerPage: itemPerPage,
  };
};

const onShortProperties = (value) => {
  return {
    type: ON_SHORT_PROPERTIES,
    value,
  };
};

const changeDeafultPage = function changeDeafultPage() {
  return {
    type: CHANGE_DEFAULT_PAGE,
    pageNumber: 0,
  };
};

const resetState = function resetState() {
  return {
    type: RESET_STATE,
  };
};

export {
  fetchProperty,
  fetchPropertySuccess,
  fetchPropertyError,
  changeFilterValue,
  setPublishTypeValue,
  changeItemPerPage,
  changePage,
  onShortProperties,
  changeDeafultPage,
  resetState,
};
