import momment from 'moment';
import { apiCaller, API_URL, HTTP_METHOD } from '../../../../../shared/helpers/index'
import { FORMAT_DATE, FORMAT_DATETIME, PAYMENT_STATUS } from '../../../../../shared/helpers/constants'

const fetchData = ( fromDate, toDate, itemPerPage, pageNumber, sort, desc) => {
    let params = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage
    };

    if(sort !== "") {
        params["sort"] = sort;
        params["desc"] = desc;
    }

    if (fromDate !== "") {
        params["fromDate"] = fromDate;
    }

    if (toDate !== "") {
        params["toDate"] = toDate;
    }

    return apiCaller(API_URL.PAY_MENT, HTTP_METHOD.GET, null, params)
}

const formatData = (data) => {

  let formatData = [];
  data.forEach(element => {
    if (element.status === PAYMENT_STATUS.PAID) {
      element.total = (Number(element.prices.amount) * Number(element.model_type.slice(-1))).toFixed(2);
      element.add_time = momment(element.add_time).format(FORMAT_DATETIME);
      element.upd_time = momment(element.upd_time).format(FORMAT_DATETIME);
      element.first_name = element.user.first_name;
      element.last_name = element.user.last_name;
      formatData.push(element);
    }
  });

  return formatData;
}
export { fetchData, formatData };