import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  ON_SHORT_PROPERTIES,
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_ERROR,
  CHANGE_PAGE,
  CHANGE_ITEM_PER_PAGE,
} from "../actions/index";
import { fetchData, formatData } from "../services/index";

export const getData = (state) => state.propertyReducer;

function* getProperties() {
  try {
    const stateData = yield select(getData);
    const {
      status,
      fromDate,
      toDate,
      itemPerPage,
      pageNumber,
      name,
      sort,
      desc,
      paymentStatus,
    } = stateData;
    const result = yield call(
      fetchData,
      status,
      fromDate,
      toDate,
      itemPerPage,
      pageNumber,
      name,
      sort,
      desc,
      paymentStatus
    );
    const formatedData = yield call(formatData, result.data.data);
    const data = {
      data: formatedData,
      count: "total" in result.data ? result.data.total.count : 0,
    };
    yield put({ type: FETCH_PROPERTY_SUCCESS, data });
  } catch (error) {
    yield put({ type: FETCH_PROPERTY_ERROR, error });
  }
}

function* changePage() {
  yield put({ type: FETCH_PROPERTY_REQUEST });
}

function* changeItemPerPage() {
  yield put({ type: FETCH_PROPERTY_REQUEST });
}

function* propertyWatcher() {
  yield takeLatest(FETCH_PROPERTY_REQUEST, getProperties);
  yield takeLatest(CHANGE_PAGE, changePage);
  yield takeLatest(CHANGE_ITEM_PER_PAGE, changeItemPerPage);
  yield takeLatest(ON_SHORT_PROPERTIES, getProperties);
}

export default propertyWatcher;
