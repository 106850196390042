import React from 'react';
import { Col, Row, Card, CardBody, Media } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { IMAGE_BASE_URL } from '../../../../shared/helpers/api';
import { FORMAT_DATE } from '../../../../shared/helpers/constants'
import moment from 'moment';


class SellerProfile extends React.PureComponent {
    render() {
        const {  seller } = this.props
        if (!seller) return null
        return (
            <Row>
                {
                    seller.avatar && <Col>
                        <Media>
                            <Media alt="agent"
                                className="property__agent-img"
                                id="image"
                                src={`${IMAGE_BASE_URL}/large/${seller.avatar}`}
                            />
                        </Media>
                    </Col>
                }
                <Col>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Fullname:</label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{`${seller.first_name} ${seller.last_name}`}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Email: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.email}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Address 1: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.address1}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Address 2: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.address2}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>City: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.city}</strong></label>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>State: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.state}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Zipcode: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.zip_code}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Seller or Lessor: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong></strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Joined date: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{moment(seller.add_time).format(FORMAT_DATE)}</strong></label>
                        </Col>
                    </Row>
                    <Row className="agent__infor">
                        <Col md={4}>
                            <label>Phone contact: </label>
                        </Col>
                        <Col md={8}>
                            <label><strong>{seller.phone_number}</strong></label>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}



export default withTranslation('common')(SellerProfile);
