import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getEmailTemplate, changePage, changeRowPerPage, deleteEmailTemplate } from './redux/actions';
import Loading from '../../../shared/components/Loading';
import { history, PATH_URL } from '../../../shared/helpers/index';
import { Link } from "react-router-dom";
import MatTable from '../../../shared/components/table/components/MatTable';
import Confirm from '../../../shared/components/Confirm';

const EmailTemplate = ({
  getEmailTemplate,
  templateData,
  loading,
  count,
  pageNumber,
  itemPerPage,
  changePage,
  changeRowPerPage,
  deleteEmailTemplate,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentEmailTemplateId, setCurrentEmailTemplateId] = useState(null);

  useEffect(() => {
    getEmailTemplate();
  }, []);

  const toggleConfirm = () => {
    setShowConfirm(false);
  }

  const confirmDelete = () => {
    setShowConfirm(false);
    deleteEmailTemplate(currentEmailTemplateId);
  }

  const showDetail = (item) => { history.push(`${PATH_URL.EMAIL_TEMPLATE}/${item._id}`) };
  
  return (
    <Container>
      <Confirm toggle={toggleConfirm} isOpen={showConfirm} title={"Confirm"} message={"Are you sure to delete this email template?"} confirm={confirmDelete} />
      <Loading isOpen={loading} />
      <Row>
        <Container>
          <Row>
            <Col md={12} lg={12}>
              {(Array.isArray(templateData) && templateData.length > 0) ? <MatTable
                count={count}
                currentPage={pageNumber}
                onPageChange={changePage}
                onChangeRowsPerPage={(e) => changeRowPerPage(e.target.value)}
                rowsPerPage={itemPerPage}
                data={templateData}
                column={["title", "upd_time", "description", "type"]}
                header={["Template name", "Last update", "Description", "Type"]}
                onClickRow={showDetail}
                showId={true}
                disablePaging={false}
                actionColumnCustom={
                  [
                    {
                      title: "Details",
                      color: "success",
                      action: showDetail,
                    },
                    {
                      title: "Delete",
                      color: "danger",
                      action: (id) => {
                        setCurrentEmailTemplateId(id);
                        setShowConfirm(true);
                      },
                    },
                  ]
                }
              /> : <span>No Data</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Link className="btn btn-primary btn-sm" to={'/company/email-template/create'}>+ Add Email Template</Link>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  templateData: state.emailTemplateReducer.emailTemplateData,
  loading: state.emailTemplateReducer.loading,
  count: state.emailTemplateReducer.count,
  pageNumber: state.emailTemplateReducer.pageNumber,
  itemPerPage: state.emailTemplateReducer.itemPerPage,
});
const mapDispatchToProps = {
  getEmailTemplate,
  changePage,
  changeRowPerPage,
  deleteEmailTemplate,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(EmailTemplate));
