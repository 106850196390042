import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            icon="text-align-justify"
            route="/company/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Properties"
            icon="apartment"
            route="/company/property"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Lessors or Sellers"
            icon="license"
            route="/company/sellers"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Ambassadors"
            icon="user"
            route="/company/ambassadors"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Accounts"
            icon="users"
            route="/company/accounts"
            onClick={this.hideSidebar}
          />

          <SidebarLink
            title="Explore Sections"
            icon="earth"
            route="/company/explore-sections"
            onClick={this.hideSidebar}
          />

          <SidebarLink
            title="Chats"
            icon="bubble"
            route="/company/chats"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Email Templates"
            icon="envelope"
            route="/company/email-template"
            onClick={() => {}}
          />
          <SidebarLink
            title="Payment"
            icon="diamond"
            route="/company/payment"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Currencies"
            icon="database"
            route="/company/currencies"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Transaction fee"
            icon="cog"
            route="/company/settings/transaction-fee"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
