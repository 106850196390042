import numeral from "numeral";
import configureFakeBackend from "./configureFakeBackend";
import authHeader from "./authHeader";
import fetchAsync from "./fetchAsync";
import history from "./history";
import { PATH_URL } from "./path";
import {
  API_URL,
  HTTP_METHOD,
  IMAGE_BASE_URL,
  URBANASSET_IMAGE_BASE_API
} from "./api";
import apiCaller from "./apiCaller";

function currencyFormat(no) {
  return numeral(no).format("0,0[.]00");
}

function currencySymbol(src, ex) {
  return (src.find(i => i.code === ex) || {}).symbol;
}

export {
  configureFakeBackend,
  authHeader,
  fetchAsync,
  history,
  API_URL,
  PATH_URL,
  IMAGE_BASE_URL,
  URBANASSET_IMAGE_BASE_API,
  HTTP_METHOD,
  apiCaller,
  currencyFormat,
  currencySymbol
};
