import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import NotFound404 from "../404/index";
import LogIn from "../Company/LogIn/index";
import Ambassadors from "../Company/Ambassadors";
import Properties from "../Company/Properties";
import Dashboard from "../Company/Dashboard";
import Sellers from "../Company/Sellers";
import Chats from "../Company/Chats";
import Parameters from "../Company/Parameters";
import ParametersCreate from "../Company/ParametersCreate";
import ParametersDetail from "../Company/ParametersDetail";
import Emails from "../Company/Emails";
import Currencies from "../Company/Currencies";
import CurrenciesCreate from "../Company/CurrenciesCreate";
import CurrenciesEdit from "../Company/CurrenciesEdit";
import Payment from "../Company/Payment";
import PropertyDetail from "../Company/PropertyDetail";
import CreateAmbassador from "../Company/Agents/CreateAmbassador";
import { PrivateRoute } from "./PrivateRoute";
import AmbassadorDetail from "../Company/AmbassadorDetail";
import SellersDetail from "../Company/SellersDetail";
import Accounts from "../Company/Accounts";
import { history, PATH_URL } from "../../shared/helpers/index";
import PaymentDetail from "../Company/PaymentDetail";
import ExploreSections from "../Company/ExploreSections";
import ExploreSectionsCreate from "../Company/ExploreSectionsCreate/ExploreSectionsCreate";
import ExploreSectionsDetail from "../Company/ExploreSectionsDetail";
import EmailTemplate from "../Company/EmailTemplate";
import EmailTemplateDetail from "../Company/EmailTemplateDetail";
import EmailTemplateCreate from "../Company/EmailTemplateCreate";
import SettingTransactionFee from "../Company/SettingTransactionFee";

const Company = () => (
  <Switch>
    <PrivateRoute exact path={PATH_URL.HOME}>
      <Dashboard />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.COMPANY}>
      <Dashboard />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.AMBASSADORS}>
      <Ambassadors />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.AMBASSADORS_CREATE}>
      <CreateAmbassador />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.AMBASSADORS_DETAIL}>
      <AmbassadorDetail />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.CHATS}>
      <Chats />
    </PrivateRoute>

    <PrivateRoute exact path={PATH_URL.CURRENCIES}>
      <Currencies />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.CURRENCIES_CREATE}>
      <CurrenciesCreate />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.CURRENCIES_EDIT}>
      <CurrenciesEdit />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.DASHBOARD}>
      <Dashboard />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.EMAILS}>
      <Emails />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.PARAMETERS}>
      <Parameters />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.PARAMETERS_CREATE}>
      <ParametersCreate />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.PARAMETERS_DETAIL}>
      <ParametersDetail />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.PAYMENT}>
      <Payment />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.PROPERTY}>
      <Properties />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.SELLERS}>
      <Sellers />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.PROPERTYDETAIL}>
      <PropertyDetail />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.SELLERETAIL}>
      <SellersDetail />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.ACCOUNTS}>
      <Accounts />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.PAYMENT_DETAIL}>
      <PaymentDetail />
    </PrivateRoute>

    <PrivateRoute exact path={PATH_URL.EXPLORE_SECTIONS}>
      <ExploreSections />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.EXPLORE_SECTIONS_CREATE}>
      <ExploreSectionsCreate />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.EXPLORE_SECTIONS_DETAIL}>
      <ExploreSectionsDetail />
    </PrivateRoute>

    <PrivateRoute exact path={PATH_URL.EMAIL_TEMPLATE}>
      <EmailTemplate />
    </PrivateRoute>
    <PrivateRoute exact path={PATH_URL.EMAIL_TEMPLATE_CREATE}>
      <EmailTemplateCreate />
    </PrivateRoute>
    <PrivateRoute path={PATH_URL.EMAIL_TEMPLATE_DETAIL}>
      <EmailTemplateDetail />
    </PrivateRoute>

    <PrivateRoute path={PATH_URL.SETTINGS_TRANSACTION_FEE}>
      <SettingTransactionFee />
    </PrivateRoute>
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/company" render={props => <Company {...props} />} />
    </div>
  </div>
);

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Router history={history}>
        <Switch history={history}>
          <Route exact path="/" render={() => <LogIn />} />
          <Route path="/" component={wrappedRoutes} />
          <Route path="/404" component={NotFound404} />
        </Switch>
      </Router>
    </main>
  </MainWrapper>
);

export default AppRouter;
